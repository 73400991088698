var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          key: _vm.dialog_popupLabel,
          attrs: { persistent: "", scrollable: "", width: "1110" },
          model: {
            value: _vm.dialog_popupLabel,
            callback: function($$v) {
              _vm.dialog_popupLabel = $$v
            },
            expression: "dialog_popupLabel"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup esl-detail" },
            [
              _c("v-card-title", [
                _c("div", { staticClass: "popup-titbox" }, [
                  _c("h3", { staticClass: "page-title-bar" }, [
                    _c("i", { staticClass: "ico ico-label" }),
                    _vm._v(_vm._s(_vm.$t("Label Detail")))
                  ])
                ])
              ]),
              _c("v-divider"),
              _c("v-card-text", [
                _c("div", { staticClass: "lable-detail-box" }, [
                  _c("div", { staticClass: "infoBox leftBox" }, [
                    _vm.currentImages.length
                      ? _c("div", [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              staticClass: "img-label",
                              style:
                                "transform: rotate(" +
                                _vm.rotationCurrent +
                                "deg) scale3d(" +
                                (_vm.rotationCurrent === 90 ||
                                _vm.rotationCurrent === -90
                                  ? "1,1,1"
                                  : _vm.rotationScale) +
                                ");",
                              attrs: { src: "" + _vm.topImage }
                            }),
                            _c(
                              "div",
                              { staticClass: "rotate-button-wrapper" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-1 pa-0",
                                    staticStyle: { "background-color": "#999" },
                                    attrs: {
                                      depressed: "",
                                      disabled: _vm.rotationCurrent === -270,
                                      elevation: "2",
                                      fab: "",
                                      icon: "",
                                      raised: "",
                                      color: "#FCFCFC",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.rotateImage(
                                          "left",
                                          "current"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("mdi-rotate-left ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-1 pa-0  bg-gray",
                                    staticStyle: { "background-color": "#999" },
                                    attrs: {
                                      depressed: "",
                                      disabled: _vm.rotationCurrent === 270,
                                      elevation: "2",
                                      fab: "",
                                      icon: "",
                                      raised: "",
                                      color: "#FCFCFC",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.rotateImage(
                                          "right",
                                          "current"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(" mdi-rotate-right ")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm.activeimage === _vm.pageIndexValue
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _c("b", [_vm._v(_vm._s(_vm.$t("Active Page")))])
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "labelInfo" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input form-status",
                                staticStyle: { width: "110px" },
                                attrs: {
                                  value: _vm.labelStatus,
                                  label: _vm.$t("Status"),
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  disabled: "",
                                  readonly: ""
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "form-input ml-4",
                                attrs: {
                                  value: _vm.processUpdateTime,
                                  label: _vm.$t("Requested Date"),
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  disabled: "",
                                  readonly: ""
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "form-input ml-2",
                                attrs: {
                                  value: _vm.statusUpdateTime,
                                  label: _vm.$t("Completed Date"),
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  disabled: "",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "c-pagenation" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-page-prev",
                                  attrs: { tile: "", depressed: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.forwardBtn()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "ico-prev" })]
                              ),
                              _vm._l(_vm.imagePageOption, function(
                                value,
                                name
                              ) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: name,
                                    ref: "labelPageBtn" + name,
                                    refInFor: true,
                                    staticClass: "btn-page",
                                    attrs: {
                                      "input-value": value.selected,
                                      disabled: value.disabled,
                                      tile: "",
                                      depressed: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickImagePageBtn(name)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(name) + " ")]
                                )
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-page-next",
                                  attrs: { tile: "", depressed: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.backwardBtn()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "ico-next" })]
                              )
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "infoBox rightBox" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        staticStyle: { "padding-top": "10px" }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          staticStyle: { width: "127px" },
                          attrs: {
                            value: _vm.labelId,
                            label: _vm.$t("Label ID"),
                            placeholder: _vm.$t("Input the label ID"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            disabled: "",
                            readonly: ""
                          }
                        }),
                        _c("v-text-field", {
                          staticClass: "form-input ml-3",
                          attrs: {
                            value: _vm.gateway,
                            label: _vm.$t("Linked Gateway"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            disabled: "",
                            readonly: ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-8" },
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            value: _vm.type,
                            label: _vm.$t("Type"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            disabled: "",
                            readonly: ""
                          }
                        }),
                        _c("v-text-field", {
                          staticClass: "form-input ml-3",
                          attrs: {
                            value: _vm.firmware,
                            label: _vm.$t("Firmware"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            disabled: "",
                            readonly: "",
                            clearable: ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-8" },
                      [
                        _c("v-label", [_vm._v("Template")]),
                        _c(
                          "v-list",
                          {
                            staticClass: "overflow-y-auto tempclass",
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              readonly: ""
                            }
                          },
                          _vm._l(_vm.TempType, function(item, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: i,
                                ref: "template",
                                refInFor: true,
                                attrs: {
                                  value: item,
                                  width: "400",
                                  height: "50"
                                }
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      attrs: { id: item },
                                      domProps: { textContent: _vm._s(item) }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c("v-text-field", {
                          staticClass: "form-input ml-3 centered-input",
                          attrs: {
                            value: _vm.templateManual,
                            label: _vm.$t("Template Manual"),
                            outlined: "",
                            dense: "",
                            height: "99px",
                            "hide-details": "",
                            disabled: "",
                            readonly: "",
                            clearable: ""
                          }
                        })
                      ],
                      1
                    ),
                    _vm.type.includes("2_6") ||
                    _vm.type.includes("5_85") ||
                    _vm.type.includes("9_7") ||
                    _vm.type.includes("12_2")
                      ? _c("div", [
                          _vm.CUSTOMER === "LIDL"
                            ? _c(
                                "div",
                                { staticClass: "mt-8" },
                                [
                                  _c("v-select", {
                                    staticClass: "form-select mt-3",
                                    staticStyle: { width: "46%" },
                                    attrs: {
                                      label: _vm.$t("Template Type Name"),
                                      placeholder: _vm.$t(
                                        "Select Template Type"
                                      ),
                                      items: _vm.templates,
                                      "item-text": "typeName",
                                      "item-value": "value",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.clearIndex()
                                      }
                                    },
                                    model: {
                                      value: _vm.templatetype,
                                      callback: function($$v) {
                                        _vm.templatetype = $$v
                                      },
                                      expression: "templatetype"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.CUSTOMER === "LIDL"
                            ? _c(
                                "div",
                                { staticClass: "mt-8" },
                                [
                                  _c("p", { staticClass: "tit-label" }, [
                                    _vm._v(_vm._s(_vm.$t("Assign Info")))
                                  ]),
                                  _c("hr"),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "justify-space-between form-add-btn mt-4",
                                      staticStyle: { border: "none" }
                                    },
                                    [
                                      _vm.templatetype != "" &&
                                      _vm.isTemplateTypeString &&
                                      !_vm.isPRD_CIString &&
                                      !_vm.isCI_PRDString &&
                                      _vm.templateTypeString >= 1
                                        ? _c(
                                            "v-list",
                                            {
                                              staticClass: "overflow-y-auto",
                                              staticStyle: {
                                                display: "flex",
                                                padding: "0px",
                                                height: "0% !important",
                                                width: "100%"
                                              },
                                              attrs: { dense: "" }
                                            },
                                            _vm._l(_vm.AIBxCount, function(
                                              item,
                                              i
                                            ) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: i,
                                                  staticStyle: {
                                                    "max-width": "50%",
                                                    "border-top": "none"
                                                  },
                                                  attrs: { value: item }
                                                },
                                                [
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      staticClass: "form-input",
                                                      attrs: {
                                                        placeholder:
                                                          "Product ID",
                                                        dense: "",
                                                        "hide-details": "",
                                                        solo: "",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.getAssignInfoList(
                                                            i
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .assigneArticleListValue[
                                                            i
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.assigneArticleListValue,
                                                            i,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "assigneArticleListValue[i]"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [
                                                          _vm.assigneArticleListValue !=
                                                          ""
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#001E38"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getAssignInfoList(
                                                                        i
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-list-item",
                                        {
                                          style:
                                            _vm.AIBxCount.length ===
                                              _vm.labelItems.length &&
                                            (_vm.AIBxCount.length !== 0 ||
                                              _vm.labelItems.length !== 0)
                                              ? "padding: 0px;max-width: 9%;"
                                              : "padding: 0px;max-width: 9%;display:none"
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _vm.showPlus()
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticStyle: {
                                                        "min-width": "0%"
                                                      },
                                                      attrs: {
                                                        disabled: !_vm.addPlusIconDisable,
                                                        ripple: false,
                                                        text: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.AIBxCount.push(
                                                            "x"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/img/ico-add.jpg"),
                                                          alt:
                                                            "Product ID input box add button"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.assigneArticleListValue != "" &&
                                      _vm.isTemplateTypeString &&
                                      !_vm.isPRD_CIString &&
                                      !_vm.isCI_PRDString &&
                                      _vm.showTable == true
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-4" },
                                            [
                                              _c("v-data-table", {
                                                staticClass: "tbl-type01",
                                                attrs: {
                                                  headers: _vm.labelHeaders,
                                                  items: _vm.labelItems,
                                                  "hide-default-footer": true,
                                                  "item-key": "productName"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item.deleteIcon",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "#C42C2C"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.removeLidL(
                                                                    item,
                                                                    _vm.labelItems.indexOf(
                                                                      item
                                                                    )
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.price",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c("p", [
                                                            item.price !== ""
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.price
                                                                    )
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v("-")
                                                                ])
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.templatetype != "" &&
                                      !_vm.isTemplateTypeString &&
                                      !_vm.isPRD_CIString &&
                                      !_vm.isCI_PRDString &&
                                      _vm.templateTypeCIString >= 1
                                        ? _c(
                                            "v-list",
                                            {
                                              staticStyle: { height: "100px" },
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _vm._l(_vm.AIBxCICount, function(
                                                item,
                                                i
                                              ) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: i,
                                                    staticStyle: {
                                                      "max-width": "50%",
                                                      "margin-top": "0px",
                                                      height: "53px !important",
                                                      "border-top": "none",
                                                      "padding-top": "0px"
                                                    },
                                                    attrs: { value: item }
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      staticClass:
                                                        "form-select mt-3",
                                                      staticStyle: {
                                                        width: "46%",
                                                        "padding-bottom": "10px"
                                                      },
                                                      attrs: {
                                                        placeholder: _vm.$t(
                                                          "Customer Info"
                                                        ),
                                                        items: _vm.customerInfo,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "value",
                                                        outlined: "",
                                                        dense: "",
                                                        "hide-details": ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.onSubmit(i)
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .CIAssigneArticleListValue[
                                                            i
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.CIAssigneArticleListValue,
                                                            i,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "CIAssigneArticleListValue[i]"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }),
                                              _vm.AIBxCICount.length ===
                                              _vm.labelCIItems.length
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass:
                                                        "add-assign-info",
                                                      staticStyle: {
                                                        padding: "0px",
                                                        "max-width": "9%",
                                                        "margin-top": "15px",
                                                        border: "none"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _vm.showCIPlus()
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticStyle: {
                                                                    "min-width":
                                                                      "0%"
                                                                  },
                                                                  attrs: {
                                                                    ripple: false,
                                                                    text: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.AIBxCICount.push(
                                                                        "y"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: require("@/assets/img/ico-add.jpg"),
                                                                      alt:
                                                                        "Product ID input box add button"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm.CIAssigneArticleListValue != "" &&
                                      !_vm.isTemplateTypeString &&
                                      !_vm.isPRD_CIString &&
                                      !_vm.isCI_PRDString &&
                                      _vm.showTable == true
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-4" },
                                            [
                                              _c("v-data-table", {
                                                staticClass: "tbl-type01",
                                                attrs: {
                                                  headers: _vm.labelCIHeaders,
                                                  items: _vm.labelCIItems,
                                                  "hide-default-footer": true
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item.deleteIcon",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "#C42C2C"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeCILidL(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.customerLogo",
                                                      fn: function() {
                                                        return [
                                                          _vm.templateSize[0] >
                                                          5
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#000000"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-image"
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "#000000"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-image-area"
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.templatetype != "" &&
                                      _vm.isPRD_CIString
                                        ? _c(
                                            "v-list",
                                            {
                                              staticStyle: {
                                                "padding-right": "0px",
                                                height: "auto !important",
                                                "max-height": "0% !important"
                                              },
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "border-top": "none",
                                                    "margin-top": "10px",
                                                    padding: "0px !important",
                                                    width: "50%"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      staticClass: "form-input",
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "Product ID",
                                                        dense: "",
                                                        "hide-details": "",
                                                        solo: "",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.getAssignPRDCIInfoList()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.assigneArticlePRDCIListValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.assigneArticlePRDCIListValue = $$v
                                                        },
                                                        expression:
                                                          "assigneArticlePRDCIListValue"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [
                                                          _vm.assigneArticlePRDCIListValue !=
                                                          ""
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#001E38"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getAssignPRDCIInfoList()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.templatetype != "" &&
                                              _vm.isPRD_CIString &&
                                              _vm.assigneArticlePRDCIListValue !=
                                                "" &&
                                              _vm.showTable == true
                                                ? _c(
                                                    "div",
                                                    { staticClass: "mt-4" },
                                                    [
                                                      _c("v-data-table", {
                                                        staticClass:
                                                          "tbl-type01",
                                                        attrs: {
                                                          headers:
                                                            _vm.labelHeaders,
                                                          items: _vm.labelItems,
                                                          "hide-default-footer": true
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "item.deleteIcon",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "#C42C2C"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removePRDCILidL()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key: "item.price",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c("p", [
                                                                    item.price !==
                                                                    ""
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.price
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "-"
                                                                            )
                                                                          ]
                                                                        )
                                                                  ])
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "border-top": "none",
                                                    "margin-top": "10px",
                                                    padding: "0px !important",
                                                    width: "50%"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "form-select assign_info_select",
                                                    staticStyle: {
                                                      "margin-bottom": "25px"
                                                    },
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "Customer Info"
                                                      ),
                                                      items: _vm.customerInfo,
                                                      "item-text":
                                                        "description",
                                                      "item-value": "value",
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.onPRDCISubmit()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.PRDCIAssigneArticleListValue,
                                                      callback: function($$v) {
                                                        _vm.PRDCIAssigneArticleListValue = $$v
                                                      },
                                                      expression:
                                                        "PRDCIAssigneArticleListValue"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.templatetype != "" &&
                                              _vm.isPRD_CIString &&
                                              _vm.PRDCIAssigneArticleListValue !=
                                                "" &&
                                              _vm.showCITable == true
                                                ? _c(
                                                    "div",
                                                    { staticClass: "mt-4" },
                                                    [
                                                      _c("v-data-table", {
                                                        staticClass:
                                                          "tbl-type01",
                                                        attrs: {
                                                          headers:
                                                            _vm.labelCIHeaders,
                                                          items:
                                                            _vm.labelCIItems,
                                                          "hide-default-footer": true
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "item.deleteIcon",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "#C42C2C"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.CIremoveLidL()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "item.customerLogo",
                                                              fn: function() {
                                                                return [
                                                                  _vm
                                                                    .templateSize[0] >
                                                                  5
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "#000000"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-image"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "#000000"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-image-area"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.templatetype != "" &&
                                      _vm.isCI_PRDString
                                        ? _c(
                                            "v-list",
                                            {
                                              staticStyle: {
                                                "margin-top": "-20px",
                                                "padding-right": "0px",
                                                height: "auto !important",
                                                "max-height": "0% !important"
                                              },
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "border-top": "none",
                                                    "margin-top": "10px",
                                                    padding: "0px !important",
                                                    width: "50%"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass:
                                                      "form-select assign_info_select",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "Customer Info"
                                                      ),
                                                      items: _vm.customerInfo,
                                                      "item-text":
                                                        "description",
                                                      "item-value": "value",
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.onCIPRDSubmit()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.assigneArticleCIPRDListValue,
                                                      callback: function($$v) {
                                                        _vm.assigneArticleCIPRDListValue = $$v
                                                      },
                                                      expression:
                                                        "assigneArticleCIPRDListValue"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.templatetype != "" &&
                                              _vm.isCI_PRDString &&
                                              _vm.assigneArticleCIPRDListValue !=
                                                "" &&
                                              _vm.showTable == true
                                                ? _c(
                                                    "div",
                                                    { staticClass: "mt-4" },
                                                    [
                                                      _c("v-data-table", {
                                                        staticClass:
                                                          "tbl-type01",
                                                        attrs: {
                                                          headers:
                                                            _vm.labelCIHeaders,
                                                          items:
                                                            _vm.labelCIItems,
                                                          "hide-default-footer": true
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "item.deleteIcon",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "#C42C2C"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeCIPRDLidL(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            },
                                                            {
                                                              key:
                                                                "item.customerLogo",
                                                              fn: function() {
                                                                return [
                                                                  _vm
                                                                    .templateSize[0] >
                                                                  5
                                                                    ? _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "#000000"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-image"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "#000000"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-image-area"
                                                                          )
                                                                        ]
                                                                      )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "border-top": "none",
                                                    "margin-top":
                                                      "30px !important",
                                                    padding: "0px !important",
                                                    width: "50%"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      staticClass: "form-input",
                                                      attrs: {
                                                        placeholder:
                                                          "Product ID",
                                                        dense: "",
                                                        "hide-details": "",
                                                        solo: "",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.getAssignPRDInfoList()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.assigneArticlePRDListValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.assigneArticlePRDListValue = $$v
                                                        },
                                                        expression:
                                                          "assigneArticlePRDListValue"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [
                                                          _vm.assigneArticlePRDListValue !=
                                                          ""
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#001E38"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getAssignPRDInfoList()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.templatetype != "" &&
                                              _vm.isCI_PRDString &&
                                              _vm.assigneArticlePRDListValue !=
                                                "" &&
                                              _vm.showPRDTable == true
                                                ? _c(
                                                    "div",
                                                    { staticClass: "mt-4" },
                                                    [
                                                      _c("v-data-table", {
                                                        staticClass:
                                                          "tbl-type01",
                                                        attrs: {
                                                          headers:
                                                            _vm.labelHeaders,
                                                          items: _vm.labelItems,
                                                          "hide-default-footer": true
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "item.deleteIcon",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "#C42C2C"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removePRDLidL()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-delete"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key: "item.price",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _c("p", [
                                                                    item.price !==
                                                                    ""
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.price
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "-"
                                                                            )
                                                                          ]
                                                                        )
                                                                  ])
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.templatetype != "" && _vm.isAddString
                                        ? _c(
                                            "v-list",
                                            {
                                              staticClass: "d-flex",
                                              staticStyle: {
                                                "padding-right": "0px",
                                                height: "auto"
                                              },
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "border-top": "none",
                                                    "margin-top": "0px",
                                                    padding: "0px !important",
                                                    height: "100px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      staticClass: "form-input",
                                                      staticStyle: {
                                                        width: "100%",
                                                        "padding-right": "15px",
                                                        height: "70px"
                                                      },
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "Left Neighbour :"
                                                        ),
                                                        placeholder: _vm.$t(
                                                          "label ID"
                                                        ),
                                                        dense: "",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        "click:clear":
                                                          _vm.clearLabel,
                                                        keyup: [
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.getLabelIds(
                                                              _vm.leftNeighbourValue,
                                                              "left"
                                                            )
                                                          },
                                                          function($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "delete",
                                                                [8, 46],
                                                                $event.key,
                                                                [
                                                                  "Backspace",
                                                                  "Delete",
                                                                  "Del"
                                                                ]
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.handleDelete()
                                                          }
                                                        ],
                                                        keydown: function(
                                                          $event
                                                        ) {
                                                          return _vm.addPopUp(
                                                            $event,
                                                            "left"
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.leftNeighbourValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.leftNeighbourValue = $$v
                                                        },
                                                        expression:
                                                          "leftNeighbourValue"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [
                                                          _vm.leftNeighbourValue !=
                                                          ""
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#001E38"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getLabelIds(
                                                                        _vm.leftNeighbourValue,
                                                                        "left"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-text-field",
                                                    {
                                                      staticClass: "form-input",
                                                      staticStyle: {
                                                        width: "100%",
                                                        height: "70px"
                                                      },
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "Right Neighbour :"
                                                        ),
                                                        placeholder: _vm.$t(
                                                          "label ID"
                                                        ),
                                                        dense: "",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.getLabelIds(
                                                            _vm.rightNeighbourValue,
                                                            "right"
                                                          )
                                                        },
                                                        keydown: function(
                                                          $event
                                                        ) {
                                                          return _vm.addPopUp(
                                                            $event,
                                                            "right"
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.rightNeighbourValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.rightNeighbourValue = $$v
                                                        },
                                                        expression:
                                                          "rightNeighbourValue"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [
                                                          _vm.rightNeighbourValue !=
                                                          ""
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#001E38"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.getLabelIds(
                                                                        _vm.rightNeighbourValue,
                                                                        "right"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "border-top": "none",
                                            "margin-top": "10px",
                                            padding: "0px !important",
                                            width: "100%"
                                          },
                                          style:
                                            (_vm.leftNeighbourValue !== "" &&
                                              _vm.leftNeighbour !== "") ||
                                            (_vm.rightNeighbourValue !== "" &&
                                              _vm.rightNeighbour !== "")
                                              ? ""
                                              : "display:none"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "w-100 d-flex",
                                              staticStyle: {
                                                "margin-top": "0px"
                                              },
                                              style:
                                                _vm.templatetype !== "" &&
                                                _vm.isAddString &&
                                                _vm.leftNeighbourValue !== "" &&
                                                _vm.leftNeighbour
                                                  ? ""
                                                  : "justify-content:end;"
                                            },
                                            [
                                              _vm.templatetype !== "" &&
                                              _vm.isAddString &&
                                              _vm.leftNeighbourValue !== "" &&
                                              _vm.leftNeighbour
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "50% !important",
                                                        "padding-right": "10px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "assign_info_add"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("PRODUCT ID")
                                                          ]),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass: "p-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.productIdLeft
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "assign_info_add"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "PRODUCT NAME"
                                                            )
                                                          ]),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass: "p-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.productNameLeft
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "assign_info_add_delete mb-0",
                                                          staticStyle: {
                                                            display: "flex",
                                                            "justify-content":
                                                              "center"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeAddLeftLidL(
                                                                _vm.leftNeighbourValue
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "#C42C2C"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v("DELETE ")
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.templatetype != "" &&
                                              _vm.isAddString &&
                                              _vm.rightNeighbourValue != "" &&
                                              _vm.rightNeighbour
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "50% !important",
                                                        "padding-left": "10px"
                                                      },
                                                      attrs: {
                                                        id: "rightNeighbour"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "assign_info_add"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("PRODUCT ID")
                                                          ]),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass: "p-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.productIdRight
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "assign_info_add"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "PRODUCT NAME"
                                                            )
                                                          ]),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass: "p-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.productNameRight
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "assign_info_add_delete mb-0",
                                                          staticStyle: {
                                                            display: "flex",
                                                            "justify-content":
                                                              "center"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeLidLRight(
                                                                _vm.rightNeighbourValue
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "#C42C2C"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-delete"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v("DELETE ")
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _c("hr"),
                                  _c("br"),
                                  _vm.AIBxCount.length >= 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled:
                                              _vm.templateTypeString !==
                                              _vm.labelItems.length
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAssign()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Assign")))]
                                      )
                                    : _vm._e(),
                                  _vm.AIBxCICount.length >= 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled:
                                              _vm.templateTypeCIString !==
                                              _vm.labelCIItems.length
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAssign()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Assign")))]
                                      )
                                    : _vm._e(),
                                  _vm.PRDCICount.length >= 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled:
                                              _vm.assigneArticlePRDCIListValue
                                                .length === 0 ||
                                              _vm.PRDCIAssigneArticleListValue
                                                .length === 0 ||
                                              _vm.labelItems.length < 1 ||
                                              _vm.labelCIItems.length < 1
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAssign()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Assign")))]
                                      )
                                    : _vm._e(),
                                  _vm.CIPRDCount.length >= 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled:
                                              _vm.assigneArticleCIPRDListValue
                                                .length === 0 ||
                                              _vm.assigneArticlePRDListValue
                                                .length === 0 ||
                                              _vm.labelItems.length < 1 ||
                                              _vm.labelCIItems.length < 1
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAssign()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Assign")))]
                                      )
                                    : _vm._e(),
                                  _vm.ADDCount.length >= 1
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn",
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            text: "",
                                            icon: "",
                                            disabled:
                                              _vm.leftNeighbour === false &&
                                              _vm.rightNeighbour === false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAssign()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Assign")))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mt-8" }, [
                      _c("p", { staticClass: "tit-label" }, [
                        _vm._v(_vm._s(_vm.$t("Assign Info")))
                      ]),
                      _c(
                        "div",
                        { staticClass: "assignInfo" },
                        [
                          _c("div", { staticClass: "assignBox" }, [
                            _vm.btnAssignTxt === _vm.assignType.unassign
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between form-add-btn"
                                  },
                                  [
                                    _c("p", { staticClass: "list_label" }, [
                                      _vm._v(_vm._s(_vm.$t("Product ID")))
                                    ]),
                                    _c(
                                      "v-list",
                                      {
                                        staticClass: "overflow-y-auto",
                                        attrs: { dense: "" }
                                      },
                                      _vm._l(_vm.articleList, function(
                                        item,
                                        i
                                      ) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: i,
                                            ref: "article",
                                            refInFor: true,
                                            attrs: {
                                              value: item,
                                              width: "400"
                                            },
                                            on: {
                                              dblclick: function($event) {
                                                return _vm.copyToClipBoard(item)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  attrs: { id: item },
                                                  domProps: {
                                                    textContent: _vm._s(item)
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm.btnAssignTxt === _vm.assignType.assign
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between form-add-btn"
                                    },
                                    [
                                      _c("p", { staticClass: "list_label" }, [
                                        _vm._v(_vm._s(_vm.$t("Product ID")))
                                      ]),
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "overflow-y-auto",
                                          attrs: { dense: "" }
                                        },
                                        [
                                          _vm._l(
                                            _vm.assigneArticleList,
                                            function(item, i) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: i,
                                                  attrs: {
                                                    value: item,
                                                    width: "400"
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "form-input",
                                                    attrs: {
                                                      placeholder:
                                                        "Input the Product ID",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.assigneArticleList[
                                                          i
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.assigneArticleList,
                                                          i,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "assigneArticleList[i]"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        ripple: false,
                                                        text: ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.clickAddArticle
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/img/ico-add.jpg"),
                                                          alt:
                                                            "Product ID input box add button"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "customDisabled",
                                  rawName: "v-customDisabled",
                                  value: _vm.labelUnassignDisabled,
                                  expression: "labelUnassignDisabled"
                                }
                              ],
                              staticClass: "btn-assign ml-3",
                              attrs: { text: "" },
                              on: { click: _vm.clickAssign }
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.btnAssignTxt)) + " ")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-8" }, [
                      _c("p", { staticClass: "tit-label" }, [
                        _vm._v(_vm._s(_vm.$t("Schedule")))
                      ]),
                      _c(
                        "div",
                        { staticClass: "schedule-info" },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "tbl-type01",
                              attrs: {
                                headers: _vm.scheduleHeaders,
                                items: _vm.scheduleList,
                                "item-key": "id",
                                page: _vm.page,
                                "hide-default-footer": true,
                                "fixed-header": "",
                                height: "120"
                              },
                              on: {
                                "update:page": function($event) {
                                  _vm.page = $event
                                }
                              }
                            },
                            [
                              _c("template", { slot: "no-data" }, [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.$t("No data available")))
                                ])
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "mt-8" }, [
                      _c("p", { staticClass: "tit-label" }, [
                        _vm._v(_vm._s(_vm.$t("Repeat Schedule")))
                      ]),
                      _c(
                        "div",
                        { staticClass: "schedule-info" },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "tbl-type01",
                              attrs: {
                                headers: _vm.repeatscheduleHeaders,
                                items: _vm.repeatscheduleList,
                                "item-key": "id",
                                page: _vm.page,
                                "hide-default-footer": true,
                                "fixed-header": "",
                                height: "120",
                                "disable-pagination": ""
                              },
                              on: {
                                "update:page": function($event) {
                                  _vm.page = $event
                                }
                              }
                            },
                            [
                              _c("template", { slot: "no-data" }, [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.$t("No data available")))
                                ])
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-8" },
                      [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t("Individual ESL Image Update")))
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("Image"),
                                    placeholder: _vm.$t("Input the image"),
                                    type: "file",
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": ""
                                  },
                                  on: {
                                    change: _vm.handleChange,
                                    keydown: _vm.clearTabindex
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c("img", {
                                            attrs: {
                                              width: "24",
                                              height: "24",
                                              src: require("@/assets/img/ico-upload-file.gif")
                                            }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ]),
                                  model: {
                                    value: _vm.inputImage,
                                    callback: function($$v) {
                                      _vm.inputImage = $$v
                                    },
                                    expression: "inputImage"
                                  }
                                }),
                                _c("v-select", {
                                  staticClass: "form-select mt-3",
                                  attrs: {
                                    label: _vm.$t("Page"),
                                    placeholder: _vm.$t("Input the page"),
                                    items: ["1", "2", "3", "4", "5", "6", "7"],
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    clearable: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  model: {
                                    value: _vm.inputPage,
                                    callback: function($$v) {
                                      _vm.inputPage = $$v
                                    },
                                    expression: "inputPage"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn btn-update mt-8",
                                    attrs: {
                                      text: "",
                                      icon: "",
                                      disabled: _vm.lableImagePushDisabled
                                    },
                                    on: { click: _vm.saveImage }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Image Update")) + " ")]
                                )
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _c("p", { staticClass: "tit-preview mb-1" }, [
                                _vm._v(_vm._s(_vm.$t("Preview")))
                              ]),
                              _c("div", { staticClass: "preview" }, [
                                _c("img", {
                                  staticClass: "img-label",
                                  attrs: {
                                    src:
                                      "data:image/jpeg;base64," +
                                      _vm.encodedImage
                                  }
                                })
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("v-divider"),
              _c(
                "div",
                { staticStyle: { padding: "50px" } },
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center mt-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: { click: _vm.closeModal }
                        },
                        [_vm._v(_vm._s(_vm.$t("Close")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "340px" },
          model: {
            value: _vm.dialog2,
            callback: function($$v) {
              _vm.dialog2 = $$v
            },
            expression: "dialog2"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "inputcssProperty", attrs: { height: "450px" } },
            [
              _c(
                "v-card-title",
                { staticStyle: { "background-color": "lightgray" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row d-flex",
                      staticStyle: { "justify-content": "space-between" }
                    },
                    [
                      _c("span", { staticStyle: { padding: "0px 10px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: { "font-size": "14px", height: "15px" }
                          },
                          [_vm._v(_vm._s(_vm.id_product))]
                        ),
                        _c("div", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v(_vm._s(_vm.name_product))
                        ])
                      ]),
                      _c("span", { staticClass: "assign_info_card_details" }, [
                        _vm._v(" " + _vm._s(_vm.priceValue) + " ")
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "v-card-text",
                { staticStyle: { height: "300px", padding: "0px" } },
                [
                  _c("div", { staticClass: "assign_list" }, [
                    _c("span", [_vm._v("Origin")]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "v-btn-primary",
                        on: {
                          click: function($event) {
                            return _vm.click_select()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.origin))]
                    )
                  ]),
                  _vm.toggleSelectCheck
                    ? _c("v-autocomplete", {
                        ref: "expand1",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.origin_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle()
                          }
                        },
                        model: {
                          value: _vm.origin,
                          callback: function($$v) {
                            _vm.origin = $$v
                          },
                          expression: "origin"
                        }
                      })
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.varietyUniqueCheck &&
                  _vm.variety != undefined &&
                  _vm.variety == ""
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("variety")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(" - ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.varietyUniqueCheck &&
                  _vm.variety != undefined &&
                  _vm.variety != ""
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("variety")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(_vm._s(_vm.variety))]
                        )
                      ])
                    : _vm._e(),
                  _vm.origin != "" &&
                  _vm.varietyObj.length >= 1 &&
                  _vm.varietyToggle
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("variety")]),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.click_select_variety()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.variety))]
                        )
                      ])
                    : _vm._e(),
                  _vm.origin != "" &&
                  _vm.varietyToggleCheck &&
                  _vm.varietyObj.length >= 1
                    ? _c("v-autocomplete", {
                        ref: "expand2",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.variety_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle_variety()
                          }
                        },
                        model: {
                          value: _vm.variety,
                          callback: function($$v) {
                            _vm.variety = $$v
                          },
                          expression: "variety"
                        }
                      })
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.calMaxUniqueCheck &&
                  _vm.caliber_max == "" &&
                  _vm.caliber_max != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Max")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(" - ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.calMaxUniqueCheck &&
                  _vm.caliber_max != "" &&
                  _vm.caliber_max != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Max")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(_vm._s(_vm.caliber_max))]
                        )
                      ])
                    : _vm._e(),
                  _vm.variety != "" &&
                  _vm.calMaxObj.length >= 1 &&
                  _vm.caliberMaxToggle
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Max")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.click_select_CaliMax()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.caliber_max))]
                        )
                      ])
                    : _vm._e(),
                  _vm.variety != "" &&
                  _vm.calMaxObj.length >= 1 &&
                  _vm.caliberMaxToggleCheck
                    ? _c("v-autocomplete", {
                        ref: "expand3",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.caliber_max_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle_CaliMax()
                          }
                        },
                        model: {
                          value: _vm.caliber_max,
                          callback: function($$v) {
                            _vm.caliber_max = $$v
                          },
                          expression: "caliber_max"
                        }
                      })
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.calMinUniqueCheck &&
                  _vm.caliber_min == "" &&
                  _vm.caliber_min != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Min")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(" - ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.calMinUniqueCheck &&
                  _vm.caliber_min != "" &&
                  _vm.caliber_min != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Min")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(_vm._s(_vm.caliber_min))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_max != "" &&
                  _vm.calMinObj.length >= 1 &&
                  _vm.caliberMinToggle
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Min")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.click_select_CaliMin()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.caliber_min))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_max != "" &&
                  _vm.calMinObj.length >= 1 &&
                  _vm.caliberMinToggleCheck
                    ? _c("v-autocomplete", {
                        ref: "expand4",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.caliber_min_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle_CaliMin()
                          }
                        },
                        model: {
                          value: _vm.caliber_min,
                          callback: function($$v) {
                            _vm.caliber_min = $$v
                          },
                          expression: "caliber_min"
                        }
                      })
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.calUnitUniqueCheck &&
                  _vm.caliber_unit == "" &&
                  _vm.caliber_unit != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Unit")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(" - ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.calUnitUniqueCheck &&
                  _vm.caliber_unit != "" &&
                  _vm.caliber_unit != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Unit")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(_vm._s(_vm.caliber_unit))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_min != "" &&
                  _vm.calUnitObj.length >= 1 &&
                  _vm.caliberUnitToggle
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Caliber Unit")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.click_select_CaliUnit()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.caliber_unit))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_min != "" &&
                  _vm.calUnitObj.length >= 1 &&
                  _vm.caliberUnitToggleCheck
                    ? _c("v-autocomplete", {
                        ref: "expand5",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.caliber_unit_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle_CaliUnit()
                          }
                        },
                        model: {
                          value: _vm.caliber_unit,
                          callback: function($$v) {
                            _vm.caliber_unit = $$v
                          },
                          expression: "caliber_unit"
                        }
                      })
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.classUniqueCheck &&
                  _vm.clasess != undefined &&
                  _vm.clasess == ""
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Class")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(" - ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.classUniqueCheck &&
                  _vm.clasess != undefined &&
                  _vm.clasess != ""
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Class")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(_vm._s(_vm.clasess))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_unit != "" &&
                  _vm.classObj.length >= 1 &&
                  _vm.clasessToggle
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Class")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.click_select_Class()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.clasess))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_unit != "" &&
                  _vm.classObj.length >= 1 &&
                  _vm.clasessToggleCheck
                    ? _c("v-autocomplete", {
                        ref: "expand6",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.class_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle_Class()
                          }
                        },
                        model: {
                          value: _vm.clasess,
                          callback: function($$v) {
                            _vm.clasess = $$v
                          },
                          expression: "clasess"
                        }
                      })
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.cretiUniqueCheck &&
                  _vm.certificates == "" &&
                  _vm.certificates != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Certificates")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(" - ")]
                        )
                      ])
                    : _vm._e(),
                  _vm.unique.length >= 1 &&
                  _vm.cretiUniqueCheck &&
                  _vm.certificates != "" &&
                  _vm.certificates != undefined
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Certificates")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            staticStyle: { cursor: "auto" }
                          },
                          [_vm._v(_vm._s(_vm.certificates))]
                        )
                      ])
                    : _vm._e(),
                  _vm.clasess != "" &&
                  _vm.certificateObj.length >= 1 &&
                  _vm.certificatesToggle
                    ? _c("div", { staticClass: "assign_list mt-1" }, [
                        _c("span", [_vm._v("Certificates")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "v-btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.click_select_Certificates()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.certificates))]
                        )
                      ])
                    : _vm._e(),
                  _vm.caliber_unit != "" &&
                  _vm.certificateObj.length >= 1 &&
                  _vm.certificatesToggleCheck
                    ? _c("v-autocomplete", {
                        ref: "expand7",
                        staticStyle: { padding: "0px 22px" },
                        attrs: {
                          items: _vm.certificates_list,
                          dense: "",
                          filled: "",
                          "return-object": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.click_toggle_Certificates()
                          }
                        },
                        model: {
                          value: _vm.certificates,
                          callback: function($$v) {
                            _vm.certificates = $$v
                          },
                          expression: "certificates"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.origin != "" &&
              _vm.variety != "" &&
              _vm.caliber_max != "" &&
              _vm.caliber_min != "" &&
              _vm.caliber_unit != "" &&
              _vm.clasess != "" &&
              _vm.certificates != ""
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "submit_card_button",
                          attrs: { dark: "", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.onSubmitPRD()
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }