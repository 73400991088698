<template>
<div>
  <v-dialog
    :key="dialog_popupLabel"
    v-model="dialog_popupLabel"
    persistent
    scrollable
    width="1110"
  >
    <v-card class="popup esl-detail">
      <v-card-title>
        <div class="popup-titbox">

<h3 class="page-title-bar"><i class="ico ico-label"></i>{{ $t('Label Detail') }}</h3>
</div>
      </v-card-title>
      <v-divider></v-divider>

        <v-card-text >
      <div class="lable-detail-box">
        <div class="infoBox leftBox">
          <div v-if="currentImages.length">
            <div class="img-box">
              <img :src="`${topImage}`" class="img-label" :style="`transform: rotate(${rotationCurrent}deg) scale3d(${rotationCurrent===90 || rotationCurrent === -90? '1,1,1' :rotationScale});`">
              <div class="rotate-button-wrapper">
                    <v-btn class="mx-1 pa-0"  depressed :disabled="rotationCurrent===-270"  elevation="2"
                    fab icon raised style="background-color: #999" color="#FCFCFC" small @click="rotateImage('left', 'current')">
                    <v-icon dark>mdi-rotate-left
                      </v-icon>
                    </v-btn>
                    <v-btn class="mx-1 pa-0  bg-gray"  depressed :disabled="rotationCurrent===270"
                     elevation="2" fab icon raised style="background-color: #999" color="#FCFCFC" small @click="rotateImage('right', 'current')">
                      <v-icon dark>
                        mdi-rotate-right
                      </v-icon>
                    </v-btn>
                  </div>
            </div>
            <div style="color:red" v-if="activeimage===pageIndexValue"><b>{{ $t('Active Page') }}</b></div>
            <div class="labelInfo">
              <v-text-field
                :value="labelStatus"
                :label="$t('Status')"
                class="form-input form-status"
                style="width:110px"
                outlined dense hide-details disabled readonly
              ></v-text-field>
              <v-text-field
                :value="processUpdateTime"
                :label="$t('Requested Date')"
                class="form-input ml-4"
                outlined dense hide-details disabled readonly
              ></v-text-field>
              <v-text-field
                :value="statusUpdateTime"
                :label="$t('Completed Date')"
                class="form-input ml-2"
                outlined dense hide-details disabled readonly
              ></v-text-field>
            </div>
            <div class="c-pagenation">
              <v-btn
                @click="forwardBtn()"
                class="btn-page-prev"
                tile
                depressed
              >
                <i class="ico-prev"></i>
              </v-btn>
              <v-btn
                v-for="(value, name) in imagePageOption"
                :ref="`labelPageBtn${name}`"
                :key="name"
                @click="clickImagePageBtn(name)"
                :input-value="value.selected"
                :disabled="value.disabled"
                class="btn-page"
                tile
                depressed
              >{{ name }}
              </v-btn>
              <v-btn
                @click="backwardBtn()"
                class="btn-page-next"
                tile
                depressed
              >
                <i class="ico-next"></i>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="infoBox rightBox">
          <div class="d-flex" style="padding-top:10px">
            <v-text-field
              :value="labelId"
              :label="$t('Label ID')"
              :placeholder="$t('Input the label ID')"
              class="form-input"
              style="width: 127px;"
              outlined
              dense
              hide-details
              disabled
              readonly
            ></v-text-field>
            <v-text-field
              :value="gateway"
              :label="$t('Linked Gateway')"
              class="form-input ml-3"
              outlined
              dense
              hide-details
              disabled
              readonly
            ></v-text-field>
          </div>
          <div class="d-flex mt-8">
            <v-text-field
              :value="type"
              :label="$t('Type')"
              class="form-input"
              outlined
              dense
              hide-details
              disabled
              readonly
            ></v-text-field>
            <v-text-field
              :value="firmware"
              :label="$t('Firmware')"
              class="form-input ml-3"
              outlined
              dense
              hide-details
              disabled
              readonly
              clearable
            ></v-text-field>
          </div>
           <div class="d-flex mt-8">
          <v-label>Template</v-label>
            <v-list dense  outlined hide-details readonly class="overflow-y-auto tempclass">
             <v-list-item
                v-for="(item, i) in TempType"
                :key="i"
                :value="item"
                width="400"
                ref="template"
                height="50">
              <v-list-item-content>
                <v-list-item-title
                  :id="item"
                  v-text="item"
                  >
                </v-list-item-title>
              </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-text-field
              :value="templateManual"
              :label="$t('Template Manual')"
              class="form-input ml-3 centered-input"
              outlined
              dense
              height="99px"
              hide-details
              disabled
              readonly
              clearable
            ></v-text-field>
          </div>
          <!-- //LidL Requirements -->
          <div v-if="type.includes('2_6') || type.includes('5_85') || type.includes('9_7') || type.includes('12_2')">
            <div class="mt-8" v-if="CUSTOMER==='LIDL'">
              <v-select v-model="templatetype" :label="$t('Template Type Name')"
                :placeholder="$t('Select Template Type')" :items="templates" item-text="typeName" item-value="value"
                class="form-select mt-3" outlined dense hide-details  style="width: 46%"
                v-on:change="clearIndex()"></v-select>
            </div>
            <div class="mt-8" v-if="CUSTOMER==='LIDL'">
              <p class="tit-label">{{ $t("Assign Info") }}</p>
              <hr />
              <div class="justify-space-between form-add-btn mt-4" style="border: none">
                <!-- PRD -->
                <v-list dense class="overflow-y-auto"
                  style="display: flex; padding: 0px; height: 0% !important;width: 100%;" v-if="
                    templatetype != '' &&
                    isTemplateTypeString &&
                    !isPRD_CIString &&
                    !isCI_PRDString &&
                    templateTypeString >= 1
                  ">
                  <v-list-item v-for="(item, i) in AIBxCount" :key="i" :value="item"
                    style="max-width: 50%; border-top: none">
                    <v-text-field v-model="assigneArticleListValue[i]" placeholder="Product ID" class="form-input" dense
                      hide-details solo  outlined @keyup.enter="getAssignInfoList(i)">
                      <template slot="append">
                      <v-icon v-if="assigneArticleListValue != ''" style="color: #001E38;" @click="getAssignInfoList(i)">mdi-arrow-right-bold</v-icon>
                      </template>
                    </v-text-field>
                  </v-list-item>
                </v-list>
               <v-list-item :style="((AIBxCount.length === labelItems.length) && (AIBxCount.length !== 0 || labelItems.length !== 0))? ('padding: 0px;max-width: 9%;'):('padding: 0px;max-width: 9%;display:none')">
                  <v-list-item-content>
                    <v-btn v-if="showPlus()" :disabled="!addPlusIconDisable" @click="AIBxCount.push('x')" :ripple="false" text style="min-width: 0%">
                      <img src="@/assets/img/ico-add.jpg" alt="Product ID input box add button" />
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <div v-if="
                  assigneArticleListValue != '' &&
                  isTemplateTypeString &&
                  !isPRD_CIString &&
                  !isCI_PRDString &&
                  showTable == true
                " class="mt-4">
                  <v-data-table :headers="labelHeaders" :items="labelItems" :hide-default-footer="true" item-key="productName"
                    class="tbl-type01">
                    <template v-slot:[`item.deleteIcon`]="{item}">
                      <v-icon color="#C42C2C" @click="removeLidL(item,labelItems.indexOf(item))">mdi-delete</v-icon>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                          <p>
                          <span  v-if="item.price !== ''">{{item.price}}</span>
                          <span v-else>-</span>
                          </p>
                      </template>
                  </v-data-table>
                </div>
                <!-- PRD hardcode -->
                <!-- <v-list dense class="overflow-y-auto" style="display: flex; height: 0% !important" v-if="
                templatetype != '' &&
                isTemplateTypeString &&
                !isPRD_CIString &&
                !isCI_PRDString ">
                <template>
                  <v-list-item v-for="(item, i) in assigneArticleListValue" :key="i" :value="item"
                    style="max-width: 50%; border-top: none">
                    <v-text-field v-model="assigneArticleListValue[i]" placeholder="Product ID" class="form-input" dense
                      hide-details solo  outlined @keyup.enter="getAssignInfoList()"></v-text-field>
                  </v-list-item>
                </template>
              </v-list>
              <div v-if="
                assigneArticleListValue != '' &&
                isTemplateTypeString &&
                !isPRD_CIString &&
                !isCI_PRDString && showTable == true && isDataPrefilled
              " class="mt-4">
                <v-data-table :headers="labelHeaders" :items="labelItems" :hide-default-footer="true"
                  class="tbl-type01">
                  <template v-slot:[`item.deleteIcon`]="{ item }">
                    <span>
                      <img src="@/assets/img/ico-delete.jpg" @click="removeLidL(item)" />
                    </span>
                  </template>
                </v-data-table>
              </div> -->
                <!-- CI dropdown -->
                <v-list dense class="" v-if="
                  templatetype != '' && !isTemplateTypeString && !isPRD_CIString &&
                  !isCI_PRDString &&
                  templateTypeCIString >= 1" style="height: 100px;">
                  <v-list-item v-for="(item, i) in AIBxCICount" :key="i" :value="item"
                    style="max-width: 50%;margin-top: 0px; height:53px !important; border-top: none; padding-top: 0px">
                    <v-select v-model="CIAssigneArticleListValue[i]" :placeholder="$t('Customer Info')"
                      :items="customerInfo" item-text="description" item-value="value" class="form-select mt-3" outlined
                      dense hide-details  style="width:  46%; padding-bottom: 10px" @change="onSubmit(i)">
                    </v-select>
                  </v-list-item>
             <v-list-item class="add-assign-info" v-if="AIBxCICount.length === labelCIItems.length" style="padding:0px;max-width: 9%;margin-top: 15px;border: none;">
                    <v-list-item-content>
                      <v-btn v-if="showCIPlus()" @click="AIBxCICount.push('y')" :ripple="false" text
                        style="min-width: 0%">
                        <img src="@/assets/img/ico-add.jpg" alt="Product ID input box add button" />
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div v-if="
                  CIAssigneArticleListValue != '' &&
                  !isTemplateTypeString &&
                  !isPRD_CIString &&
                  !isCI_PRDString &&
                  showTable == true
                " class="mt-4">
                  <v-data-table :headers="labelCIHeaders" :items="labelCIItems" :hide-default-footer="true"
                    class="tbl-type01">
                    <template v-slot:[`item.deleteIcon`]="{item}">
                      <v-icon color="#C42C2C" @click="removeCILidL(item)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:[`item.customerLogo`]>
                      <v-icon color="#000000" v-if="templateSize[0] > 5">mdi-image</v-icon>
                      <v-icon color="#000000" v-else>mdi-image-area</v-icon>
                    </template>
                  </v-data-table>
                </div>
                <!-- New PDR/CI -->
                <v-list dense v-if="templatetype != '' && isPRD_CIString" style="padding-right: 0px;height: auto !important;max-height: 0% !important;">
                  <v-list-item style="border-top: none;margin-top: 10px; padding: 0px !important ;width:50%">
                    <v-text-field v-model="assigneArticlePRDCIListValue"  placeholder="Product ID" class="form-input" dense
                      hide-details solo  @keyup.enter="getAssignPRDCIInfoList()" outlined style="width: 100%">
                     <template slot="append">
                      <v-icon v-if="assigneArticlePRDCIListValue != ''" style="color: #001E38;" @click="getAssignPRDCIInfoList()">mdi-arrow-right-bold</v-icon>
                    </template>
                    </v-text-field>
                  </v-list-item>
                  <div v-if="
                  templatetype != ''  && isPRD_CIString && assigneArticlePRDCIListValue != '' &&
                  showTable == true" class="mt-4">
                  <v-data-table :headers="labelHeaders" :items="labelItems" :hide-default-footer="true"
                    class="tbl-type01">
                    <template v-slot:[`item.deleteIcon`]>
                      <v-icon color="#C42C2C" @click="removePRDCILidL()">mdi-delete</v-icon>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                          <p>
                          <span  v-if="item.price !== ''">{{item.price}}</span>
                          <span v-else>-</span>
                          </p>
                      </template>
                  </v-data-table>
                </div>
                <v-list-item style="border-top: none;margin-top: 10px; padding: 0px !important;width:50%">
                    <v-select v-model="PRDCIAssigneArticleListValue" :placeholder="$t('Customer Info')"
                      :items="customerInfo" item-text="description" item-value="value"
                      class="form-select assign_info_select" outlined dense hide-details style="margin-bottom: 25px;"
                      @change="onPRDCISubmit()">
                    </v-select>
                  </v-list-item>
                  <div v-if="
                    templatetype != '' && isPRD_CIString && PRDCIAssigneArticleListValue !='' &&
                    showCITable == true" class="mt-4">
                      <v-data-table :headers="labelCIHeaders" :items="labelCIItems" :hide-default-footer="true"
                        class="tbl-type01">
                        <template v-slot:[`item.deleteIcon`]>
                          <v-icon color="#C42C2C" @click="CIremoveLidL()">mdi-delete</v-icon>
                        </template>
                        <template v-slot:[`item.customerLogo`]>
                          <v-icon color="#000000" v-if="templateSize[0] > 5">mdi-image</v-icon>
                          <v-icon color="#000000" v-else>mdi-image-area</v-icon>
                        </template>
                      </v-data-table>
                    </div>
                </v-list>
                <!-- CI/PRD -->
                 <v-list dense v-if="templatetype != '' && isCI_PRDString" style="margin-top:-20px;padding-right: 0px;height: auto !important;max-height: 0% !important;">
                  <v-list-item style="border-top: none;margin-top: 10px; padding: 0px !important;width:50%">
                    <v-select v-model="assigneArticleCIPRDListValue" :placeholder="$t('Customer Info')"
                      :items="customerInfo" item-text="description" item-value="value"
                      class="form-select assign_info_select" outlined dense hide-details
                      @change="onCIPRDSubmit()">
                    </v-select>
                  </v-list-item>
                  <div v-if="templatetype != '' && isCI_PRDString && assigneArticleCIPRDListValue != '' &&
                    showTable == true" class="mt-4">
                      <v-data-table :headers="labelCIHeaders" :items="labelCIItems" :hide-default-footer="true"
                        class="tbl-type01">
                        <template v-slot:[`item.deleteIcon`]="{item}">
                          <v-icon color="#C42C2C" @click="removeCIPRDLidL(item)">mdi-delete</v-icon>
                        </template>
                        <template v-slot:[`item.customerLogo`]>
                          <v-icon color="#000000" v-if="templateSize[0] > 5">mdi-image</v-icon>
                          <v-icon color="#000000" v-else>mdi-image-area</v-icon>
                        </template>
                      </v-data-table>
                    </div>
                  <v-list-item style="border-top: none;margin-top: 30px !important; padding: 0px !important;width:50%">
                    <v-text-field v-model="assigneArticlePRDListValue" placeholder="Product ID" class="form-input" dense
                      hide-details solo  @keyup.enter="getAssignPRDInfoList()" outlined >
                      <template slot="append">
                      <v-icon v-if="assigneArticlePRDListValue != ''" style="color: #001E38;" @click="getAssignPRDInfoList()">mdi-arrow-right-bold</v-icon>
                    </template>
                    </v-text-field>
                  </v-list-item>
                  <div v-if="
                  templatetype != '' && isCI_PRDString && assigneArticlePRDListValue != '' &&
                  showPRDTable == true
                " class="mt-4">
                  <v-data-table :headers="labelHeaders" :items="labelItems" :hide-default-footer="true"
                    class="tbl-type01">
                    <template v-slot:[`item.deleteIcon`]>
                      <v-icon color="#C42C2C" @click="removePRDLidL()">mdi-delete</v-icon>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                          <p>
                          <span  v-if="item.price !== ''">{{item.price}}</span>
                          <span v-else>-</span>
                          </p>
                      </template>
                  </v-data-table>
                </div>
                </v-list>
                <!-- ADD -->
                <v-list dense class="d-flex" v-if="templatetype != '' && isAddString" style="padding-right: 0px; height: auto">
                  <v-list-item style="border-top: none;margin-top: 0px;padding: 0px !important;height: 100px;">
                    <v-text-field v-model="leftNeighbourValue" :label="$t('Left Neighbour :')" :placeholder="$t('label ID')" class="form-input" dense outlined
                      @click:clear="clearLabel"
                      @keyup.enter="getLabelIds(leftNeighbourValue, 'left')"
                      @keydown="addPopUp($event, 'left')"
                      @keyup.delete="handleDelete()"
                      style="width: 100%; padding-right: 15px; height: 70px">
                     <template slot="append">
                      <v-icon v-if="leftNeighbourValue != ''" style="color: #001E38;" @click="getLabelIds(leftNeighbourValue, 'left')">mdi-arrow-right-bold</v-icon>
                    </template>
                    </v-text-field>
                    <v-text-field v-model="rightNeighbourValue" :label="$t('Right Neighbour :')" :placeholder="$t('label ID')" class="form-input" dense outlined
                      @keyup.enter="getLabelIds(rightNeighbourValue, 'right')"
                      @keydown="addPopUp($event, 'right')"
                      style="width: 100%; height: 70px"
                    >
                <template slot="append">
                      <v-icon v-if="rightNeighbourValue != ''" style="color: #001E38;" @click="getLabelIds(rightNeighbourValue, 'right')">mdi-arrow-right-bold</v-icon>
                        </template>
                    </v-text-field>
                  </v-list-item>
                </v-list>
                <v-list-item :style="(leftNeighbourValue !== '' && leftNeighbour !== '') || (rightNeighbourValue !== '' && rightNeighbour !== '' )? '' : 'display:none'" style=" display:flex; border-top: none; margin-top: 10px; padding: 0px !important; width: 100%;">
                  <div class="w-100 d-flex" :style="templatetype !== '' && isAddString && leftNeighbourValue !== '' && leftNeighbour ? '' :'justify-content:end;'" style="margin-top:0px">
                    <div v-if="templatetype !== '' && isAddString && leftNeighbourValue !== '' && leftNeighbour"
                    style="width: 50% !important; padding-right: 10px"
                  >
                    <div class="assign_info_add">
                      <span>PRODUCT ID</span>
                      <button class="p-2">{{ productIdLeft }}</button>
                    </div>
                    <div class="assign_info_add">
                      <span>PRODUCT NAME</span>
                      <button class="p-2">{{productNameLeft}}</button>
                    </div>
                    <div
                      class="assign_info_add_delete mb-0"
                      style="display: flex; justify-content: center"
                      @click="removeAddLeftLidL(leftNeighbourValue)"
                    >
                      <v-icon color="#C42C2C">mdi-delete</v-icon>DELETE
                    </div>
                  </div>
                  <div v-if=" templatetype != '' && isAddString && rightNeighbourValue != '' && rightNeighbour"
                    style="width: 50% !important;padding-left: 10px"
                    id="rightNeighbour"
                  >
                    <div class="assign_info_add">
                      <span>PRODUCT ID</span>
                      <button class="p-2">{{ productIdRight }}</button>
                    </div>
                    <div class="assign_info_add">
                      <span>PRODUCT NAME</span>
                      <button class="p-2">{{productNameRight}}</button>
                    </div>
                    <div
                      class="assign_info_add_delete mb-0"
                      style="display: flex; justify-content: center"
                      @click="removeLidLRight(rightNeighbourValue)"
                    >
                      <v-icon color="#C42C2C">mdi-delete</v-icon>DELETE
                    </div>
                  </div>
                  </div>
                </v-list-item>
              </div>
              <br />
              <hr />
              <br />
             <v-btn text icon style="float: right" class="btn" v-if="AIBxCount.length >= 1" :disabled="templateTypeString !== labelItems.length" @click="onAssign()">{{
                    $t("Assign")
                }}</v-btn>
              <v-btn text icon style="float: right" class="btn" v-if="AIBxCICount.length >= 1" :disabled="templateTypeCIString !== labelCIItems.length" @click="onAssign()">{{
                    $t("Assign")
                }}</v-btn>
              <v-btn text icon style="float: right" class="btn" v-if="PRDCICount.length >= 1"
              :disabled="assigneArticlePRDCIListValue.length === 0 || PRDCIAssigneArticleListValue.length === 0 || labelItems.length < 1 || labelCIItems.length < 1" @click="onAssign()">{{
                    $t("Assign")
                }}</v-btn>
              <v-btn text icon style="float: right" class="btn" v-if="CIPRDCount.length >= 1"
              :disabled="assigneArticleCIPRDListValue.length === 0 || assigneArticlePRDListValue.length === 0 || labelItems.length < 1 || labelCIItems.length < 1" @click="onAssign()">{{
                    $t("Assign")
                }}</v-btn>
                <v-btn text icon style="float: right" class="btn" v-if="ADDCount.length >= 1" :disabled="leftNeighbour === false && rightNeighbour === false" @click="onAssign()">{{
                    $t("Assign")
                }}</v-btn>
            </div>
</div>
            <!-- End  //LidL Requirements -->
          <div class="mt-8">
            <p class="tit-label">{{ $t('Assign Info') }}</p>
            <div class="assignInfo">
              <div class="assignBox">
                <div
                  v-if="btnAssignTxt === assignType.unassign"
                  class="d-flex justify-space-between form-add-btn"
                >
                  <p class="list_label">{{ $t('Product ID') }}</p>
                  <v-list dense class="overflow-y-auto">
                      <v-list-item
                        v-for="(item, i) in articleList"
                        :key="i"
                        :value="item"
                        @dblclick="copyToClipBoard(item)"
                        width="400"
                        ref="article"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            :id="item"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                  </v-list>
                </div>
                <div v-else-if="btnAssignTxt === assignType.assign">
                  <div class="d-flex justify-space-between form-add-btn">
                    <p class="list_label">{{ $t('Product ID') }}</p>
                    <v-list dense class="overflow-y-auto">
                        <v-list-item
                          v-for="(item, i) in assigneArticleList"
                          :key="i"
                          :value="item"
                          width="400"
                        >
                          <v-text-field
                            v-model="assigneArticleList[i]"
                            placeholder="Input the Product ID"
                            class="form-input"
                            dense hide-details solo
                            clearable
                          ></v-text-field>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn
                              @click="clickAddArticle"
                              :ripple="false"
                              text
                            >
                              <img src="@/assets/img/ico-add.jpg" alt="Product ID input box add button"/>
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <v-btn
                text
                @click="clickAssign"
                class="btn-assign ml-3"
                v-customDisabled="labelUnassignDisabled"
              >{{ $t(btnAssignTxt) }}
              </v-btn>
            </div>
          </div>
          <div class="mt-8">
            <p class="tit-label">{{ $t('Schedule') }}</p>
            <div class="schedule-info">
              <v-data-table
                :headers="scheduleHeaders"
                :items="scheduleList"
                item-key="id"
                :page.sync="page"
                :hide-default-footer="true"
                fixed-header
                height="120"
                class="tbl-type01"
              >
              <template slot="no-data">
                <p>{{ $t('No data available') }}</p>
              </template>
              </v-data-table>
            </div>
          </div>

          <div class="mt-8">
            <p class="tit-label">{{ $t('Repeat Schedule') }}</p>
            <div class="schedule-info">
              <v-data-table
                :headers="repeatscheduleHeaders"
                :items="repeatscheduleList"
                item-key="id"
                :page.sync="page"
                :hide-default-footer="true"
                fixed-header
                height="120"
                disable-pagination
                class="tbl-type01"
              >
              <template slot="no-data">
                <p>{{ $t('No data available') }}</p>
              </template>
              </v-data-table>
            </div>
          </div>
          <!-- admin 계정일때만 노출 -->
          <!-- <div v-if="accessLevel === '1'" class="mt-8"> -->
          <!-- <div v-if="!lableImagePushDisabled" class="mt-8"> -->
          <div class="mt-8">
            <h4>{{$t('Individual ESL Image Update')}}</h4>
            <v-row>
              <v-col cols="6">
                <v-file-input
                  v-model="inputImage"
                  @change="handleChange"
                  class="form-file"
                  :label="$t('Image')"
                  :placeholder="$t('Input the image')"
                  type="file"
                  outlined
                  dense
                  hide-details
                  prepend-icon
                  @keydown="clearTabindex"
                >
                  <template v-slot:append>
                    <img
                      width="24"
                      height="24"
                      src="@/assets/img/ico-upload-file.gif"
                    />
                  </template>
                </v-file-input>
                <v-select
                  v-model="inputPage"
                  :label="$t('Page')"
                  :placeholder="$t('Input the page')"
                  :items="['1', '2', '3', '4', '5', '6', '7']"
                  class="form-select mt-3"
                  outlined
                  dense
                  hide-details
                  clearable
                   @keydown="clearTabindex"
                ></v-select>
                <v-btn
                  @click="saveImage"
                  text
                  icon
                  :disabled="lableImagePushDisabled"
                  class="btn btn-update mt-8"
                >{{ $t('Image Update') }}
                </v-btn >
              </v-col>
              <v-col cols="6">
                <p class="tit-preview mb-1">{{ $t('Preview') }}</p>
                <div class="preview">
                  <img :src="`data:image/jpeg;base64,${encodedImage}`" class="img-label">
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
</v-card-text>
<v-divider></v-divider>

      <div style="padding:50px;">
        <v-card-actions class="d-flex justify-center mt-0">
          <v-btn text icon @click="closeModal" class="btn">{{ $t('Close') }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
  <!-- LIDL popup -->
  <v-dialog v-model="dialog2" max-width="340px">
      <v-card height="450px" class="inputcssProperty">
        <v-card-title style="background-color:lightgray">
          <div class="row d-flex" style="justify-content: space-between;">
            <span style="padding: 0px 10px;">
              <div style="font-size: 14px;height: 15px;">{{ id_product }}</div>
              <div style="font-weight:600;">{{ name_product }}</div>
            </span>
            <span class="assign_info_card_details">
              {{ priceValue }}
            </span>
          </div>
        </v-card-title>
        <v-card-text style="height: 300px;padding:0px;">
          <!-- Origin -->
          <div class="assign_list"><span>Origin</span> <button @click='click_select()' class="v-btn-primary">{{ origin
          }}</button></div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand1" v-model="origin" v-if="toggleSelectCheck"
            @change="click_toggle()" :items="origin_list" dense filled return-object></v-autocomplete>
          <!-- variety -->
          <div class="assign_list mt-1" v-if="unique.length >= 1 && varietyUniqueCheck && variety != undefined && variety == ''"><span>variety</span> <button
              class="v-btn-primary" style="cursor: auto;"> - </button></div>
          <div class="assign_list mt-1" v-if="unique.length >= 1 && varietyUniqueCheck && variety != undefined && variety != ''"><span>variety</span> <button
              class="v-btn-primary" style="cursor: auto;">{{ variety }}</button></div>
          <div class="assign_list mt-1"
            v-if="origin != '' && varietyObj.length >= 1 && varietyToggle">
            <span>variety</span>
            <button @click='click_select_variety()' class="v-btn-primary">{{ variety }}</button>
          </div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand2" v-model="variety"
            v-if="origin != '' && varietyToggleCheck && varietyObj.length >= 1"
            @change="click_toggle_variety()" :items="variety_list" dense filled return-object></v-autocomplete>
          <!-- caliber max -->
          <div class="assign_list mt-1" v-if="unique.length >= 1 && calMaxUniqueCheck && caliber_max == '' && caliber_max != undefined"><span>Caliber Max</span> <button
              class="v-btn-primary" style="cursor: auto;"> - </button></div>
          <div class="assign_list mt-1" v-if="unique.length >= 1 && calMaxUniqueCheck && caliber_max != '' && caliber_max != undefined"><span>Caliber Max</span> <button
              class="v-btn-primary" style="cursor: auto;">{{ caliber_max }}</button></div>
          <div class="assign_list mt-1"
            v-if="variety != '' && calMaxObj.length >= 1 && caliberMaxToggle">
            <span>Caliber Max</span> <button @click='click_select_CaliMax()' class="v-btn-primary">{{ caliber_max
            }}</button>
          </div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand3" v-model="caliber_max"
            v-if="variety != '' && calMaxObj.length >= 1 && caliberMaxToggleCheck"
            @change="click_toggle_CaliMax()" :items="caliber_max_list" dense filled return-object></v-autocomplete>
          <!-- caliber min -->
          <div class="assign_list mt-1" v-if="unique.length >= 1 && calMinUniqueCheck && caliber_min == '' && caliber_min != undefined"><span>Caliber Min</span> <button
              class="v-btn-primary" style="cursor: auto;"> - </button></div>
          <div class="assign_list mt-1" v-if="unique.length >= 1 && calMinUniqueCheck && caliber_min != '' && caliber_min != undefined"><span>Caliber Min</span> <button
              class="v-btn-primary" style="cursor: auto;">{{ caliber_min }}</button></div>
          <div class="assign_list mt-1"
            v-if="caliber_max != '' && calMinObj.length >= 1 && caliberMinToggle">
            <span>Caliber Min</span> <button @click='click_select_CaliMin()' class="v-btn-primary">{{ caliber_min
            }}</button>
          </div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand4" v-model="caliber_min"
            v-if="caliber_max != '' && calMinObj.length >= 1 && caliberMinToggleCheck"
            @change="click_toggle_CaliMin()" :items="caliber_min_list" dense filled return-object></v-autocomplete>
          <!-- caliber unit -->
          <div class="assign_list mt-1" v-if="unique.length >= 1 && calUnitUniqueCheck && caliber_unit == '' && caliber_unit != undefined"><span>Caliber Unit</span> <button
              class="v-btn-primary" style="cursor: auto;"> - </button></div>
          <div class="assign_list mt-1" v-if="unique.length >= 1 && calUnitUniqueCheck && caliber_unit != '' && caliber_unit != undefined"><span>Caliber Unit</span> <button
              class="v-btn-primary" style="cursor: auto;">{{ caliber_unit }}</button></div>
          <div class="assign_list mt-1"
            v-if="caliber_min != '' && calUnitObj.length >= 1 && caliberUnitToggle ">
            <span>Caliber Unit</span> <button @click='click_select_CaliUnit()' class="v-btn-primary">{{ caliber_unit
            }}</button>
          </div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand5" v-model="caliber_unit"
            v-if="caliber_min != '' && calUnitObj.length >= 1 && caliberUnitToggleCheck"
            @change="click_toggle_CaliUnit()" :items="caliber_unit_list" dense filled return-object></v-autocomplete>
          <!-- classes -->
          <div class="assign_list mt-1" v-if="unique.length >= 1 && classUniqueCheck && clasess != undefined && clasess == ''"><span>Class</span> <button
              class="v-btn-primary" style="cursor: auto;"> - </button></div>
          <div class="assign_list mt-1" v-if="unique.length >= 1 && classUniqueCheck && clasess != undefined && clasess != ''"><span>Class</span> <button
              class="v-btn-primary" style="cursor: auto;">{{ clasess }}</button></div>
          <div class="assign_list mt-1"
            v-if="caliber_unit != '' && classObj.length >= 1 && clasessToggle">
            <span>Class</span> <button @click='click_select_Class()' class="v-btn-primary">{{ clasess }}</button>
          </div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand6" v-model="clasess"
            v-if="caliber_unit != '' && classObj.length >= 1 && clasessToggleCheck"
            @change="click_toggle_Class()" :items="class_list" dense filled return-object></v-autocomplete>
          <!-- certificates -->
          <div class="assign_list mt-1" v-if="unique.length >= 1 && cretiUniqueCheck && certificates == '' && certificates != undefined"><span>Certificates</span> <button
              class="v-btn-primary" style="cursor: auto;"> - </button></div>
          <div class="assign_list mt-1" v-if="unique.length >= 1 && cretiUniqueCheck && certificates != '' && certificates != undefined"><span>Certificates</span> <button
              class="v-btn-primary" style="cursor: auto;">{{ certificates }}</button></div>
          <div class="assign_list mt-1"
            v-if="clasess != '' && certificateObj.length >= 1 && certificatesToggle ">
            <span>Certificates</span> <button @click='click_select_Certificates()' class="v-btn-primary">{{ certificates
            }}</button>
          </div>
          <v-autocomplete style="padding: 0px 22px;" ref="expand7" v-model="certificates"
            v-if="caliber_unit != '' && certificateObj.length >= 1 && certificatesToggleCheck"
            @change="click_toggle_Certificates()" :items="certificates_list" dense filled return-object>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions
          v-if="origin != '' && variety != '' && caliber_max != '' && caliber_min != '' && caliber_unit != '' && clasess != '' && certificates != ''">
          <v-btn class="submit_card_button" dark text @click="onSubmitPRD()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
  <!-- LIDL popup ends -->
</div>
</template>
<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import configs from '@/plugins/configs'
// import axios from 'axios'
const requests = {
  getTemplates: {
    method: 'get',
    url: '/api/common/templates/type'
  }
}

export default {
  directives: {
    customDisabled: {
      inserted: function (el, binding) {
        if (binding.value) {
          el.classList.add('disabled')
        }
      }
    }
  },
  props: {
    item: {},
    labelDetailDisabled: {
      default: false,
      required: true
    }
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      dialog_popupLabel: false,
      accessLevel: null,
      plusFlag: false,
      activePage: null,
      imagePageOption: {
        1: {
          selected: false,
          disabled: true
        },
        2: {
          selected: false,
          disabled: true
        },
        3: {
          selected: false,
          disabled: true
        },
        4: {
          selected: false,
          disabled: true
        },
        5: {
          selected: false,
          disabled: true
        },
        6: {
          selected: false,
          disabled: true
        },
        7: {
          selected: false,
          disabled: true
        }
      },
      imageLength: 7,
      page: 1,
      // 09.24 아이템 리스트 아이템 추가
      labelSourceId: null,
      store: 'Store1',
      currentImages: [],
      labelId: null,
      status: null,
      type: '',
      gateway: null,
      firmware: null,
      templateManual: null,
      TempType: [],
      articleList: [],
      // assigneArticleList: [],
      btnAssignTxt: null,
      inputPage: null,
      inputImage: null,
      encodedImage: '',
      labelID: '',
      preview: '',
      rules: {
        required: (value) => !!value || 'Required.'
      },
      scheduleList: [],
      repeatscheduleList: [],
      assignType: codes.assignType,
      labelUnassignDisabled: false,
      lableImagePushDisabled: false,
      leftNeighbour: false,
      rightNeighbour: false,
      templateTypeString: '',
      templateTypeCIString: '',
      labelItems: [],
      labelCIItems: [],
      isPRD_CIString: false,
      isCI_PRDString: false,
      isTemplateTypeString: false,
      showPRDTable: false,
      templateSize: '',
      isAddString: false,
      assigneArticleList: [],
      dialog2: false,
      assigneArticleListValue: [],
      assigneArticleCIPRDListValue: [],
      AIBxCount: [], // no of boxes count
      AIBxCICount: [],
      PRDCICount: [],
      ADDCount: [],
      CIPRDCount: [],
      rightNeighbourValue: '',
      leftNeighbourValue: '',
      templates: [],
      templatetype: '',
      customerInfo: [],
      items: [],
      duplicateData: [],
      articleData: [],
      origin: '',
      origin_list: [],
      variety: '',
      variety_list: [],
      caliber_max: '',
      caliber_max_list: [],
      caliber_min_list: [],
      caliber_min: '',
      caliber_unit: '',
      caliber_unit_list: [],
      clasess: '',
      class_list: [],
      certificates: '',
      certificates_list: [],
      options: {},
      showTable: false,
      showCITable: false,
      assigneArticleCIList: '',
      assigneArticleInfoList: [],
      isDataPrefilled: true,
      toggleSelect: true,
      varietyToggle: false,
      caliberMaxToggle: false,
      caliberMinToggle: false,
      caliberUnitToggle: false,
      clasessToggle: false,
      certificatesToggle: false,
      toggleSelectCheck: true,
      varietyToggleCheck: false,
      caliberMaxToggleCheck: false,
      caliberMinToggleCheck: false,
      caliberUnitToggleCheck: false,
      clasessToggleCheck: false,
      certificatesToggleCheck: false,
      varietyUniqueCheck: false,
      calMaxUniqueCheck: false,
      calMinUniqueCheck: false,
      calUnitUniqueCheck: false,
      classUniqueCheck: false,
      cretiUniqueCheck: false,
      objFilteredData: [],
      eventValue: '',
      CIAssigneArticleListValue: [],
      PRDCIAssigneArticleListValue: [],
      CISelectedValue: [],
      PRDdata: '',
      obj: [],
      varietyObj: [],
      calMaxObj: [],
      calMinObj: [],
      calUnitObj: [],
      classObj: [],
      certificateObj: [],
      unique: [],
      CIData: [],
      PRDarticleData: [],
      assigneArticlePRDListValue: [],
      assigneArticlePRDCIListValue: [],
      addPlusIconDisable: true,
      isCIPRD: false,
      CUSTOMER: configs.Customer,
      name_product: '',
      id_product: '',
      priceValue: '',
      productIdLeft: null,
      productIdRight: null,
      productNameLeft: '',
      productNameRight: '',
      indexValue: 0,
      //  LidL Requirements ends
      // Image Rotation
      rotationCurrent: 0,
      rotationPrevious: 0,
      rotationScale: '1,1,1',
      pageIndexValue: null,
      activeimage: null
    }
  },
  computed: {
    //  LidL Requirements
    labelCIHeaders () {
      return [
        {
          text: this.$t('CUSTOMER LOGO'),
          sortable: true,
          value: 'customerLogo'
        },
        {
          text: this.$t('CUSTOMER DESCRIPTION'),
          sortable: false,
          value: 'customer_id'
        },
        {
          text: '',
          sortable: false,
          value: 'deleteIcon'
        }
      ]
    },
    repeatscheduleHeaders () {
      return [
        {
          text: this.$t('Start Date'),
          sortable: false,
          value: 'startDate',
          width: '20%'
        },
        {
          text: this.$t('Day'),
          sortable: false,
          value: 'repeatDay',
          width: '15%'
        },
        {
          text: this.$t('Time'),
          sortable: false,
          value: 'applyTime',
          width: '25%'
        },
        {
          text: this.$t('Display Page'),
          sortable: false,
          value: 'displayPage',
          width: '23%'
        },
        {
          text: 'Status',
          sortable: false,
          align: 'center',
          value: 'state',
          width: '15%'
        }
      ]
      // end
    },
    labelHeaders () {
      return [
        {
          text: this.$t('PRODUCT ID'),
          sortable: true,
          value: 'product_id'
        },
        {
          text: this.$t('PRODUCT NAME'),
          sortable: false,
          value: 'product_name'
        },
        {
          text: this.$t('PRICE'),
          sortable: false,
          value: 'price'
        },
        {
          text: '',
          sortable: false,
          value: 'deleteIcon'
        }
      ]
    },
    //  LidL Requirements ends
    // code optimized by sourab
    scheduleHeaders () {
      return [
        { text: this.$t('Start Time'), sortable: false, value: 'startTime', width: '25%' },
        { text: this.$t('End Time'), sortable: false, value: 'endTime', width: '25%' },
        { text: this.$t('Display Page'), sortable: false, value: 'displayPage', width: '25%' },
        { text: this.$t('Status'), sortable: false, value: 'state', width: '25%' }

      ].map(scheduleHeader => ({
        ...scheduleHeader,
        width: '25%',
        sortable: false
      }))
      // end
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    topImage () {
      var image = ''
      this.currentImages.forEach(element => {
        if (element.index === this.activePage) {
          if (this.productionType === 'cloud') {
            image = element.content
          } else {
            image = 'data:image/jpeg;base64,' + element.content
          }
          this.labelStatus = element.state
          this.processUpdateTime = commons.convertDate(element.processUpdateTime)
          this.statusUpdateTime = commons.isNull(element.statusUpdateTime) ? '-' : commons.convertDate(element.statusUpdateTime)
          this.rotationCurrent = 0
          this.rotationPrevious = 0
          this.rotationScale = '1,1,1'
        }
      })
      return image
    }
  },
  watch: {
    // Auto toggle
    dialog2 (e) {
      if (e === true) {
        requestAnimationFrame(() => {
          this.$refs.expand1.focus()
          setTimeout(() => {
            this.$refs.expand1.activateMenu()
          }, 300)
        })
      }
    }
    // expand
  },
  destroyed () {
    EventBus.$off('templatetype')
  },
  mounted () {
    //  LidL Requirements
    EventBus.$on('templatetype', () => {
      this.getTemplates()
      this.CIData = []
      this.templates = []
      this.customerInfo = []
      if (this.CUSTOMER === 'LIDL' && this.selectedStore) {
        this.getCICustomerInfo()
      }
    })
    this.clearIndex()
    this.labelItems = this.items
    if (this.CUSTOMER === 'LIDL' && this.selectedStore) {
      this.getCICustomerInfo()
    }
    //  LidL Requirements ends
    EventBus.$on('getLabelDetailPopupDataValues', async (item) => {
      this.initData(item.id)
      if (item.status !== codes.labelStatus.UNASSIGNED && item.status !== codes.labelStatus.UNASSIGNING) {
        const scheduleList = await this.getScheduleList(item.id)
        this.showScheduleList(scheduleList)
      }
      this.getCurrentImage(item.id)
      this.getLabelInfo(item.id)
    })
    EventBus.$on('openLabelDetail', () => {
      if (this.labelDetailDisabled) return
      this.dialog_popupLabel = true
    })
    this.$store.dispatch('auth/getDisabledBtn', '2100').then((flag) => {
      this.labelUnassignDisabled = flag
    })
    this.$store.dispatch('auth/getDisabledBtn', '2104').then((flag) => {
      this.lableImagePushDisabled = flag
    })
  },
  beforeDestroy () {
    EventBus.$off('getLabelDetailPopupDataValues')
    EventBus.$off('openLabelDetail')
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    //  LidL Requirements
    // / response for gettemplate
    handleGetTempaltesResponse (res) {
      if (res.status === 204) {
        this.handleGetTempaltesError('No content')
        return
      }
      this.selected = []
      if (res.status === 200) {
        this.templates = []
        res.data.forEach((item, index) => {
          item.sno = index + 1
          var templateAddress = item.typeName.substr(0, 3)
          if (item.typeName.includes('ADD') || item.typeName.includes('PRD') || item.typeName.includes('CI')) {
            if (this.type.match(templateAddress)) {
              var countTemplate = ''
              countTemplate = (item.typeName.match(/CI/g) || []).length
              if (countTemplate !== 3) { this.templates.push(item.typeName) }
            }
          }
        })
        // this.templates = res.data
        if (res.data.length !== 0) {
          this.selected.push(this.templates[0])
        }
        this.totalTemplates = this.templates.length
        this.getSelectedType = this.templates[0]
      }
    },
    handleGetTempaltesError (error) {
      this.templates = []
      this.totalTemplates = this.templates.length
      this.selected = []
      console.debug(error)
    },
    getTemplates () {
      const params = { size: '2000' }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTemplates.method,
          requests.getTemplates.url,
          config
        )
        .then((res) => {
          this.handleGetTempaltesResponse(res)
        })
        .catch((error) => {
          this.handleGetTempaltesError(error)
        })
    },
    openmodel () {
      this.dialog2 = true
    },
    clearIndex () {
      this.clearCOSettingsData()
      this.assigneArticleListValue = []
      this.assigneArticleCIPRDListValue = []
      this.assigneArticlePRDListValue = []
      this.assigneArticlePRDCIListValue = []
      this.PRDCIAssigneArticleListValue = []
      this.CIAssigneArticleListValue = []
      this.items = []
      this.labelItems = []
      this.labelCIItems = []
      this.duplicateData = []
      this.leftNeighbourValue = ''
      this.rightNeighbourValue = ''
      this.leftNeighbour = false
      this.rightNeighbour = false
      this.showTable = false
      this.showPRDTable = false
      this.toggleSelect = true
      this.isCIPRD = false
      this.PRDCICount = []
      this.CIPRDCount = []
      this.AIBxCount = []
      this.AIBxCICount = []
      this.ADDCount = []
      this.templateTypeString = ''
      this.templateTypeCIString = ''
      this.templateSize = this.templatetype.split(/[_]+/)
      this.templateTypeString = (this.templatetype.match(/PRD/g) || []).length
      this.templateTypeCIString = (this.templatetype.match(/CI/g) || []).length
      this.isPRD_CIString = this.templatetype.includes('PRD.CI')
      this.isCI_PRDString = this.templatetype.includes('CI.PRD')
      this.isAddString = this.templatetype.includes('ADD')
      if (this.templateTypeString >= 1 && !this.isPRD_CIString && !this.isCI_PRDString) {
        console.log('PRD', this.templateTypeString)
        this.AIBxCount = ['x']
      }
      if (this.templateTypeCIString >= 1 && !this.isPRD_CIString && !this.isCI_PRDString) {
        console.log('CI', this.templateTypeCIString)
        this.AIBxCICount = ['y']
      }
      if (this.isPRD_CIString) {
        console.log('PRDCI', this.isPRD_CIString)
        this.PRDCICount = ['G']
      }
      if (this.isCI_PRDString) {
        console.log('CIPRD', this.isCI_PRDString)
        this.CIPRDCount = ['k']
      }
      if (this.isAddString) {
        console.log('ADD', this.isAddString)
        this.ADDCount = ['I']
      }
      this.isTemplateTypeString = this.templatetype.includes('PRD')
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // PRD Starts
    getAssignInfoList (event) {
      this.eventValue = event
      this.clearCOSettingsData()
      this.toggleSelectCheck = true
      EventBus.$emit('loadingOn')
      this.addPlusIconDisable = true
      this.articleData = []
      this.PRDarticleData = this.assigneArticleListValue[this.assigneArticleListValue.length - 1]
      if (this.assigneArticleListValue[event] !== undefined) {
        // const allUnique = !this.assigneArticleListValue.some((v, i) => this.assigneArticleListValue.indexOf(v) < i)
        // var isDuplicate = -1
        // for (let a = 0; a < this.labelItems.length; a++) {
        //   console.log(this.labelItems[a].product_id, this.assigneArticleListValue)
        //   isDuplicate = this.labelItems[a].product_id.localeCompare(this.assigneArticleListValue)
        // }
        // if (allUnique && isDuplicate !== 0) {
        const url = '/api/common/articles/sub'
        const config = { params: {} }
        config.params.stationCode = this.selectedStore
        config.params.articleId = this.assigneArticleListValue[event]
        this.$utils.callAxios('get', url, config)
          .then(response => {
            EventBus.$emit('loadingOff')
            console.log(response)
            this.PRDdata = response.data
            if (response !== '') {
              if (response.data.length > 1) {
                this.openmodel(event)
                for (var i = 0; i < response.data.length; i++) {
                  this.articleData.push(response.data[i])
                  for (var x in this.articleData[i].data) {
                    if (x === 'origin' || x === 'ORIGIN') {
                      this.origin_list.push(this.articleData[i].data[x])
                      this.origin_list = [...new Set(this.origin_list)]
                    }
                  }
                }
              } else if (response.data.length === 1 && response.data !== '' && response.status !== 400) {
                this.showTable = true
                this.showPRDTable = true
                for (var j = 0; j < response.data.length; j++) {
                  const PRDobj = {
                    product_id: response.data[j].id,
                    product_name: response.data[j].name,
                    price: response.data[j].data.SALE_PRICE
                  }
                  this.labelItems.splice(event, 1, PRDobj)
                }
              } else {
                EventBus.$emit('loadingOff')
                this.addPlusIconDisable = false
                // const PRDindex = this.assigneArticleListValue.indexOf(this.PRDarticleData)
                // for (let c = 0; c < this.labelItems.length; c++) {
                //   var tableIndex = this.labelItems[c].findIndex(object => {
                //     return object.product_id === this.assigneArticleListValue
                //   })
                // }
                // console.log(tableIndex)
                this.assigneArticleListValue.splice(event, 1)
                this.labelItems.splice(event, 1)
                EventBus.$emit(
                  'messageAlert',
                  this.$t('Enter the valid product Id')
                )
                if (this.labelItems.length === 0) {
                  this.showTable = false
                }
              }
            }
          })
          .catch(e => {
            EventBus.$emit('loadingOff')
            this.errors.push(e)
          })
        // } else {
        //   EventBus.$emit(
        //     'messageAlert',
        //     this.$t('ProductId Should not be same1')
        //   )
        //   EventBus.$emit('loadingOff')
        //   if (this.assigneArticleListValue.length > 1) {
        //     this.assigneArticleListValue.splice(event, 1)
        //     this.labelItems.splice(event, 1)
        //   }
        // }
      } else {
        EventBus.$emit('loadingOff')
        EventBus.$emit(
          'messageAlert',
          this.$t('Please enter ProductId')
        )
      }
    },
    removeLidL (event, index) {
      console.log(index)
      this.labelItems.splice(index, 1)
      this.assigneArticleListValue.splice(index, 1)
      if (this.assigneArticleListValue.length >= 1) {
        this.AIBxCount.splice(index, 1)
      }
    },
    showPlus () {
      // PRD>1
      // AIBxCount <= PrD
      const condition1 = this.templateTypeString > 1
      const condition2 = this.AIBxCount.length < this.templateTypeString
      return condition1 && condition2
    },
    onSubmitPRD () {
      this.dialog2 = false
      this.showTable = true
      this.showPRDTable = true
      if (this.assigneArticleCIPRDListValue.length >= 1 || this.assigneArticlePRDCIListValue.length >= 1 || this.assigneArticleListValue.length === 1) {
        this.labelItems = []
      }
      const submitData = []
      let submitList = []
      for (let j = 0; j < this.articleData.length; j++) {
        const obj = {
          origin: this.articleData[j].data.origin,
          variety: this.articleData[j].data.variety,
          caliberMax: this.articleData[j].data.caliberMax,
          caliberMin: this.articleData[j].data.caliberMin,
          caliberUnit: this.articleData[j].data.caliberUnit,
          class: this.articleData[j].data.class,
          certificates: this.articleData[j].data.certificates
        }
        submitData.push(obj)
      }

      submitList = [...new Set(submitData.map(JSON.stringify))].map(JSON.parse) // Remove duplicate objects

      for (let h = 0; h < this.articleData.length; h++) {
        const dataItem = this.articleData[h].data
        if (
          submitList[h].origin === this.origin &&
          submitList[h].variety === this.variety &&
          submitList[h].caliberMax === this.caliber_max &&
          submitList[h].caliberMin === this.caliber_min &&
          submitList[h].caliberUnit === this.caliber_unit &&
          submitList[h].class === this.clasess &&
          submitList[h].certificates === this.certificates
        ) {
          const PRDobj1 = {
            product_id: this.articleData[h].id,
            product_name: this.articleData[h].name,
            price: dataItem.SALE_PRICE
          }
          this.labelItems.splice(this.eventValue, 1, PRDobj1)
          if (this.labelItems.length === 1) {
            this.assigneArticleListValue[0] = PRDobj1.product_id
          } else {
            this.assigneArticleListValue[h] = this.labelItems[h].product_id
          }
        }
      }

      this.duplicateData = this.assigneArticlePRDCIListValue
      this.clearCOSettingsData()
    },
    // PRD Ends
    // CI Starts
    onSubmit (event) {
      this.dialog2 = false
      this.showTable = true
      this.items = []
      this.CIobj = []
      // var CIarticleData = []
      // CIarticleData = this.CIAssigneArticleListValue[this.CIAssigneArticleListValue.length - 1]
      // const allCIUnique = !this.CIAssigneArticleListValue.some((v, i) => this.CIAssigneArticleListValue.indexOf(v) < i)
      // if (allCIUnique) {
      let CIobj = []
      if (this.CIAssigneArticleListValue !== '') {
        CIobj = {
          customer_id: this.CIAssigneArticleListValue[event]
        }
        this.labelCIItems.splice(event, 1, CIobj)
      }
      // } else {
      //   EventBus.$emit(
      //     'messageAlert',
      //     this.$t('Customer Info Should not be same')
      //   )
      //   console.log(event)
      //   console.log(this.CIAssigneArticleListValue[event])
      //   console.log(this.labelCIItems[event])
      //   this.CIAssigneArticleListValue.splice(event, 1)
      //   this.labelCIItems.splice(event, 1)
      // }
    },
    showCIPlus () {
      // CI>1
      // AIBxCICount <= CI
      const condition3 = this.templateTypeCIString > 1
      const condition4 = this.AIBxCICount.length < this.templateTypeCIString
      return condition3 && condition4
    },
    removeCILidL (e) {
      console.log(e)
      var data = e.customer_id
      for (var i = 0; i < this.CIAssigneArticleListValue.length; i++) {
        const index = this.labelCIItems[0].customer_id.indexOf(this.CIAssigneArticleListValue[i])
        if (data === this.CIAssigneArticleListValue[i]) {
          this.labelCIItems.splice(index, 1)
          this.CIAssigneArticleListValue.splice(index, 1)
          if (this.CIAssigneArticleListValue.length >= 1) {
            this.AIBxCICount.splice(index, 1)
          }
        }
      }
    },
    // CI.PRD starts
    showCIPRDPlus () {
      this.CIPRDCount.push('k')
    },
    getAssignPRDInfoList () {
      this.showCIPRDPlus()
      this.clearCOSettingsData()
      this.toggleSelectCheck = true
      EventBus.$emit('loadingOn')
      this.isCIPRD = false
      this.articleData = []
      if (this.assigneArticlePRDListValue !== '') {
        const url = '/api/common/articles/sub'
        const config = { params: {} }
        config.params.stationCode = this.selectedStore
        config.params.articleId = this.assigneArticlePRDListValue
        this.$utils.callAxios('get', url, config)
          .then(response => {
            EventBus.$emit('loadingOff')
            // const url = `/api/common/articles/sub?stationCode=${this.selectedStore}&articleId=${this.assigneArticlePRDListValue}`
            // axios.get(configs.ServerAddress + url)
            //   .then(response => {
            console.log(response)
            this.PRDdata = response.data
            if (response !== '') {
              if (response.data.length > 1) {
                this.openmodel()
                for (var i = 0; i < response.data.length; i++) {
                  this.articleData.push(response.data[i])
                  for (var x in this.articleData[i].data) {
                    if (x === 'origin') {
                      this.origin_list.push(this.articleData[i].data[x])
                      this.origin_list = [...new Set(this.origin_list)]
                    }
                  }
                }
              } else if (response.data.length === 1 && response.data !== '') {
                this.showTable = true
                this.showPRDTable = true
                this.labelItems = []
                for (var h = 0; h < response.data.length; h++) {
                  const PRDobj = {
                    product_id: response.data[h].id,
                    product_name: response.data[h].name,
                    price: response.data[h].data.SALE_PRICE
                  }
                  this.labelItems.push(PRDobj)
                  this.duplicateData = this.assigneArticlePRDListValue
                }
              } else {
                EventBus.$emit('loadingOff')
                this.assigneArticlePRDListValue = []
                EventBus.$emit(
                  'messageAlert',
                  this.$t('Enter the valid product Id')
                )
                const PRDindex3 = this.assigneArticlePRDListValue.indexOf(this.PRDarticleData)
                this.assigneArticlePRDListValue.splice(PRDindex3, 1)
                this.labelItems.splice(PRDindex3, 1)
                this.showPRDTable = false
                this.duplicateData = []
              }
            }
          })
          .catch(e => {
            EventBus.$emit('loadingOff')
            this.errors.push(e)
          })
      }
    },
    onCIPRDSubmit () {
      this.showCIPRDPlus()
      this.showTable = true
      this.labelCIItems = []
      this.isCIPRD = false
      this.CISelectedValue = this.customerInfo
      if (this.assigneArticleCIPRDListValue !== '') {
        const CIObj = {
          customer_id: this.assigneArticleCIPRDListValue
        }
        this.labelCIItems.push(CIObj)
      }
    },
    removeCIPRDLidL (e) {
      this.assigneArticleCIPRDListValue = []
      this.labelCIItems = []
      this.showTable = false
      this.isCIPRD = true
    },
    removePRDLidL () {
      this.assigneArticlePRDListValue = []
      this.labelItems = []
      this.duplicateData = []
      this.showPRDTable = false
      this.isCIPRD = true
    },
    // CI.PRD ends
    // PRD.CI starts
    showPRDCIPlus () {
      this.PRDCICount.push('G')
    },
    getAssignPRDCIInfoList () {
      this.showPRDCIPlus()
      this.clearCOSettingsData()
      this.toggleSelectCheck = true
      EventBus.$emit('loadingOn')
      this.articleData = []
      if (this.assigneArticlePRDCIListValue !== '') {
        const url = '/api/common/articles/sub'
        const config = { params: {} }
        config.params.stationCode = this.selectedStore
        config.params.articleId = this.assigneArticlePRDCIListValue
        this.$utils.callAxios('get', url, config)
          .then(response => {
            // const url = `api/common/articles/sub?stationCode=${this.selectedStore}&articleId=${this.assigneArticlePRDCIListValue}`
            // axios.get(configs.ServerAddress + url)
            //   .then(response => {
            console.log(response)
            EventBus.$emit('loadingOff')
            this.PRDdata = response.data
            if (response !== '') {
              if (response.data.length > 1) {
                this.openmodel()
                for (let i = 0; i < response.data.length; i++) {
                  this.articleData.push(response.data[i])
                  for (var x in this.articleData[i].data) {
                    if (x === 'origin') {
                      this.origin_list.push(this.articleData[i].data[x])
                      this.origin_list = [...new Set(this.origin_list)]
                    }
                  }
                }
              } else if (response.data.length === 1 && response.data !== '') {
                this.showTable = true
                this.labelItems = []
                this.showPRDTable = true
                for (var h = 0; h < response.data.length; h++) {
                  const PRDobj = {
                    product_id: response.data[h].id,
                    product_name: response.data[h].name,
                    price: response.data[h].data.SALE_PRICE
                  }
                  this.labelItems.push(PRDobj)
                  this.duplicateData = this.assigneArticlePRDCIListValue
                }
              } else {
                EventBus.$emit('loadingOff')
                this.assigneArticlePRDCIListValue = []
                EventBus.$emit(
                  'messageAlert',
                  this.$t('Enter the valid product Id')
                )
                const PRDindex4 = this.assigneArticlePRDCIListValue.indexOf(this.PRDarticleData)
                this.assigneArticlePRDCIListValue.splice(PRDindex4, 1)
                this.labelItems.splice(PRDindex4, 1)
                this.showTable = false
                this.duplicateData = []
              }
            }
          })
          .catch(e => {
            EventBus.$emit('loadingOff')
            this.errors.push(e)
          })
      }
    },
    removePRDCILidL () {
      this.assigneArticlePRDCIListValue = []
      this.labelItems = []
      this.showTable = false
      this.duplicateData = []
    },
    CIremoveLidL () {
      this.PRDCIAssigneArticleListValue = []
      this.labelCIItems = []
      this.showCITable = false
    },
    onPRDCISubmit () {
      this.showPRDCIPlus()
      this.showCITable = true
      this.labelCIItems = []
      if (this.PRDCIAssigneArticleListValue !== '') {
        const CIObj = {
          customer_id: this.PRDCIAssigneArticleListValue
        }
        this.labelCIItems.push(CIObj)
      }
    },
    // PRD.CI ends
    // ADD starts
    getLabelIds (code, pos) {
      console.log('getttttttttt', this.leftNeighbourValue)
      if (this.leftNeighbourValue !== '' || this.rightNeighbourValue !== '') {
        var rightNeighbourBlock1 = document.getElementById('rightNeighbour')
        if (rightNeighbourBlock1) {
          rightNeighbourBlock1.setAttribute(
            'style',
            'margin-left:0% !important;width: 50% !important;padding-left: 10px;'
          )
        }
        if (
          this.labelId === this.leftNeighbourValue ||
          this.labelId === this.rightNeighbourValue
        ) {
          EventBus.$emit(
            'messageAlert',
            this.$t('LabelId Should not be same')
          )
          this.labelId === this.leftNeighbourValue
            ? (this.leftNeighbourValue = '')
            : (this.rightNeighbourValue = '')
        } else if (
          this.leftNeighbourValue === this.rightNeighbourValue &&
          this.leftNeighbourValue !== '' &&
          this.rightNeighbourValue !== ''
        ) {
          EventBus.$emit(
            'messageAlert',
            this.$t('LabelId Should not be same')
          )
          this.rightNeighbourValue = ''
          this.rightNeighbour = false
        } else {
          // const url = `/labels/type?code=${code}`
          // axios
          //   .get(configs.ServerAddress + url)
          //   .then((response) => { EventBus.$emit('loadingOff')
          EventBus.$emit('loadingOn')
          const url = '/api/common/labels/type/one'
          const config = { params: {} }
          config.params.labelCode = code
          this.$utils.callAxios('get', url, config)
            .then(response => {
              EventBus.$emit('loadingOff')
              if (response.data === '') {
                EventBus.$emit('messageAlert', this.$t('Invalid Label code'))
              } else {
                const test =
                  (response.data.name.includes('5_85')) &&
                  !response.data.name.includes('FREEZER')
                console.log('testtttt', test, response.data.labelCode)
                if (test && response.status === 200) {
                  console.log('responseee222', response, this.rightNeighbourValue, this.leftNeighbourValue)
                  if (pos === 'left') {
                    this.leftNeighbour = true
                    this.getLeftNeighbourValue(response.data.labelCode)
                  }
                  if (pos === 'right') {
                    console.log('this.rightNeighbour', this.rightNeighbour)
                    this.rightNeighbour = true
                    this.getRightNeighbourValue(response.data.labelCode)
                  }
                  // EventBus.$emit('messageAlert', this.$t('Label code updated succesfully'))
                } else {
                  console.log('Inside eventbus', response.status)
                  EventBus.$emit('messageAlert', this.$t('Invalid Label code'))
                }
              }
            })
            .catch((e) => {
              EventBus.$emit('loadingOff')
              EventBus.$emit('messageAlert', this.$t('Invalid Label code'))
            })
        }
      } else {
        var rightNeighbourBlock2 = document.getElementById('rightNeighbour')
        if (rightNeighbourBlock2) {
          rightNeighbourBlock2.setAttribute(
            'style',
            'margin-left:50% !important;width: 50% !important;padding-left: 10px;'
          )
        }
      }
    },
    addPopUp (e, pos) {
      console.log('adddpopup', this.rightNeighbourValue, pos, e.target.value, e.target.value.length)
      if (e.key === 'Backspace' || e.key === 'Delete') {
        var value = e.target.value
        console.log(value)
        if (pos === 'left') {
          console.log('backkkkkkkkkkkkkkkkkkkkkkkkkk')
          this.leftNeighbour = false
        }
        if (pos === 'right') {
          this.rightNeighbour = false
        }
      }
    },
    removeAddLeftLidL (event) {
      if (this.leftNeighbourValue) {
        console.log('removeLEftdhfbvjd,jfked')
        this.leftNeighbourValue = ''
        this.leftNeighbour = false
        var rightNeighbourBlock = document.getElementById('rightNeighbour')
        if (rightNeighbourBlock) {
          rightNeighbourBlock.setAttribute(
            'style',
            'margin-left:50% !important;width: 50% !important;padding-left: 10px;'
          )
        }
      }
    },
    removeLidLRight (event) {
      if (this.rightNeighbourValue) {
        this.rightNeighbourValue = ''
        this.rightNeighbour = false
      }
      console.log('removvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv', this.rightNeighbour)
      this.items.splice(
        this.items.findIndex(function (i) {
          return i.product_id === event.product_id
        }),
        1
      )
      var inputIndex = this.assigneArticleListValue.indexOf(event.product_id)
      this.assigneArticleListValue.splice(inputIndex, 1)
      this.showTable = false
    },
    getLeftNeighbourValue (labelCode) {
      EventBus.$emit('loadingOn')
      console.log(labelCode, 'LEFT')
      if (labelCode !== '') {
        const url = '/api/common/labels'
        const config = { params: {} }
        config.params.store = this.selectedStore
        config.params.label = labelCode

        this.$utils.callAxios('get', url, config)
          .then(response => {
            EventBus.$emit('loadingOff')
            if (response.status !== 200) {
              this.leftNeighbour = false
              EventBus.$emit('messageAlert', this.$t('No data found'))
            } else {
              EventBus.$emit('loadingOff')
              console.log('producttttttttttttttt', response.data.labelList[0].articleList[0].articleId)
              this.productIdLeft = response.data.labelList[0].articleList[0].articleId
              this.productNameLeft = response.data.labelList[0].articleList[0].articleName
              console.log('downproductttt', this.productIdLeft)
            }
            console.log('responseee', response)
          })
          .catch((e) => {
            EventBus.$emit('loadingOff')
            EventBus.$emit('messageAlert', this.$t('Invalid Label code'))
          })
      }
    },
    getRightNeighbourValue (labelCode) {
      EventBus.$emit('loadingOn')
      console.log(labelCode, 'RIGHT')
      const url = '/api/common/labels'
      const config = { params: {} }
      config.params.store = this.selectedStore
      config.params.label = labelCode
      this.$utils.callAxios('get', url, config)
        .then(response => {
          EventBus.$emit('loadingOff')
          if (response.status !== 200) {
            this.rightNeighbour = false
            EventBus.$emit('messageAlert', this.$t('No data found'))
          } else {
            EventBus.$emit('loadingOff')
            console.log('righttttttttt', response.data.labelList[0].articleList[0].articleId)
            this.productIdRight = response.data.labelList[0].articleList[0].articleId
            this.productNameRight = response.data.labelList[0].articleList[0].articleName
          }
          console.log('responseee', response)
        })
        .catch((e) => {
          EventBus.$emit('loadingOff')
          EventBus.$emit('messageAlert', this.$t('Invalid Label code'))
        })
    },
    clearLabel () {
      console.log(
        'clearrrrrrrrrrrr',
        this.templatetype,
        this.isAddString,
        this.leftNeighbourValue
      )
      this.leftNeighbourValue = ''
      console.log(
        'belowww',
        this.templatetype,
        this.isAddString,
        this.leftNeighbourValue
      )
    },
    // ADD Ends
    click_select () {
      this.varietyUniqueCheck = false
      this.calMaxUniqueCheck = false
      this.calMinUniqueCheck = false
      this.calUnitUniqueCheck = false
      this.classUniqueCheck = false
      this.cretiUniqueCheck = false
      this.toggleSelectCheck = true
      this.varietyToggleCheck = false
      this.caliberMaxToggleCheck = false
      this.caliberMinToggleCheck = false
      this.caliberUnitToggleCheck = false
      this.clasessToggleCheck = false
      this.certificatesToggleCheck = false
      this.varietyToggle = false
      this.caliberMaxToggle = false
      this.caliberMinToggle = false
      this.caliberUnitToggle = false
      this.clasessToggle = false
      this.certificatesToggle = false
    },
    click_toggle () {
      this.toggleSelectCheck = false
      this.varietyToggle = true
      this.varietyToggleCheck = true
      this.variety = ''
      this.caliber_max = ''
      this.caliber_min = ''
      this.caliber_unit = ''
      this.clasess = ''
      this.certificates = ''
      this.variety_list = []
      this.caliber_max_list = []
      this.caliber_min_list = []
      this.caliber_unit_list = []
      this.class_list = []
      this.certificates_list = []
      this.obj = []
      this.varietyObj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.origin)
      }
      console.log(this.obj)
      this.objFilteredData = [...new Set(this.origin_list)]
      this.varietyObj = [...new Set(this.origin_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.origin)) {
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.origin === this.articleData[l].data.origin) {
            this.uniqueCheck = true
            this.toggleSelectCheck = false
            this.varietyToggleCheck = false
            this.caliberMaxToggleCheck = false
            this.caliberMinToggleCheck = false
            this.caliberUnitToggleCheck = false
            this.clasessToggleCheck = false
            this.certificatesToggleCheck = false
            this.varietyToggle = false
            this.caliberMaxToggle = false
            this.caliberMinToggle = false
            this.caliberUnitToggle = false
            this.clasessToggle = false
            this.certificatesToggle = false
            this.varietyUniqueCheck = true
            this.calMaxUniqueCheck = true
            this.calMinUniqueCheck = true
            this.calUnitUniqueCheck = true
            this.classUniqueCheck = true
            this.cretiUniqueCheck = true
            this.variety = this.articleData[l].data.variety
            this.caliber_max = this.articleData[l].data.caliberMax
            this.caliber_min = this.articleData[l].data.caliberMin
            this.caliber_unit = this.articleData[l].data.caliberUnit
            this.clasess = this.articleData[l].data.class
            this.certificates = this.articleData[l].data.certificates
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
          }
        }
      } else {
        for (var i = 0; i < this.articleData.length; i++) {
          if (this.articleData[i].data.origin === this.origin) {
            this.variety_list.push(this.articleData[i].data.variety)
          }
        }
        this.unique = []
        this.varietyUniqueCheck = false
        this.calMaxUniqueCheck = false
        this.calMinUniqueCheck = false
        this.calUnitUniqueCheck = false
        this.classUniqueCheck = false
        this.cretiUniqueCheck = false
        // auto toggle
        requestAnimationFrame(() => {
          this.$refs.expand2.focus()
          setTimeout(() => {
            this.$refs.expand2.activateMenu()
          }, 300)
        })
      }
    },
    click_select_variety () {
      this.varietyToggleCheck = true
      this.caliberMaxToggleCheck = false
      this.caliberMinToggleCheck = false
      this.caliberUnitToggleCheck = false
      this.clasessToggleCheck = false
      this.certificatesToggleCheck = false
      this.caliberMaxToggle = false
      this.caliberMinToggle = false
      this.caliberUnitToggle = false
      this.clasessToggle = false
      this.certificatesToggle = false
      this.calMaxUniqueCheck = false
      this.calMinUniqueCheck = false
      this.calUnitUniqueCheck = false
      this.classUniqueCheck = false
      this.cretiUniqueCheck = false
    },
    click_toggle_variety () {
      this.varietyToggleCheck = false
      this.caliberMaxToggle = true
      this.caliberMaxToggleCheck = true
      this.caliber_max = ''
      this.caliber_min = ''
      this.caliber_unit = ''
      this.clasess = ''
      this.certificates = ''
      this.caliber_max_list = []
      this.caliber_min_list = []
      this.caliber_unit_list = []
      this.class_list = []
      this.certificates_list = []
      this.objFilteredData = []
      this.obj = []
      this.calMaxObj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.variety)
      }
      this.objFilteredData = [...new Set(this.variety_list)]
      this.calMaxObj = [...new Set(this.variety_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.variety)) {
        this.toggleSelectCheck = false
        this.uniqueCheck = true
        this.varietyToggleCheck = false
        this.caliberMaxToggleCheck = false
        this.caliberMinToggleCheck = false
        this.caliberUnitToggleCheck = false
        this.clasessToggleCheck = false
        this.certificatesToggleCheck = false
        this.varietyToggle = true
        this.caliberMaxToggle = false
        this.caliberMinToggle = false
        this.caliberUnitToggle = false
        this.clasessToggle = false
        this.certificatesToggle = false
        this.varietyUniqueCheck = false
        this.calMaxUniqueCheck = true
        this.calMinUniqueCheck = true
        this.calUnitUniqueCheck = true
        this.classUniqueCheck = true
        this.cretiUniqueCheck = true
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.articleData[l].data.variety === this.variety) {
            this.caliber_max = this.articleData[l].data.caliberMax
            this.caliber_min = this.articleData[l].data.caliberMin
            this.caliber_unit = this.articleData[l].data.caliberUnit
            this.clasess = this.articleData[l].data.class
            this.certificates = this.articleData[l].data.certificates
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
          }
        }
      } else {
        for (var i = 0; i < this.articleData.length; i++) {
          if (this.articleData[i].data.variety === this.variety &&
          this.articleData[i].data.origin === this.origin) {
            this.caliber_max_list.push(this.articleData[i].data.caliberMax)
          }
        }
        this.unique = []
        this.uniqueCheck = false
        requestAnimationFrame(() => {
          this.$refs.expand3.focus()
          setTimeout(() => {
            this.$refs.expand3.activateMenu()
          }, 300)
        })
      }
    },
    click_select_CaliMax () {
      this.caliberMaxToggleCheck = true
      this.caliberMinToggleCheck = false
      this.caliberUnitToggleCheck = false
      this.clasessToggleCheck = false
      this.certificatesToggleCheck = false
      this.caliberMinToggle = false
      this.caliberUnitToggle = false
      this.clasessToggle = false
      this.certificatesToggle = false
      this.calMinUniqueCheck = false
      this.calUnitUniqueCheck = false
      this.classUniqueCheck = false
      this.cretiUniqueCheck = false
    },
    click_toggle_CaliMax () {
      this.caliberMaxToggleCheck = false
      this.caliberMinToggle = true
      this.caliberMinToggleCheck = true
      this.caliber_min = ''
      this.caliber_unit = ''
      this.clasess = ''
      this.certificates = ''
      this.caliber_min_list = []
      this.caliber_unit_list = []
      this.class_list = []
      this.certificates_list = []
      this.objFilteredData = []
      this.obj = []
      this.calMinObj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.caliberMax)
      }
      this.objFilteredData = [...new Set(this.caliber_max_list)]
      this.calMinObj = [...new Set(this.caliber_max_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.caliber_max)) {
        this.caliberMaxToggleCheck = false
        this.uniqueCheck = true
        this.toggleSelectCheck = false
        this.varietyToggleCheck = false
        this.caliberMaxToggleCheck = false
        this.caliberMinToggleCheck = false
        this.caliberUnitToggleCheck = false
        this.clasessToggleCheck = false
        this.certificatesToggleCheck = false
        this.varietyToggle = false
        this.caliberMaxToggle = true
        this.caliberMinToggle = false
        this.caliberUnitToggle = false
        this.clasessToggle = false
        this.certificatesToggle = false
        this.varietyUniqueCheck = true
        this.calMaxUniqueCheck = false
        this.calMinUniqueCheck = true
        this.calUnitUniqueCheck = true
        this.classUniqueCheck = true
        this.cretiUniqueCheck = true
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.articleData[l].data.caliberMax === this.caliber_max) {
            this.caliber_min = this.articleData[l].data.caliberMin
            this.caliber_unit = this.articleData[l].data.caliberUnit
            this.clasess = this.articleData[l].data.class
            this.certificates = this.articleData[l].data.certificates
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
          }
        }
      } else {
        this.varietyToggle = true
        for (var i = 0; i < this.articleData.length; i++) {
          if (this.articleData[i].data.variety === this.variety &&
          this.articleData[i].data.origin === this.origin && this.articleData[i].data.caliberMax === this.caliber_max) {
            this.caliber_min_list.push(this.articleData[i].data.caliberMin)
          }
        }
        this.unique = []
        this.varietyUniqueCheck = false
        this.calMaxUniqueCheck = false
        this.calMinUniqueCheck = false
        this.calUnitUniqueCheck = false
        this.classUniqueCheck = false
        this.cretiUniqueCheck = false
        // auto toggle
        requestAnimationFrame(() => {
          this.$refs.expand4.focus()
          setTimeout(() => {
            this.$refs.expand4.activateMenu()
          }, 300)
        })
      }
    },
    click_select_CaliMin () {
      this.uniqueCheck = false
      this.caliberMinToggleCheck = true
      this.caliberUnitToggleCheck = false
      this.clasessToggleCheck = false
      this.certificatesToggleCheck = false
      this.caliberUnitToggle = false
      this.clasessToggle = false
      this.certificatesToggle = false
      this.calUnitUniqueCheck = false
      this.classUniqueCheck = false
      this.cretiUniqueCheck = false
    },
    click_toggle_CaliMin () {
      this.caliberMinToggleCheck = false
      this.caliberUnitToggle = true
      this.caliberUnitToggleCheck = true
      this.caliber_unit = ''
      this.clasess = ''
      this.certificates = ''
      this.caliber_unit_list = []
      this.class_list = []
      this.certificates_list = []
      this.objFilteredData = []
      this.obj = []
      this.calUnitObj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.caliberMin)
      }
      this.objFilteredData = [...new Set(this.caliber_min_list)]
      this.calUnitObj = [...new Set(this.caliber_min_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.caliber_min)) {
        this.toggleSelectCheck = false
        this.uniqueCheck = true
        this.varietyToggleCheck = false
        this.caliberMaxToggleCheck = false
        this.caliberMinToggleCheck = false
        this.caliberUnitToggleCheck = false
        this.clasessToggleCheck = false
        this.certificatesToggleCheck = false
        this.varietyToggle = false
        this.caliberMaxToggle = false
        this.caliberMinToggle = true
        this.caliberUnitToggle = false
        this.clasessToggle = false
        this.certificatesToggle = false
        this.varietyUniqueCheck = true
        this.calMaxUniqueCheck = true
        this.calMinUniqueCheck = false
        this.calUnitUniqueCheck = true
        this.classUniqueCheck = true
        this.cretiUniqueCheck = true
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.articleData[l].data.caliberMax === this.caliber_max) {
            this.caliber_unit = this.articleData[l].data.caliberUnit
            this.clasess = this.articleData[l].data.class
            this.certificates = this.articleData[l].data.certificates
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
          }
        }
      } else {
        this.varietyToggle = true
        this.caliberMaxToggle = true
        for (var i = 0; i < this.articleData.length; i++) {
          if (this.articleData[i].data.variety === this.variety &&
          this.articleData[i].data.origin === this.origin &&
          this.articleData[i].data.caliberMax === this.caliber_max &&
          this.articleData[i].data.caliberMin === this.caliber_min) {
            this.caliber_unit_list.push(this.articleData[i].data.caliberUnit)
          }
        }
        this.unique = []
        this.varietyUniqueCheck = false
        this.calMaxUniqueCheck = false
        this.calMinUniqueCheck = false
        this.calUnitUniqueCheck = false
        this.classUniqueCheck = false
        this.cretiUniqueCheck = false
        // auto toggle
        requestAnimationFrame(() => {
          this.$refs.expand5.focus()
          setTimeout(() => {
            this.$refs.expand5.activateMenu()
          }, 300)
        })
      }
    },
    click_select_CaliUnit () {
      this.uniqueCheck = false
      this.caliberUnitToggleCheck = true
      this.clasessToggleCheck = false
      this.certificatesToggleCheck = false
      this.clasessToggle = false
      this.certificatesToggle = false
      this.classUniqueCheck = false
      this.cretiUniqueCheck = false
    },
    click_toggle_CaliUnit () {
      this.caliberUnitToggleCheck = false
      this.clasessToggle = true
      this.clasessToggleCheck = true
      this.clasess = ''
      this.certificates = ''
      this.class_list = []
      this.certificates_list = []
      this.objFilteredData = []
      this.obj = []
      this.classObj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.caliberUnit)
      }
      this.objFilteredData = [...new Set(this.caliber_unit_list)]
      this.classObj = [...new Set(this.caliber_unit_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.caliber_unit)) {
        this.toggleSelectCheck = false
        this.uniqueCheck = true
        this.varietyToggleCheck = false
        this.caliberMaxToggleCheck = false
        this.caliberMinToggleCheck = false
        this.caliberUnitToggleCheck = false
        this.clasessToggleCheck = false
        this.certificatesToggleCheck = false
        this.varietyToggle = false
        this.caliberMaxToggle = false
        this.caliberMinToggle = false
        this.caliberUnitToggle = true
        this.clasessToggle = false
        this.certificatesToggle = false
        this.varietyUniqueCheck = true
        this.calMaxUniqueCheck = true
        this.calMinUniqueCheck = true
        this.calUnitUniqueCheck = false
        this.classUniqueCheck = true
        this.cretiUniqueCheck = true
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.articleData[l].data.caliberUnit === this.caliber_unit) {
            this.clasess = this.articleData[l].data.class
            this.certificates = this.articleData[l].data.certificates
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
            console.log('srujana', this.clasess)
          }
        }
      } else {
        this.varietyToggle = true
        this.caliberMaxToggle = true
        this.caliberMinToggle = true
        for (var i = 0; i < this.articleData.length; i++) {
          if (this.articleData[i].data.variety === this.variety &&
          this.articleData[i].data.origin === this.origin &&
          this.articleData[i].data.caliberMax === this.caliber_max &&
          this.articleData[i].data.caliberMin === this.caliber_min &&
          this.articleData[i].data.caliberUnit === this.caliber_unit) {
            this.class_list.push(this.articleData[i].data.class)
          }
        }
        this.unique = []
        this.varietyUniqueCheck = false
        this.calMaxUniqueCheck = false
        this.calMinUniqueCheck = false
        this.calUnitUniqueCheck = false
        this.classUniqueCheck = false
        this.cretiUniqueCheck = false
        // auto toggle
        requestAnimationFrame(() => {
          this.$refs.expand6.focus()
          setTimeout(() => {
            this.$refs.expand6.activateMenu()
          }, 300)
        })
      }
    },
    click_select_Class () {
      this.uniqueCheck = false
      this.clasessToggleCheck = true
      this.certificatesToggle = false
      this.certificatesToggleCheck = false
      this.cretiUniqueCheck = false
    },
    click_toggle_Class () {
      this.clasessToggleCheck = false
      this.certificatesToggle = true
      this.certificatesToggleCheck = true
      this.certificates = ''
      this.certificates_list = []
      this.objFilteredData = []
      this.obj = []
      this.classObj = []
      this.claObj = []
      this.certificateObj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.class)
      }
      this.objFilteredData = [...new Set(this.class_list)]
      this.certificateObj = [...new Set(this.class_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.clasess)) {
        this.toggleSelectCheck = false
        this.uniqueCheck = true
        this.varietyToggleCheck = false
        this.caliberMaxToggleCheck = false
        this.caliberMinToggleCheck = false
        this.caliberUnitToggleCheck = false
        this.clasessToggleCheck = false
        this.certificatesToggleCheck = false
        this.varietyToggle = false
        this.caliberMaxToggle = false
        this.caliberMinToggle = false
        this.caliberUnitToggle = false
        this.clasessToggle = true
        this.certificatesToggle = false
        this.varietyUniqueCheck = true
        this.calMaxUniqueCheck = true
        this.calMinUniqueCheck = true
        this.calUnitUniqueCheck = true
        this.classUniqueCheck = false
        this.cretiUniqueCheck = true
        this.certificates = this.objFilteredData[0].certificates
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.articleData[l].data.class === this.clasess) {
            this.certificates = this.articleData[l].data.certificates
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
          }
        }
      } else {
        this.varietyToggle = true
        this.caliberMaxToggle = true
        this.caliberMinToggle = true
        this.caliberUnitToggle = true
        for (var i = 0; i < this.articleData.length; i++) {
          if (this.articleData[i].data.variety === this.variety &&
          this.articleData[i].data.origin === this.origin &&
          this.articleData[i].data.caliberMax === this.caliber_max &&
          this.articleData[i].data.caliberMin === this.caliber_min &&
          this.articleData[i].data.caliberUnit === this.caliber_unit &&
          this.articleData[i].data.class === this.clasess) {
            this.certificates_list.push(this.articleData[i].data.certificates)
          }
        }
        this.unique = []
        this.varietyUniqueCheck = false
        this.calMaxUniqueCheck = false
        this.calMinUniqueCheck = false
        this.calUnitUniqueCheck = false
        this.classUniqueCheck = false
        this.cretiUniqueCheck = false
        // auto toggle
        requestAnimationFrame(() => {
          this.$refs.expand7.focus()
          setTimeout(() => {
            this.$refs.expand7.activateMenu()
          }, 300)
        })
      }
    },
    click_select_Certificates () {
      this.certificatesToggleCheck = true
    },
    click_toggle_Certificates () {
      this.certificatesToggleCheck = false
      this.objFilteredData = []
      this.obj = []
      this.unique = []
      for (var j = 0; j < this.articleData.length; j++) {
        this.obj.push(this.articleData[j].data.certificates)
      }
      this.objFilteredData = [...new Set(this.certificates_list)]
      this.unique = this.obj.filter((v, i) => this.obj.indexOf(v) === this.obj.lastIndexOf(v))
      if (this.obj.length === this.objFilteredData.length || this.unique.includes(this.certificates)) {
        this.certificatesToggleCheck = false
        for (var l = 0; l < this.articleData.length; l++) {
          if (this.articleData[l].data.certificates === this.certificates) {
            this.toggleSelectCheck = false
            this.varietyToggleCheck = false
            this.caliberMaxToggleCheck = false
            this.caliberMinToggleCheck = false
            this.caliberUnitToggleCheck = false
            this.clasessToggleCheck = false
            this.certificatesToggleCheck = false
            this.varietyToggle = false
            this.caliberMaxToggle = false
            this.caliberMinToggle = false
            this.caliberUnitToggle = false
            this.clasessToggle = false
            this.certificatesToggle = true
            this.name_product = this.articleData[l].name
            this.id_product = this.articleData[l].id
            this.priceValue = this.articleData[l].data.SALE_PRICE
          }
        }
      } else {
      }
    },
    clearCOSettingsData () {
      this.varietyToggle = false
      this.varietyToggleCheck = false
      this.caliberMaxToggle = false
      this.caliberMaxToggleCheck = false
      this.caliberMinToggle = false
      this.caliberMinToggleCheck = false
      this.caliberUnitToggle = false
      this.caliberUnitToggleCheck = false
      this.clasessToggle = false
      this.clasessToggleCheck = false
      this.certificatesToggle = false
      this.certificatesToggleCheck = false
      this.uniqueCheck = false
      this.origin = ''
      this.variety = ''
      this.caliber_max = ''
      this.caliber_min = ''
      this.caliber_unit = ''
      this.clasess = ''
      this.certificates = ''
      this.origin_list = []
      this.caliber_max_list = []
      this.caliber_min_list = []
      this.caliber_unit_list = []
      this.class_list = []
      this.certificates_list = []
      this.objFilteredData = []
      this.obj = []
      this.objFilteredData = []
      this.varietyObj = []
      this.calMaxObj = []
      this.calMinObj = []
      this.calUnitObj = []
      this.classObj = []
      this.certificateObj = []
      this.unique = []
    },
    getCICustomerInfo () {
      var config
      config = {
        params: {
          // usage, // Default || Bakery
          contents: 'false',
          areaId: null,
          stationCode: this.selectedStore,
          isLoop: true
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getCustomerInfo.method,
          `${codes.requests.getCustomerInfo.url}`,
          config
        )
        .then(response => {
          console.log(response)
          this.CIData = response.data.customerInfoList
          for (let i = 0; i < response.data.customerInfoList.length; i++) {
            this.customerInfo.push(response.data.customerInfoList[i].description)
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    // onAssignClick () {
    //   if (this.templatetype === '5_85.SP1.ADD') {
    //     console.log('insideAsignnnnnnnnn', this.templatetype)
    //     if (this.leftNeighbourValue !== '' && this.rightNeighbourValue !== '') {
    //       EventBus.$emit(
    //         'messageAlert',
    //         this.$t('For the template 5_85.SP1.ADD only one neighbour is possible. Please delete one of the two neighbours')
    //       )
    //     } else { this.onAssign() }
    //   } else { this.onAssign() }
    // },
    onAssign () {
      // CI.PRD
      console.log(JSON.parse(JSON.stringify(this.assigneArticleListValue)))
      console.log(JSON.parse(JSON.stringify(this.assigneArticleCIPRDListValue)))
      console.log(JSON.parse(JSON.stringify(this.assigneArticlePRDListValue)))
      console.log(JSON.parse(JSON.stringify(this.assigneArticlePRDCIListValue)))
      console.log(JSON.parse(JSON.stringify(this.PRDCIAssigneArticleListValue)))
      console.log(JSON.parse(JSON.stringify(this.CIAssigneArticleListValue)))
      var PRDValue = ''
      if (this.assigneArticlePRDListValue.length > 0 || this.assigneArticleCIPRDListValue.length > 0) {
        this.assigneArticleListValue = []
        console.log('CIPRDCIPRDCIPRD')
        console.log(this.CIData)
        for (var e = 0; e < this.CIData.length; e++) {
          if (this.CIData[e].description === this.assigneArticleCIPRDListValue) {
            PRDValue = 'CI_' + this.CIData[e].id
          }
        }
        var value = ''
        value = this.assigneArticlePRDListValue.toString()
        // value.unshift(PRDValue)
        const val = [PRDValue, value]
        this.assigneArticleListValue = val.filter(e => e)
      }
      // PRD.CI
      if (this.assigneArticlePRDCIListValue.length > 0 || this.PRDCIAssigneArticleListValue.length > 0) {
        console.log('PRDCIPRDCIPRDCI')
        this.assigneArticleListValue = []
        var CIObj = ''
        for (var k = 0; k < this.CIData.length; k++) {
          if (this.CIData[k].description === this.PRDCIAssigneArticleListValue) {
            CIObj = 'CI_' + this.CIData[k].id
          }
        }
        let value2 = ''
        value2 = this.assigneArticlePRDCIListValue.toString()
        // value2.push(CIObj)
        const val2 = [value2, CIObj]
        this.assigneArticleListValue = val2.filter(e => e)
      }
      if (this.CIAssigneArticleListValue.length > 0) {
        console.log('CICICICICI')
        var newMyObj = []
        for (var i = 0; i < this.CIData.length; i++) {
          for (var j = 0; j < this.CIAssigneArticleListValue.length; j++) {
            if (this.CIData[i].description === this.CIAssigneArticleListValue[j]) {
              newMyObj.push('CI_' + this.CIData[i].id)
            }
          }
        }
        this.assigneArticleListValue = newMyObj
        console.log(this.assigneArticleListValue)
      }
      // ADD
      if (this.templatetype === '5_85.SP1.ADD') {
        console.log('insideAsignnnnnnnnn', this.templatetype)
        if (this.leftNeighbourValue !== '' && this.rightNeighbourValue !== '') {
          EventBus.$emit(
            'messageAlert',
            this.$t('For the template 5_85.SP1.ADD only one neighbour is possible. Please delete one of the two neighbours')
          )
          return
        }
      }
      if (this.leftNeighbourValue !== '' && this.rightNeighbourValue === '') {
        this.assigneArticleListValue = []
        this.assigneArticleListValue.push(this.productIdLeft)
        this.assigneArticleListValue.push('LIDL_LOGO')
        console.log('onassssssssssssssssssss', this.assigneArticleList.includes(this.leftNeighbourValue))
      }
      if (this.rightNeighbourValue !== '' && this.leftNeighbourValue === '') {
        this.assigneArticleListValue = []
        this.assigneArticleListValue.push('LIDL_LOGO')
        this.assigneArticleListValue.push(this.productIdRight)
      }
      if (this.leftNeighbourValue !== '' && this.rightNeighbourValue !== '') {
        this.assigneArticleListValue = []
        this.assigneArticleListValue.push(this.productIdLeft)
        this.assigneArticleListValue.push(this.productIdRight)
      }
      var data = {}
      if (this.leftNeighbourValue !== '' && this.rightNeighbourValue === '') {
        data = {
          assignList: [
            {
              addInfo2: this.leftNeighbourValue,
              addInfo4: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
              arrow: 'string',
              articleIdList: this.assigneArticleListValue,
              labelCode: this.labelId,
              templateName: this.templatetype
            }
          ]
        }
      } else if (this.rightNeighbourValue !== '' && this.leftNeighbourValue === '') {
        data = {
          assignList: [
            {
              addInfo3: this.rightNeighbourValue,
              addInfo4: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
              arrow: 'string',
              articleIdList: this.assigneArticleListValue,
              labelCode: this.labelId,
              templateName: this.templatetype
            }
          ]
        }
      } else if (this.leftNeighbourValue !== '' && this.rightNeighbourValue !== '') {
        data = {
          assignList: [
            {
              addInfo2: this.leftNeighbourValue,
              addInfo3: this.rightNeighbourValue,
              addInfo4: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
              arrow: 'string',
              articleIdList: this.assigneArticleListValue,
              labelCode: this.labelId,
              templateName: this.templatetype
            }
          ]
        }
      } else {
        data = {
          assignList: [
            {
              addInfo4: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
              arrow: 'string',
              articleIdList: this.assigneArticleListValue,
              labelCode: this.labelId,
              templateName: this.templatetype
            }
          ]
        }
      }
      // const data = {
      //   assignList: [
      //     {
      //       addInfo4: this.$store.getters['dataStore/GET_CURRENT_LANGUAGE'],
      //       arrow: 'string',
      //       articleIdList: this.assigneArticleListValue,
      //       labelCode: this.labelId,
      //       templateName: this.templatetype
      //     }
      //   ]
      // }
      // const url = `/labels/link/${this.selectedStore}`
      // axios.post(configs.ServerAddress + url, data)
      //   .then(response => console.log(response))
      EventBus.$emit('loadingOn')

      const url = '/api/common/labels/link'
      const config = { params: {} }
      config.params.store = this.selectedStore
      this.$utils.callAxiosWithBody('post', url, data, config)
        .then(res => {
          EventBus.$emit('loadingOff')
          console.log(res)
          if (res.status === 200) {
            EventBus.$emit('loadingOff')
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            setTimeout(this.closeModal, 500)
          } else {
            EventBus.$emit('loadingOff')
            EventBus.$emit('messageAlert', 'error')
          }
        })
        .catch((error) => {
          EventBus.$emit('loadingOff')
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
        })
    },
    //  LidL Requirements ends
    getScheduledPage (scheduleList) {
      const now = new Date()
      for (const schedule in scheduleList) {
        const Start = new Date(scheduleList[schedule].startTime)
        const End = new Date(scheduleList[schedule].endTime)
        if (now >= Start && now < End) {
          return scheduleList[schedule].displayPage
        }
      }
      return codes.ACTIVE_PAGE
    },
    async showScheduleList (scheduleList) {
      if (!commons.isValidStr(scheduleList)) {
        scheduleList = []
      }
      this.scheduleList = scheduleList
    },
    getScheduleList (labelId) {
      this.togetRepeatSchedullist(labelId)
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          label: labelId
        }
      }
      return this.$utils.callAxios(codes.requests.getScheduleList.method, codes.requests.getScheduleList.url, config)
        .then(res => {
          // state 값을 row에 추가
          return res.data.scheduleList.map((row) => {
            row.state = res.data.state
            return row
          })
        })
        .catch(error => {
          console.debug(`Selected label has no schduled page. error: ${error}`)
        })
    },
    togetRepeatSchedullist (id) {
      const params = { label: id, store: this.selectedStore }
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.getRepeatScheduleSettings.method,
          codes.requests.getRepeatScheduleSettings.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            this.repeatscheduleList = res.data.repeatScheduleList
            this.repeatscheduleList = this.repeatscheduleList.map(item => ({
              ...item,
              state: res.data.state
            }))
          } else {
            this.repeatscheduleList = []
          }
        })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
        })
    },
    resetLabelDetail () {
      this.currentImages = []
      this.labelStatus = ''
      this.processUpdateTime = ''
      this.statusUpdateTime = ''
      this.labelId = ''
      this.gateway = ''
      this.type = ''
      this.firmware = ''
      this.articleList = []
      this.assigneArticleList = []
      this.scheduleList = ''
      this.repeatscheduleList = ''
      this.inputImage = ''
      this.inputPage = 0
      this.encodedImage = ''
      this.origin = ''
      this.variety = ''
      this.caliber_max = ''
      this.caliber_min = ''
      this.caliber_unit = ''
      this.clasess = ''
      this.certificates = ''
      this.assigneArticleListValue = []
      this.assigneArticleCIPRDListValue = []
      this.assigneArticlePRDListValue = []
      this.assigneArticlePRDCIListValue = []
      this.PRDCIAssigneArticleListValue = []
      this.CIAssigneArticleListValue = []
      this.items = []
      this.labelItems = []
      this.labelCIItems = []
      this.duplicateData = []
      this.leftNeighbourValue = ''
      this.rightNeighbourValue = ''
      this.showTable = false
      this.showPRDTable = false
      this.toggleSelect = true
      this.isCIPRD = false
      this.templateTypeString = ''
      this.templateTypeCIString = ''
      this.templatetype = ''
      this.TempType = []
      this.$forceUpdate()
    },
    closeModal () {
      this.resetLabelDetail()
      this.$emit('updateLabelList')
      this.dialog_popupLabel = false
    },
    getCurrentImage (labelId) {
      Object.values(this.imagePageOption).forEach(x => {
        x.selected = false
        x.disabled = true
      })
      const url = '/api/common/labels/detail'
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          label: labelId
        }
      }
      this.$utils.callAxios('get', url, config).then((res) => {
        this.currentImages = res.data.currentImage
        res.data.currentImage.forEach(x => {
          this.imagePageOption[x.index].disabled = false
        })
        if (res.data.currentImage.length) {
          this.imagePageOption[res.data.currentImage.map(x => x.index)[0]].selected = true
          //  this.activePage = res.data.currentImage.map(x => x.index)[0]
          this.activePage = res.data.activePage
          this.activeimage = res.data.activePage
        }
        const scheduledPage = this.getScheduledPage(this.scheduleList)
        if (scheduledPage !== codes.ACTIVE_PAGE) {
          this.activePage = scheduledPage
        }
        this.clickImagePageBtn(this.activePage)
      })
    },
    getLabelInfo (labelId) {
      const url = '/api/common/labels'
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          label: labelId,
          isLabelAlive: configs.isLabelAlive
        }
      }
      this.$utils.callAxios('get', url, config).then((res) => {
        if (res.data) {
          console.log(res.data)
          this.labelId = res.data.labelList[0].labelCode
          this.status = res.data.labelList[0].updateStatus
          if (res.data.labelList[0].updateStatus === codes.labelStatus.UNASSIGNED) {
            this.btnAssignTxt = codes.assignType.assign
          } else {
            this.btnAssignTxt = codes.assignType.unassign
          }
          this.type = res.data.labelList[0].type
          this.getTemplates()
          if (res.data.labelList[0].gateway) {
            this.gateway = res.data.labelList[0].gateway.name
          } else this.gateway = null
          this.firmware = res.data.labelList[0].firmwareVersion
          this.articleList = res.data.labelList[0].articleList.map(element => element.articleId) || []
          this.templateManual = res.data.labelList[0].templateManual
          this.TempType = res.data.labelList[0].templateType
          this.$forceUpdate()
        }
      })
    },
    clickAssign () {
      // check for duplicate values
      const articleList = this.assigneArticleList.filter((el) => el !== '')
      if (new Set(articleList).size !== articleList.length) {
        EventBus.$emit('messageAlert', this.$t('Duplicate Product ID'))
      } else if (this.btnAssignTxt === codes.assignType.assign) {
        const url = '/api/common/labels/link'
        const config = {
          params: {
            company: this.$store.state.auth.user.company,
            store: this.selectedStore,
            validateStatus: false,
            responseType: 'application/json'
          }
        }
        const body = {
          assignList: [
            {
              articleIdList: this.assigneArticleList.filter((el) => el !== ''),
              labelCode: this.labelId
            }
          ]
        }
        this.$utils.callAxiosWithBody('post', url, body, config).then((res) => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            setTimeout(this.closeModal, 500)
          } else {
            EventBus.$emit('messageAlert', 'error')
          }
        }).catch((error) => {
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
        })
      } else if (this.btnAssignTxt === codes.assignType.unassign) {
        if (this.labelUnassignDisabled) return
        const url = '/api/common/labels/unlink'
        const config = {
          params: {
            company: this.$store.state.auth.user.company,
            store: this.selectedStore
          }
        }
        const body = {
          unAssignList: [this.labelId]
        }
        this.$utils.callAxiosWithBody('post', url, body, config)
          .then(res => {
            if (res.status === 200) {
              const msg = res.data.responseMessage.split(' ')
              const labelId = msg.pop()
              EventBus.$emit('messageAlert', `${this.$t(msg.join(' '))} ${labelId}`)
            } else {
              EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            }
            setTimeout(this.closeModal, 500)
          })
      }
    },
    // 이미지 인코딩
    encodeBase64ImageFile (image) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (event) => {
          resolve(event.target.result)
        }
        reader.onerror = (error) => {
          reject(error)
        }
      })
    },
    handleChange: function () {
      this.encodedImage = ''
      var file = this.inputImage

      if (file && file.type.match(/^image\/(png|jpeg|jpg|bmp)$/)) {
        this.encodeBase64ImageFile(file).then(data => {
          this.encodedImage = data.split(',').slice(1).join()
        })
      }
    },
    saveImage () {
      if (this.inputPage && this.encodedImage) {
        const url = '/api/common/labels/image'
        const config = {
          params: {
            company: this.$store.state.auth.user.company,
            store: this.selectedStore
          }
        }
        const body = {
          labelCode: this.labelId,
          page: this.inputPage,
          image: this.encodedImage
        }
        this.$utils.callAxiosWithBody('post', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t('Success.'))
          this.getCurrentImage(this.labelId)
          this.closeModal()
        }).catch((error) => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('Fail.'))
        })
      } else {
        if (!this.encodedImage) {
          EventBus.$emit('messageAlert', this.$t('Select the image to update'))
        } else if (!this.inputPage) {
          EventBus.$emit('messageAlert', this.$t('Select the page to update'))
        } else EventBus.$emit('messageAlert', this.$t('Error.'))
      }
    },
    clickAddArticle () {
      this.assigneArticleList.push('')
    },
    initData (labelId) {
      this.labelSourceId = labelId
      this.assigneArticleList = []
      this.inputPage = null
      this.inputImage = null
      this.encodedImage = ''
      this.accessLevel = this.$store.state.auth.user.accountInfo.accessLevel
      this.scheduleList = []
      this.repeatscheduleList = []
    },
    forwardBtn () {
      var current = Object.keys(this.imagePageOption).filter(x => this.imagePageOption[x].selected)[0]
      var selectable = Object.keys(this.imagePageOption).filter(x => !this.imagePageOption[x].disabled)
      var currentIndex = selectable.indexOf(current)
      if (currentIndex !== 0) {
        currentIndex -= 1
      }
      this.clickImagePageBtn(selectable[currentIndex])
    },
    backwardBtn () {
      var current = Object.keys(this.imagePageOption).filter(x => this.imagePageOption[x].selected)[0]
      var selectable = Object.keys(this.imagePageOption).filter(x => !this.imagePageOption[x].disabled)
      var currentIndex = selectable.indexOf(current)
      if (currentIndex !== (selectable.length - 1)) {
        currentIndex += 1
      }
      this.clickImagePageBtn(selectable[currentIndex])
    },
    clickImagePageBtn (value) {
      this.pageIndexValue = null
      this.pageIndexValue = parseInt(value)
      if (commons.isNull(this.imagePageOption[value])) return
      this.activePage = value * 1
      Object.keys(this.imagePageOption).forEach((x) => {
        this.imagePageOption[x].selected = false
      })
      this.imagePageOption[value].selected = true
    },

    // Image Rotation
    rotateImage (type, tabType) {
      if (tabType === 'current') {
        this.rotationScale = '1,1,1'
        if (type === 'right') {
          this.rotationCurrent += 90
          switch (this.rotationCurrent) {
            case 90:
              this.rotationScale = '1, 1, 1'
              break
            case 180:
              this.rotationScale = '1, 1, 1'
              break
            case 270:
              this.rotationScale = '1, 1, 1'
              break
            default:
              this.rotationScale = '1, 1, 1'
          }
        } else if (type === 'left') {
          this.rotationCurrent -= 90
          switch (this.rotationCurrent) {
            case -90:
              this.rotationScale = '1, 1, 1'
              break
            case -180:
              this.rotationScale = '1, 1, 1'
              break
            case -270:
              this.rotationScale = '1, 1, 1'
              break
            default:
              this.rotationScale = '1, 1, 1'
          }
        }
      } else if (tabType === 'previous') {
        this.rotationScale = '1,1,1'
        if (type === 'right') {
          this.rotationPrevious += 90
          switch (this.rotationPrevious) {
            case 90:
              this.rotationScale = '1, 1, 1'
              break
            case 180:
              this.rotationScale = '1, 1, 1'
              break
            case 270:
              this.rotationScale = '1, 1, 1'
              break
            default:
              this.rotationScale = '1, 1, 1'
          }
        } else if (type === 'left') {
          this.rotationPrevious -= 90
          switch (this.rotationPrevious) {
            case -90:
              this.rotationScale = '1, 1, 1'
              break
            case -180:
              this.rotationScale = '1, 1, 1'
              break
            case -270:
              this.rotationScale = '1, 1, 1'
              break
            default:
              this.rotationScale = '1, 1, 1'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.esl-detail {
  padding: 0;
}
.esl-detail .popup-titbox {
  padding: 40px 50px 0;
}
.esl-detail .box {
  padding: 20px 50px;
}
.esl-detail .box + .box {
  border-top: 1px solid #808495;
}
.esl-detail .preview {
  display:flex;
  justify-content: center;
  align-items: center;
  width:245px;
  height:200px;
  background:#fafafa;
  overflow: hidden;
}
.esl-detail .preview .img-label {
  border: 1px solid rgba(0, 0, 0, 0.38);
  max-width:100%;
  max-height:100%;
}
.esl-detail .tit-preview {
  font-size: var(--font14);
  color: rgba(0, 0, 0, 0.6);
}
.esl-detail .form-add-btn {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  overflow: hidden;
}
.esl-detail .form-add-btn .v-list {
  width: calc(100% - 70px);
  height: 120px;
  padding: 0;
  padding-right:10px;
}
.esl-detail .form-add-btn .v-list .v-list-item {
  min-height: auto;
  padding: 0 5px;
  height: 40px;
}
.esl-detail .form-add-btn .v-list .v-list-item:before {
  background-color: #001e38;
}
.esl-detail .form-add-btn .v-list .v-list-item + .v-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0.38);
}
.esl-detail .form-add-btn .v-list .v-list-item__content {
  padding: 0;
}
.esl-detail .form-add-btn .list_label {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: var(--font12);
  color: rgba(0, 0, 0, 0.6);
}
.esl-detail .tit-label {
  margin-bottom: 3px;
  font-size: var(--font14);
  line-height: 1.5;
  color:#001e38;
}
.esl-detail .form-add-btn .v-list .form-input{
  font-size:12px;
  border-bottom:0;
}
.esl-detail .lable-detail-box{display:flex; padding:0 50px;}
.esl-detail .lable-detail-box .infoBox+.infoBox{flex:1; margin-left:50px; padding-left:50px; border-left:2px solid #bfc1ca;}
.esl-detail .lable-detail-box .leftBox{width:380px;}
/* .esl-detail .lable-detail-box .rightBox{max-height:520px; padding-right:20px; overflow:hidden; overflow-y:auto;} */
.esl-detail .lable-detail-box .rightBox::-webkit-scrollbar {width: 5px;}
.esl-detail .lable-detail-box .rightBox::-webkit-scrollbar-track {background: transparent;}
.esl-detail .lable-detail-box .rightBox::-webkit-scrollbar-thumb {background: #818181; border-radius:5px;}
.esl-detail .lable-detail-box .rightBox::-webkit-scrollbar-thumb:hover {background: #555;}
.esl-detail .leftBox .img-box{display:flex; justify-content:center; align-items:center; width:100%; height:380px; margin:0 auto; background:#fafafa;position: relative;}
.esl-detail .leftBox .img-box img{max-width:100%; max-height:100%; border:1px solid rgba(0, 0, 0, 0.38);}
.esl-detail>>>.form-input.v-text-field--outlined .v-label{color:#001e38;}
.labelInfo{display:flex; margin:20px 0;}
.esl-detail .labelInfo>>>.form-input .v-input__slot{min-height:30px !important;}
.esl-detail .labelInfo>>>.form-input.form-status input{color:#e27070;}
.esl-detail .assignInfo{display:flex;}
.esl-detail .assignInfo .assignBox{width:100%;}
.esl-detail .assignInfo .btn-assign{width:90px; height:auto; font-size:var(--font12); background:#001e38; color:#fff;}
.esl-detail .schedule-info{width:100%; padding:0 15px 0 20px; border:1px solid rgba(0, 0, 0, 0.38); border-radius:5px; overflow:hidden;}
.esl-detail .schedule-info>>>.tbl-type01 thead th{height:35px !important; padding-left:0 !important; border-bottom:1px solid rgba(0, 0, 0, 0.12) !important; background:#fff; font-weight:400; font-size:var(--font16) !important;}
.esl-detail .schedule-info>>>.tbl-type01 tbody td{height:30px !important; padding-left:0 !important; background:#fff;}
.esl-detail>>>.v-btn.btn-update .v-btn__content{line-height:40px; font-size:var(--font14);}
.v-btn.btn-page{min-width:35px !important; height:35px !important; padding:0 !important; border:1px solid #e8e9ec; border-radius:3px; background:#fff !important;}
.v-btn.btn-page:before{background:transparent !important;}
.c-pagenation>>>.v-btn.v-btn--disabled.btn-page .v-btn__content{background:transparent !important;}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).btn-page{background:#fff !important;}
.v-btn.v-btn--active.btn-page{background:#001e38 !important; color:#fff;}
.btn-page-prev, .btn-page-next{position:relative; min-width:35px !important; height:35px !important; padding:0 !important; border:1px solid #e8e9ec; border-radius:3px; background:#fff !important;}
.btn-page-prev .v-btn__content, .btn-page-next .v-btn__content{position:absolute !important; top:0; left:0; width:100%; height:100%;}
.ico-prev, .ico-next{display:inline-flex;}
.ico-prev:before{content:"\F141"; display:inline-block; font:normal normal normal 24px/1 "Material Design Icons"; color:#8e91a0; font-size:var(--font24);}
.ico-next:before{content:"\F142"; display:inline-block; font:normal normal normal 24px/1 "Material Design Icons"; color:#8e91a0; font-size:var(--font24);}
.c-pagenation{display:flex; justify-content: space-between; align-items:center; margin-top:20px;}
.disabled { background: #ededf0 !important; pointer-events: none; border: 1px solid #a2a1af; }
/* LidL Requirements  */
::v-deep .esl-detail .form-add-btn .v-list[data-v-1138ff95] {
  width: 100% !important;
}

::v-deep .v-card__actions {
  display: grid;
  padding: 8px 50px !important;
}

::v-deep .v-select__selection--comma {
  margin: 7px;
}

.assign_info_add {
  height: 30px;
  background-color: #ECECEC;
  opacity: 1;
  border-radius: 5px;
  padding: 7px 10px;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  color: #707070;
  font-size: 10px;
}

.assign_info_add_delete {
  height: 30px;
  background-color: #ECECEC;
  opacity: 1;
  border-radius: 5px;
  align-items: center;
  width: 100%;
  padding: auto;
  color: #C42C2C;
  font-size: 10px;
}

.assign_info_card_details {
  font-size: 30px;
  font-weight: 500;
  padding: 10px;
}

.submit_card_button {
  font-size: var(--font17);
  border: 1px solid #7c7b8e;
  background: #001e38;
  color: #fff;
  border-radius: 5px;
  letter-spacing: -0.005em;
}

.assign_list {
  padding: 5px 22px;
  background-color: #0000000D;
  font-size: 16px;
  font-weight: 500;
  height: 30px;
}

::v-deep .theme--light.v-text-field--filled>.v-input__control>.v-input__slot {
  background-color: white !important;
}

::v-deep .v-list-item {
  padding: 0px 20px;
}

.assign_list>span {
  width: 40%;
  display: inline-block;
  position: relative;
}

.assign_info_add>span {
  width: 35%;
  display: inline-block;
  position: relative;
}

.assign_info_add>span::after {
  content: ':';
  position: absolute;
  right: 0;
}

.assign_list>span::after {
  content: ':';
  position: absolute;
  right: 0;
}

::v-deep .v-menu__content {
  border: 0px #ffff !important;
}
::v-deep  .v-label{
  font-size: 12px !important;
  line-height: 1.6 !important;
  color: #001e38 !important;
}
.tempclass{
  max-height: 100px !important;
  width: 370px;
  margin-top: 26px;
  margin-left: -47px;
  background: #ededf0 !important;
  color: #808495 !important;
  border-radius: 4px !important;
}
::v-deep .centered-input input {
  text-align: center !important;
  text-transform: capitalize !important;
  margin-left: 24px !important;
}
.rotate-button-wrapper {
  position:absolute;bottom:4px;right:4px;
}
::v-deep .rotate-button-wrapper .v-btn.v-btn--disabled .v-btn__content {
  background: transparent !important
}
::v-deep .rotate-button-wrapper .theme--light.v-btn.v-btn--disabled{
  background-color: #E7E7E7 !important;
}
</style>
<style>
.inputcssProperty .v-text-field.v-input--is-focused>.v-input__control>.v-input__slot:after {
  transform: scaleX(1);
  border: none;
}

.inputcssProperty .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border: transparent;

}

/* LidL Requirements ends */
</style>
