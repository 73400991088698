<template>
	<div>
		<h2 class="page-title-bar">
			<i class="ico ico-label"></i>{{ $t('Label Info') }}
		</h2>
		<!-- 검색창: Template 이름검색 -->
		<section class="searchArea">
			<v-text-field
				v-model="searchBy.labelParam"
				@keyup="handleSearchLabelIdKeyUp"
				:style="{ width: '10%', marginLeft: '0 !important' }"
				:label="$t('Label ID')"
				:placeholder="$t('Input label ID')"
				class="form-input ml-4"
				outlined
				dense
				hide-details
				clearable
			></v-text-field>
			<v-text-field
				v-model="searchBy.productIdParam"
				@keyup.enter="clickSearch()"
				:label="$t('Product ID')"
				:placeholder="$t('Input product ID')"
				class="form-input ml-3"
				style="width:10%"
				outlined
				dense
				hide-details
				clearable
			></v-text-field>
			<v-text-field
				v-model="searchBy.productDescParam"
				@keyup.enter="clickSearch()"
				:label="$t('Product Description')"
				:placeholder="$t('Input product description')"
				class="form-input ml-3"
				outlined
				style="width:10%"
				dense
				hide-details
				clearable
			></v-text-field>
			<v-select
				v-model="searchBy.gatewayParam"
				:items="gatewayItems"
				:label="$t('Linked Gateway')"
				:placeholder="$t('Select gateway')"
				class="form-select ml-3"
				clearable
				outlined
				dense
				persistent-hint
				hide-details
				style="width:10%"
			>
      <template v-slot:no-data>
          <div tabindex="-1" class="v-list-item theme--light">
            <div class="v-list-item__content">
              <div class="v-list-item__title">{{ $t('No data available') }}</div>
            </div>
          </div>
        </template>
    </v-select>
			<v-select
				v-model="searchBy.typeParam"
				:items="typeItems"
				:label="$t('Type')"
				:placeholder="$t('Select label type')"
				class="form-select ml-3"
				clearable
				item-value="value"
				item-text="text"
				outlined
				dense
				multiple
				persistent-hint
				hide-details
				chips
				style="width:10%"
				@change="onChangetype(searchBy.typeParam)"
				:class="{labelTypeclass:labelTypelist.length !==0}"
				:menu-props="{ maxHeight: '200' }"
			>
				<template
					v-slot:selection="{ item, index }"
					style="display: contents;"
				>
					<span
						class="item"
						v-if="index < 1"
					>{{ item.substring(0,15)+'...' }} </span>
					<span
						v-if="index === 1"
						class="grey--text caption"
            >(+{{ searchBy.typeParam.length - 1 }}) {{ $t('type') }}</span>
				</template>
			</v-select>

			<v-btn
				@click="clickSearch()"
				class="btn type-search ml-3"
				text
				style="width:120px"
			>{{ $t('Search') }}
			</v-btn>
		</section>
		<section
			class="searchArea"
			style="width:60%"
		>
			<v-select
				v-model="searchBy.statusParam"
				:items="statusItems"
				:label="$t('Status')"
				:placeholder="$t('Select label status')"
				item-value="value"
				item-text="text"
				class="form-select ml-3"
				outlined
				dense
				hide-details
				clearable
				multiple
				chips
				@change="onChange(searchBy.statusParam)"
				persistent-hint
				:class="{statuslistclass:statuslist.length !==0}"
				:style="{ marginLeft: '0 !important' }"
				style="width:10%;"
				:menu-props="{ maxHeight: '200' }"
			>
				<template
					v-slot:selection="{ item, index }"
					style="display: contents;"
				>
					<span
						class="item"
						v-if="index < maxDisplay"
					>{{ $t(item.value) }} </span>
					<span
						v-if="index === maxDisplay"
						class="grey--text caption"
          >(+{{ searchBy.statusParam.length - maxDisplay }} {{ $t('status') }})</span>
				</template>
			</v-select>

			<v-text-field
				v-model="searchBy.templateTypeParam"
				@keyup.enter="clickSearch()"
				:label="$t('Template Type Name')"
				:placeholder="$t('Input template type name')"
				class="form-input ml-3"
				outlined
				dense
				hide-details
				clearable
				style="width:10%"
			></v-text-field>
			<v-select
				v-if="(CUSTOMER ==='LCL') || (CUSTOMER ==='METRO')"
				v-model="searchParam.battery"
				:items="statusItemsBattery"
				:label="$t('Battery')"
				:placeholder="$t('Select Battery Level')"
				item-value="value"
				item-text="text"
				class="form-select ml-3"
				outlined
				dense
				hide-details
				clearable
				@change="clickCheckBox"
				style="width:10%"
			></v-select>
		</section>

		<!-- 결과 내 찾기 Checkboxs -->

		<!-- Label List -->
		<div
			class="col-12 pl-0 pb-0 pt-0 pr-0 d-flex"
			style="margin-top:10px"
		>
			<!-- <div class="col-12" style="display:flex"> -->

			<div class="col-4 pr-0">
				<v-data-table
					v-model="selected"
					:headers="FixedlabelHeader"
					:items="labelItems"
					:item-class="getRowClass"
					:hide-default-footer="true"
					item-key="id"
					show-select
					:options.sync="options"
					class="tbl-type01"
					@click:row="openLabelModal"
					:server-items-length="totalLabels"
				>
        <template slot="no-data">
						<p>
							{{ $t('No data available') }}
						</p>
				</template>
					<template v-slot:[`item.productdataId`]="{ item }">
						<p
							v-for="(items,index) in item.productdataId"
							:key="index"
						>
							<span v-if="items.articleId.length > 13">{{items.articleId.substring(0,4)+'...'+ items.articleId.substring(items.articleId.length - 3, items.articleId.length)}}</span>
							<span v-else-if="items.articleId.length==0">-</span>
							<span v-else>{{items.articleId}}</span>
						</p>
					</template>
				</v-data-table>
			</div>
			<div class="col-8 pl-0">
				<v-data-table
					v-model="selected"
					:headers="labelHeaders"
					:items="labelItems"
					:item-class="getRowClass"
					:hide-default-footer="true"
					item-key="id"
					:options.sync="options"
					class="tbl-type01 tblBorder"
					:server-items-length="totalLabels"
					@click:row="openLabelModal"
				>
					<template slot="no-data">
						<p>
							{{ $t('No data available') }}
						</p>
					</template>

					<template v-slot:[`item.productdataId`]="{ item }">
						<p
							v-for="(items,index) in item.productdataId"
							:key="index"
						>
							<span v-if="items.articleId.length > 13">{{items.articleId.substring(0,4)+'...'+ items.articleId.substring(items.articleId.length - 3, items.articleId.length)}}</span>
							<span v-else-if="items.articleId.length==0">-</span>
							<span v-else>{{items.articleId}}</span>
						</p>
					</template>
					<!-- <template v-slot:[`item.productdataName`]="{ item }">
    <p v-for="(items,index) in item.productdataName" :key="index">
      <span v-if="items.articleName!=null">
<span v-if="items.articleName.length > 12">{{items.articleName.substring(0,4)+'...'+ items.articleName.substring(items.articleName.length - 3, items.articleName.length)}}</span>
      <span v-else-if="items.articleName.length==0">-</span>
      <span v-else>{{items.articleName}}</span>
      </span>
      <span v-else>-</span>
      </p>
  </template> -->
					<template v-slot:[`item.productdataName`]="{ item }">
						<p
							v-for="(items, index) in item.productdataName"
							:key="index"
						>
							<!-- {{ items }} -->
							<v-tooltip
								v-if="items.articleName && items.articleName.length > 20"
								top
								content-class="v-tooltip-resize"
							>
								<template v-slot:activator="{ on, attrs }">
									<span
										v-bind="attrs"
										v-on="on"
										style="width:120px;display: block;overflow: hidden;text-overflow: ellipsis;"
									>
										{{ items.articleName }}
									</span>
								</template>
								<span>{{ items.articleName }}</span>
							</v-tooltip>
							<span v-else>{{
                items.articleName ? items.articleName : "-"
              }}</span>
							<!-- <span v-if="items.articleName.length == 0">-</span> -->
						</p>
					</template>
					<template v-slot:[`item.battery`]="{ item }">
						<p v-bind:class="batteryStyle(item.battery)">{{ $t(item.battery) }}</p>
					</template>
          <template v-slot:[`item.signal`]="{ item }">
						<p>{{ $t(item.signal) }}</p>
					</template>
					<template
						v-if="networklabel"
						v-slot:[`item.network`]="{ item }"
					>
						<p class="text-center">
							<i
								class="state-icon"
								v-bind:class="networkStatusStyle(item.network)"
							></i>
						</p>
					</template>
					<template v-else>
						<p class="text-center">
							{{ $t(item.network) }}
						</p>
					</template>
					<template v-slot:[`item.status`]="{ item }">
						<p v-bind:class="statusStyle(item.status)">{{ $t(item.status) }}</p>
					</template>
             <template v-slot:[`item.templateManual`]="{ item }">
						<span>{{$t(item.templateManual)}}</span>
					</template>
					<!-- //For LiDL -->
					<template v-slot:[`item.requestDate`]="{ item }">
						<span>{{item.requestDate}}</span>
					</template>
					<template v-slot:[`item.completedDate`]="{ item }">
						<span>{{item.completedDate}}</span>
					</template>
					<!-- //End -->
				</v-data-table>
			</div>

		</div>
		<div class="opt-result d-flex justify-end align-center">
			<p>{{ $t('In-Result Search') }}</p>
      <v-checkbox
				hide-details
				:label="`${$t('Schedule')}`"
				class="form-checkbox ml-4"
				v-model="searchParam.isSchedule"
				value="true"
				@change="clickCheckBox"
			></v-checkbox>
			<v-checkbox
				v-if="LabelAlivestatus"
				hide-details
				:label="`${$t('Offline')}`"
				class="form-checkbox ml-4"
				v-model="searchParam.network"
				value="false"
				@change="clickCheckBox"
			></v-checkbox>
			<v-checkbox
				v-if="(CUSTOMER !=='LCL') && (CUSTOMER !=='METRO')"
				hide-details
				:label="`${$t('Low Battery')}`"
				class="form-checkbox ml-4"
				v-model="searchParam.battery"
				value="BAD"
				@change="clickCheckBox"
			></v-checkbox>
			<v-checkbox
				hide-details
				:label="`${$t('Bad Signal')}`"
				class="form-checkbox ml-4"
				v-model="searchParam.signal"
				value="BAD"
				@change="clickCheckBox"
			></v-checkbox>
			<!-- MisusedLabels -->
			<v-checkbox
				hide-details
				:label="`${$t('Misused')}`"
				class="form-checkbox ml-4"
				v-model="searchParam.misusedLabelType"
				value="All"
				@change="clickCheckBox"
			></v-checkbox>
		</div>
		<!-- Label detail popup -->
		<popupLabel
			:item="detailItem"
			:labelDetailDisabled="btnDisabledDetail"
			@updateLabelList="searchLabelList"
		></popupLabel>
		<!-- Button group -->
		<div class="table-options">
			<div>
				<v-btn
					v-if="CUSTOMER==='ROGERS'"
					@click="openassignPopup"
					text
					class="btn"
				>{{ $t('Assign') }}</v-btn>
				<v-btn
					@click="unassignLabels"
					:disabled="btnDisabledDelete || checkSelected"
					text
					class="btn ml-2"
				>{{ $t('Unassign') }}</v-btn>
				<BlinkPopup
					:selectedLabel="selectedLabel"
          @fireResetLabel="resetLabel"
					:btnDisabledBlinking="btnDisabledBlinking || checkSelected"
				/>
				<v-btn
					@click="exportLabelList"
					:disabled="btnDisabledExport || btnDisabledExportCheckList"
					text
					class="btn ml-2"
				>{{ $t('Export') }}</v-btn>
				<v-btn
					@click="deleteLabels"
					:disabled="btnDisabledDelete || checkSelected"
					text
					class="btn  ml-2"
				>{{ $t('Delete') }}</v-btn>
				<v-btn
					@click="OnDemandAlive"
					:disabled=" checkSelected || btnDisabledBlinking"
					text
					class="btn  ml-2"
				>{{ $t('On-Demand Alive') }}</v-btn>
				<v-btn
					v-if="CUSTOMER==='LCL'"
					@click="ResendLabelsAssign"
					:disabled="checkSelected || btnDisabledBlinking"
					text
					class="btn  ml-2"
				>{{ $t('Resend') }}</v-btn>
			</div>
			<!-- <br> -->
      <br>
     <br>
			<div style="float:left !important;display:flex">
				<v-btn
					v-if="CUSTOMER==='LCL'"
					@click="toresendAllLabels"
					:disabled="btnDisabledBlinking"
					text
					class="btn  ml-2"
				>{{ $t('Resend All') }}</v-btn>
				<v-btn
					v-if="CUSTOMER==='LCL'"
					@click="toblinkLedAllLabels"
					:disabled="btnDisabledBlinking "
					text
					class="btn  ml-2"
				>{{ $t('Blink LED All') }}</v-btn>
				<v-btn
					v-if="CUSTOMER==='LCL'"
					@click="PageFlipAll"
					:disabled="btnDisabledBlinking"
					text
          style="width: 170px !important;"
					class="btn  ml-2"
				>{{ $t('Page Flip All') }}</v-btn>
        <LabelSchedule @fireResetLabel="resetLabel" openScheduleModel1="openScheduleModel1" :selectedLabel="selectedLabel" :PageListarray="PageListarray">
      </LabelSchedule>
			</div>
			<div>
				<v-dialog
					v-model="messageDialog"
					width="300"
				>
					<v-card
						class="popup add_store_popup"
						align="center"
						justify="center"
					>
						<v-row>
							<v-col>
								<span v-text="messageText"></span>
							</v-col>
						</v-row>
						<v-card-actions class="d-flex justify-center">
							<v-btn
								text
								icon
								@click=";(messageDialog = false), searchLabelList()"
								class="btn"
                >{{$t('OK')}}</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>

			<!-- <div>
        <div class="pageInfo">{{ pageInfoText }}</div>
        <br>
        <v-pagination
          v-model="pageIndex"
          :length="pageCount"
          :total-visible="7"
          color="#2f3b4c"
          @input="paging"
          class="pageAlign"
        ></v-pagination>
      </div> -->

		</div>
		<div
			class="col-12 pr-0"
			style="padding:0px"
		>
			<Pagination
				@paging="paging($event)"
				:pageInfoText="pageInfoText"
				:pageIndex="pageIndex"
				:rowPerPage="rowPerPage"
				:goToPageInput="goToPageInput"
				:totalPages="totalPages"
				@updatePage="updatePage($event)"
			></Pagination>
		</div>
		<a
			ref="link"
			:style="{ display: 'none' }"
		/>
		<!-- Assignpopup -->
		<v-dialog
			v-model="assignpopup"
			persistent
			scrollable
			width="500"
		>
			<v-card class="popup add_store_popup">
				<v-card-title>
					<h3 class="page-title-bar mb-2">
						<i
							class="ico ico-label"
							style="margin-right: 13px;"
						>
						</i>{{ $t('Assign') }}
					</h3>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col>
							<p class="txt-s mb-2">MarketLandscape ID <span style="color:red">*</span></p>
							<v-text-field
								outlined
								dense
								hide-details
								prepend-icon
								solo
								flat
								disabled
								persistent-hint
								:placeholder="`${$t('Input MarketLanscape ID')}`"
								class="form-file"
								v-model="selectedStore"
							>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<p class="txt-s mb-1">
								{{ $t("Product Type") }}
							</p>
							<v-radio-group
								row
								v-model="productType"
								@change="producttype($event)"
							>
								<v-radio
									label="Accessory"
									value="Accessory"
								></v-radio>
								<v-radio
									label="Device"
									value="Device"
								></v-radio>
								<v-radio
									label="Name Tag"
									value="Name"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
					<v-row>
						<v-col style="margin-top: -23px">
							<p class="txt-s mb-1">
								{{ $t("Product") }} <span style="color:red">*</span>
							</p>
							<v-row>
								<v-col cols="5">
									<v-select
										:items="product"
										:placeholder="$t('Product ID')"
										class="form-select custom-select"
										outlined
										dense
										filled
										v-model="productdataselect"
										:disabled="!producttypechange"
										persistent-hint
										hide-details
										style="padding-top: 0px"
									></v-select>
								</v-col>
								<v-col style="padding-top: 11px;padding-left:00px">
									<v-autocomplete
										v-if="productdataselect ==='Product ID'"
										:items="productlist"
										outlined
										dense
										item-text="articleId"
										item-value="articleId"
										placeholder="Input"
										:disabled="!producttypechange"
										v-model="productValue"
                    style="font-size: 14px;"
										return-object
									>
										<template v-slot:item="data">
											<span style="font-size:14px">{{ data.item.articleId }} - {{ data.item.articleName }}</span>
										</template>
									</v-autocomplete>
									<v-autocomplete
										v-if="productdataselect !=='Product ID'"
										:items="productlist"
										outlined
										dense
										item-text="articleName"
										item-value="articleName"
										placeholder="Input"
										:disabled="!producttypechange"
										v-model="productValue"
										return-object
									>
										<template v-slot:item="data">
											<span style="font-size:14px">{{ data.item.articleId }} - {{ data.item.articleName }}</span>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row style="margin-top: -30px;">
						<v-col>
							<p class="txt-s mb-2">{{ $t("Label Code") }} <span style="color:red">*</span></p>
							<v-text-field
								outlined
								dense
								hide-details
								prepend-icon
								solo
								flat
								persistent-hint
								:placeholder="`${$t('Input Label Code')}`"
								class="form-file"
								v-model="labelCode"
							>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row class="ma-0 pa-0">
						<v-col class="ma-0 pa-0">
							<v-checkbox
								:disabled="labelCode.length ===0"
								@change="gettemplatesbylabel();changeflag($event)"
								:label="`${$t('Template Types by Label')}`"
								class="ma-0 pa-0"
								v-model="templatetypebylabel"
								hide-details
							></v-checkbox>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<p class="txt-s mb-2">Template </p>
							<v-select
								v-if="templatetypebylabel"
								outlined
								dense
								:items="templatelist"
								item-text="templateName"
								hide-details
								prepend-icon
								solo
								flat
								persistent-hint
								:placeholder="`${$t('Select Template')}`"
								class="form-file"
								v-model="templatelistvalue"
								return-object
							>
								<template v-slot:item="data">
									<span style="font-size:14px">{{ data.item.templateName }} ({{ data.item.width }} X {{ data.item.height }})</span>
								</template>
							</v-select>
							<v-select
								v-if="!templatetypebylabel"
								outlined
								dense
								:items="templatelist"
								item-text="typeName"
								hide-details
								prepend-icon
								solo
								flat
								persistent-hint
								:placeholder="`${$t('Select Template')}`"
								class="form-file"
								v-model="templatelistvalue"
								return-object
							>

								<template v-slot:item="data">
									<span style="font-size:14px">{{ data.item.typeName }} - {{ data.item.templateSize }}</span>
								</template>
							</v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="d-flex justify-center">
					<v-btn
						text
						icon
						@click="ToassignLabel()"
						class="btn"
					>{{$t('Save')}}</v-btn>
					<v-btn
						text
						icon
						@click="assignpopup = false"
						class="btn"
					>{{$t('Cancel')}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- //LCL Blink LED ALL -->
		<v-dialog
			v-model="dialogBlinkLED"
			scrollable
			persistent
			width="430"
		>
			<v-card class="popup">
				<v-card-title>
					<h3 class="page-title-bar">
						<i class="ico ico-label"></i>{{ $t('BlinkLED All') }}
					</h3>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row style="padding-top:10px">
						<v-col class="pt-0">
							<v-select
								outlined
								dense
								hide-details
								v-model="pattern"
								:label="$t('Pattern')"
								:items="patternItems"
								item-text="name"
								item-value="id"
								class="form-select"
								:disabled="selectBoxEnable"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-select
								outlined
								dense
								hide-details
								v-model="duration"
								:label="$t('Duration')"
								:placeholder="durationItems[0]"
								:items="durationItems"
								class="form-select"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-select
								outlined
								dense
								hide-details
								v-model="color"
								:label="$t(Label_Color)"
								:placeholder="colorItems[0]"
								:items="colorItems"
								class="form-select"
							>
              <template v-slot:item="{item }">
                 <span style="font-size: 0.8125rem;">{{ $t(item) }}</span>
              </template>
              <template v-slot:selection="{item }">
                  <span class="LedPatternClass" style="display: contents !important;">{{ $t(item) }}</span>
              </template>
            </v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="d-flex justify-center">
					<v-btn
						text
						icon
						@click="handleSaveBtnClick"
						class="btn"
					>{{
          $t('Save')
        }}</v-btn>
					<v-btn
						text
						icon
						@click="dialogBlinkLED = false"
						class="btn"
					>{{
          $t('Cancel')
        }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- //LCL Page Flip  ALL -->
		<v-dialog
			v-model="pageFlipDialog"
			scrollable
			persistent
			width="400"
		>
			<v-card class="popup">
				<v-card-title>
					<h3 class="page-title-bar">
						<i class="ico ico-label"></i>{{ $t('PageFlip All') }}
					</h3>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row style="padding-top:10px">
						<v-col class="pt-0">
							<v-select
								outlined
								dense
								hide-details
								v-model="PageValue"
								:label="$t('Page')"
								:items="PageListarray"
								item-text="name"
								item-value="id"
								class="form-select"
							></v-select>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="d-flex justify-center">
					<v-btn
						text
						icon
						@click="PageFlipAllSaveBtn"
						class="btn"
					>{{
          $t('Save')
        }}</v-btn>
					<v-btn
						text
						icon
						@click="pageFlipDialog = false"
						class="btn"
					>{{
          $t('Cancel')
        }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

     <!-- //Message dialog for Rogers Brand selection -->
     <v-dialog v-model="MessageBoardpopup" scrollable
       persistent width="500">
      <v-card class="popup add_store_popup messageClass">
      <span>You are assigning the wrong ESL tag model to the current store brand.
        Please check the ESL tag model.</span>
         <span>Rogers can only use RED ESL tag model.</span>
         <span>Fido can only use YELLOW ESL tag model</span>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="MessageBoardpopup = false" class="btn">{{$t('OK')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End -->

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
// Utility
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import { exportFiles } from '@/plugins/exporter'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'
import codes from '@/plugins/codes'
// Components
import popupLabel from './modal/LabelPopupLabel'
import BlinkPopup from './modal/LabelBlinkPopup'
import LabelSchedule from './modal/LabelSchedule.vue'
import configs from '@/plugins/configs'
// Mixins
import Pagenation from '@/mixins/Pagenation'
import Pagination from '@/components/pagination/Pagination.vue'
// import Pagenation from '@/mixins/Pagenation'
import '@/assets/css/freeze.css'

const requests = {
  unassignLabel: {
    method: 'post',
    url: '/api/common/labels/unlink'
  },
  getvaluebyproducttype: {
    method: 'get',
    // url: '/portal/articles/article/content'
    url: '/api/common/article/content'
  },
  getTemplatestype: {
    method: 'get',
    url: '/api/common/templates/type'
  },
  getTemplatestypebylabel: {
    method: 'get',
    url: '/api/common/labels/type/one'
  },
  gettemplatesbylabeltype: {
    method: 'get',
    url: '/api/common/templates'
  },
  toassignlabels: {
    method: 'post',
    url: '/api/common/labels/link'
  },

  // LCL API's

  // Resend All
  toresendAllLabels: {
    method: 'post',
    url: '/api/common/labels/multi/link'
  },

  // Blink LED All
  getLabelLedData: {
    method: 'get',
    url: '/api/common/functions/led/pattern'
  },
  toblinkLedAllLabels: {
    method: 'post',
    url: '/api/common/labels/multi/led'
  },

  // Page Flip All
  togetPageFlipSize: {
    method: 'get',
    url: '/api/common/property'
  },
  // Save Page Flip settings
  tosavepageFlipSettings: {
    method: 'post',
    url: '/api/common/labels/multi/page'
  }
  // End LCD APIs

}

export default {
  name: 'LabelInfo',
  mixins: [Pagenation],
  components: {
    popupLabel,
    BlinkPopup,
    Pagination,
    LabelSchedule
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      productValue: '',
      labelCode: '',
      templatetypebylabel: false,
      assignpopup: false,
      product: ['Product ID', 'Product Description'],
      productType: '',
      producttypechange: false,
      productlist: [],
      productdataselect: 'Product ID',
      templatelist: [],
      templatelistvalue: '',
      selected: [],
      totalLabels: null,
      page: 0,
      rowPerPage: '10',
      pageIndex: 1,
      pageCount: 0,
      totalPages: 0,
      goToPageInput: 1,

      pageInfoText: null,
      requestConfig: {},
      networklabel: Boolean,
      LabelAlivestatus: Boolean,
      searchParam: {
        company: null,
        store: null,
        label: null,
        templateType: null,
        network: null,
        battery: null,
        signal: null,
        page: null,
        size: '10',
        sort: 'code,asc',
        ll: '',
        misusedLabelType: null, // API 기능 동작 확인후 수정 필요,
        isSchedule: null
      },
      options: {},
      // dialog : popup
      dialog1: false,
      dialog_popupLabel: false,
      dialogLabelBlinkPopup: false,
      labelItems: [],
      typeItems: [],
      gatewayItems: [],
      detailItem: {},

      // 조회 조건
      searchBy: {
        labelParam: null,
        gatewayParam: null,
        statusParam: null,
        typeParam: null,
        templateTypeParam: null,
        productIdParam: null,
        productDescParam: null
      },

      // 버튼 권한
      btnDisabledDelete: null,
      btnDisabledBlinking: null,
      btnDisabledExport: null,
      btnDisabledDetail: null,

      ROW_CLASS: 'row-class',
      messageDialog: false,
      messageText: null,
      CUSTOMER: configs.Customer,
      InitialData: '',
      statuslist: [],
      labelTypelist: [],
      maxDisplay: 2,

      // LCL BlinkLED All
      Label_Color: this.$t('Color'),
      dialogBlinkLED: false,
      ledPattern: null,
      patternItems: [],
      durationItems: ['0s', '10s', '30s', '1m', '2m', '5m'],
      colorItems: [
        'RED',
        'GREEN',
        'BLUE',
        'MAGENTA',
        'YELLOW',
        'CYAN',
        'WHITE'
      ],
      pattern: null,
      duration: '0s',
      color: 'RED',
      selectBoxEnable: false,

      // LCL  Page FLip ALL
      pageFlipDialog: false,
      PageListarray: [],
      PageValue: 1,
      // Rogers
      labeltypeflag: false,
      rogersBrand: '',
      MessageBoardpopup: false,
      messagetextdata: '',
      openScheduleModel1: false

    }
  },
  computed: {
    FixedlabelHeader () {
      const header = [
        {
          text: this.$t('LABEL ID'),
          sortable: true,
          value: 'id',
          width: '108px'
        },
        {
          text: this.$t('PRODUCT ID'),
          sortable: false,
          value: 'productdataId',
          width: '120px'
        }

      ]
      return header
    },
    labelHeaders () {
      const header = [

        // {
        //   text: this.$t('PRODUCT ID'),
        //   sortable: false,
        //   value: 'productdataId',
        //   width: '10%'
        // },
        {
          text: this.$t('PRODUCT DESCRIPTION'),
          sortable: false,
          value: 'productdataName',
          width: '17%'
        },
        {
          text: this.$t('LINKED GATEWAY'),
          sortable: false,
          value: 'gateway',
          width: '10%'
        }, // 09.27 텍스트 수정
        {
          text: this.$t('BATTERY'),
          sortable: false,
          value: 'battery',
          width: '10%'
        },
        {
          text: this.$t('SIGNAL STRENGTH'),
          sortable: false,
          value: 'signal',
          width: '10%'
        },
        { text: this.$t('TYPE'), sortable: true, value: 'type', width: '5%' },
        { text: this.$t('TEMPLATE'), sortable: false, value: 'templateType', width: '5%' },
        { text: this.$t('TEMPLATE MANUAL'), sortable: false, value: 'templateManual', width: '5%' },

        {
          text: this.$t('NETWORK'),
          sortable: false,
          value: 'network',
          align: 'center',
          width: '5%'
        },
        {
          text: this.$t('STATUS'),
          sortable: false,
          value: 'status',
          width: '10%'
        },
        { text: this.$t('LATEST RESPONSE DATE'), sortable: true, value: 'time' } // 09.24 텍스트 수정
      ]
      // For LiDL
      if (configs.Customer === 'LIDL') {
        header.push({ text: this.$t('REQUESTED DATE'), sortable: false, value: 'requestDate' })
        header.push({ text: this.$t('COMPLETED DATE'), sortable: false, value: 'completedDate' })
      }
      // END
      return header
    },
    statusItems () {
      return [
        {
          value: 'SUCCESS',
          text: this.$t('SUCCESS')
        },
        {
          value: 'PROCESSING',
          text: this.$t('PROCESSING')
        },
        {
          value: 'TIMEOUT',
          text: this.$t('TIMEOUT')
        },
        {
          value: 'UNASSIGNED',
          text: this.$t('UNASSIGNED')
        }
      ]
    },
    statusItemsBattery () {
      return [
        {
          value: 'VERYGOOD',
          text: this.$t('Very good')
        },
        {
          value: 'GOOD',
          text: this.$t('Good')
        },
        {
          value: 'LOW',
          text: this.$t('Low')
        },
        {
          value: 'REPLACE',
          text: this.$t('Replace')
        }
      ]
    },
    selectedLabel () {
      return this.selected.map(element => element.id)
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    btnDisabledExportCheckList () {
      var flag = true
      if (this.labelItems.length) flag = false
      return flag
    },
    checkSelected () {
      var flag = true
      if (this.selected.length) flag = false
      return flag
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' })
  },
  watch: {
    options (val) {
      const columnInfo = {
        id: 'code',
        type: 'type',
        time: 'lastResponseTime',
        requestDate: 'processUpdateTime',
        completedDate: 'statusUpdateTime'
      }
      let sortParam = ''
      if (val.sortBy.length && val.sortDesc[0]) {
        sortParam = columnInfo[val.sortBy[0]] + ',DESC'
      } else if (val.sortBy.length && !val.sortDesc[0]) {
        sortParam = columnInfo[val.sortBy[0]] + ',ASC'
      }
      if (!commons.isNull(sortParam)) this.searchParam.sort = sortParam
      // this.page = 0
      this.searchLabelList(this.page)
    },
    selectedStore () {
      // Rogers
      if (this.CUSTOMER === 'ROGERS') {
        this.tocheckRogersBrand(this.store.code)
      }
      // END
      // 공통 Store 선택시 초기화 하지 않음.
      this.page = 0
      this.getTypeItems()
      this.searchLabelList()
      this.getGatewayList()
      EventBus.$emit('templatetype')
    },
    searchBy: {
      handler (newSearchBy) {
        const labelInfo = {
          searchBy: newSearchBy
        }
        setTempObj('labelInfo', labelInfo)
      },
      deep: true
    },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  mounted () {
    this.clearTabindex()
    this.LabelAlivestatus = configs.isLabelAlive
    console.log(this.LabelAlivestatus)
    // button 권한 체크
    this.$store.dispatch('auth/getDisabledBtn', '2100').then(flag => {
      this.btnDisabledDelete = flag
    })
    this.$store.dispatch('auth/getDisabledBtn', '2101').then(flag => {
      this.btnDisabledBlinking = flag
    })
    this.$store.dispatch('auth/getDisabledBtn', '2102').then(flag => {
      this.btnDisabledExport = flag
    })
    this.$store.dispatch('auth/getDisabledBtn', '2103').then(flag => {
      this.btnDisabledDetail = flag
    })

    // Overview 에서 redirect된 경우, 검색조건을 다시 설정.
    if (commons.notEmpty(this.$route.params)) {
      this.resetSearchBy()
      this.setRouterSearchCondition(this.$route)
    } else {
      // 이전 검색조건 설정.
      this.initData()
      this.setLastSearchCondition()
    }
    this.searchParam.company = this.$store.state.auth.user.company
    EventBus.$emit('enableSelectedStores', true)

    if (this.store === undefined || !this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.clickSearch()
      this.getTypeItems()
      this.getGatewayList()
    }
    if (this.searchBy.statusParam) {
      this.statuslist = this.searchBy.statusParam
    }
    this.togetPropert()
  },
  methods: {
    initData () {
      this.searchParam = {
        company: null,
        store: null,
        label: null,
        templateType: null,
        network: null,
        battery: null,
        signal: null,
        page: null,
        size: '10',
        sort: 'code,asc',
        misusedLabelType: null,
        articleId: null,
        articleName: null
      }
      this.page = 0
      this.goToPageInput = 1

      this.searchBy = {
        labelParam: null,
        gatewayParam: null,
        statusParam: null,
        typeParam: null,
        templateTypeParam: null,
        productIdParam: null,
        productDescParam: null
      }
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    openLabelModal (item) {
      if (this.btnDisabledDetail) return
      this.detailItem = item
      EventBus.$emit('getLabelDetailPopupDataValues', item)
      EventBus.$emit('openLabelDetail')
    },
    handleSearchLabelIdKeyUp (e) {
      this.labelParam = e.target.value.replaceAll(' ', '')
      if (e.code === 'Enter') this.clickSearch()
    },
    clickSearch () {
      this.page = 0
      this.searchParam.label = this.searchBy.labelParam
      if (this.searchBy.gatewayParam) {
        this.searchParam.gateway = this.searchBy.gatewayParam
      } else delete this.searchParam.gateway
      // if (this.searchBy.typeParam) {
      //   this.searchParam.type = this.searchBy.typeParam
      // } else delete this.searchParam.type
      if (this.searchBy.typeParam) {
        if (this.searchBy.typeParam.length === 0) {
          this.searchParam.type = null
        } else {
          this.searchParam.type = this.searchBy.typeParam
        }
      } else delete this.searchParam.type

      if (this.searchBy.statusParam) {
        console.log(this.searchBy.statusParam.length)
        if (this.searchBy.statusParam.length === 0) {
          this.searchParam.status = null
        } else {
          this.searchParam.status = this.searchBy.statusParam
        }
      } else delete this.searchParam.status
      this.searchParam.templateType = this.searchBy.templateTypeParam
      this.searchParam.articleId = this.searchBy.productIdParam
      this.searchParam.articleName = this.searchBy.productDescParam
      this.searchLabelList()
    },
    searchLabelList () {
      if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
        EventBus.$emit('openSelectedStores')
        return
      }
      const searchParam = this.searchParam
      for (const key of Object.keys(searchParam)) {
        if (commons.isNull(searchParam[key])) delete searchParam[key]
      }
      const url = '/api/common/labels'
      const config = { params: this.searchParam }
      config.params.store = this.$store.state.dataStore.selectedStore.code
      config.params.page = this.page
      config.params.size = this.rowPerPage

      var active = configs.isLabelAlive
      console.log(active)
      config.params.isLabelAlive = configs.isLabelAlive
      if (this.productionType === 'cloud') {
        config.params.company = this.$store.state.auth.user.company
      }

      this.$utils.callAxios('get', url, config).then(res => {
        this.saveRequestConfig(res.config)
        const headers = res.headers
        if (res.data) {
          this.InitialData = res.data.labelList
          this.totalLabels = res.data.labelList.length

          this.labelItems = res.data.labelList.map(resData => {
            const obj = {}
            const filteredArticleList = Array.from(new Set(resData.articleList.map(JSON.stringify))).map(JSON.parse)
            obj.id = resData.labelCode
            obj.articleName = ''
            if (resData.articleList.length === 0) {
              obj.productdataId = [{ articleId: '', articleName: '' }]
            } else {
              obj.productdataId = filteredArticleList
            }
            if (resData.articleList.length === 0) {
              obj.productdataName = [{ articleId: '', articleName: '' }]
            } else {
              obj.productdataName = filteredArticleList
            }

            if (resData.gateway) {
              obj.gateway = resData.gateway.name
            } else {
              obj.gateway = '-'
            }
            obj.battery = resData.battery
            obj.signal = resData.signal
            obj.type = resData.type // 수정필요
            obj.network = resData.networkStatus
            obj.status = resData.updateStatus
            if (resData.lastResponseTime) {
              obj.time = commons.convertDate(resData.lastResponseTime)
            } else obj.time = '-'
            if (resData.requestDate) {
              obj.requestDate = commons.convertDate(resData.requestDate)
            } else obj.requestDate = '-'
            if (resData.completedDate) {
              obj.completedDate = commons.convertDate(resData.completedDate)
            } else obj.completedDate = '-'
            if (resData.networkStatus === null) {
              this.networklabel = false
              obj.network = 'N/A'
            } else {
              this.networklabel = true
            }
            if (resData.templateType) {
              obj.templateType = resData.templateType[0]
            } else obj.templateType = '-'
            if (resData.templateManual !== '') {
              obj.templateManual = resData.templateManual.toString()
            } else obj.templateManual = '-'
            return obj
          })
        } else this.labelItems = []
        if (headers['x-total-count'] === '0') {
          this.pageInfoText = '0 ' + ' ' + this.$t('in total')
          this.pageIndex = 1
          this.pageCount = 1
          this.totalPages = 1
        } else {
          this.pageInfoText =
            headers['x-number'] * 1 * headers['x-size'] +
            1 + ' ' +
            this.$t('to') + ' ' +
            (headers['x-number'] * 1 * headers['x-size'] +
              this.labelItems.length) +
            ', ' +
            headers['x-totalelements'] * 1 +
            this.$t('in total')

          this.pageIndex = headers['x-number'] * 1 + 1
          this.pageCount = headers['x-totalpages'] * 1
          this.totalPages = headers['x-totalpages'] * 1
        }
      })
    },
    networkStatusStyle (networkStatus) {
      if (networkStatus === true) return 'bg-stable'
      else if (networkStatus === false) return 'bg-danger'
      else return null
    },
    batteryStyle (battery) {
      if (configs.Customer === 'LCL' || configs.Customer === 'METRO') {
        if (battery === 'VERYGOOD') return 'verygoodBattery'
        else if (battery === 'GOOD') return 'goodBattery'
        else if (battery === 'LOW') return 'LowBattery'
        else if (battery === 'REPLACE') return 'replaceBattery'
        else if (battery === 'BAD') return 'txt-danger'
        else return 'txt-caution' // NONE 인경우
      } else {
        if (battery === 'GOOD') return 'txt-stable'
        else if (battery === 'BAD') return 'txt-danger'
        else return 'txt-caution' // NONE 인경우
      }
    },
    statusStyle (status) {
      if (status === 'TIMEOUT') return 'txt-danger'
      else return null
    },
    getTypeItems () {
      const store = this.selectedStore
      const url = '/api/common/labels/type'
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: store
        }
      }
      this.$utils.callAxios('get', url, config).then(res => {
        this.typeItems = res.data.labelTypeList
      })
        .catch(error => {
          console.debug(`Failed to load type. error: ${error}`)
        })
  	  },
    getGatewayList () {
      const store = this.selectedStore
      const url = '/api/common/gateway/list'
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: store
        }
      }
      this.$utils.callAxios('get', url, config).then(res => {
        this.gatewayItems = res.data.gatewayList
      })
        .catch(error => {
          console.debug(`Failed to load list. error: ${error}`)
        })
    },
    unassignLabels () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore
        }
      }
      const body = {
        unAssignList: this.selected.map(labelData => labelData.id)
      }
      this.$utils
        .callAxiosWithBody(
          requests.unassignLabel.method,
          requests.unassignLabel.url,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) {
            const msg = res.data.responseMessage.split(' ')
            const labelId = msg.pop()
            this.messageText = `${this.$t(msg.join(' '))} ${labelId}`
            this.messageDialog = true
            this.paging(1)
            this.$forceUpdate()
          }
        })
        .catch(error => {
          console.debug(`Failed to unassign labels. error: ${error}`)
          this.messageText = this.$t('Unassigning failed.')
          this.messageDialog = true
        })
    },

    // Delete Label
    deleteLabels () {
      const url = '/api/common/labels'
      const config = {
        params: {},
        data: {
          labelList: this.selected.map(labelData => labelData.id)
        }
      }
      this.$utils
        .callAxios('delete', url, config)
        .then(res => {
          if (res.status === 200) {
            this.messageText = this.$t(res.data.responseMessage)
            this.messageDialog = true
          }
        })
        .catch(error => {
          console.debug(`Failed to delete labels. error: ${error}`)
          this.messageText = this.$t('Delete failed.')
          this.messageDialog = true
        })
    },

    // On Demand Alive
    OnDemandAlive () {
      const url = '/api/common/labels/onDemandAlive/execute'
      if (this.selectedStore !== undefined || this.selectedStore !== null || this.selectedStore !== '') {
        const config = {
          params: {
            company: this.$store.state.auth.user.company,
            store: this.selectedStore
          }
        }
        const body = {
          labelList: this.selected.map(labelData => labelData.id)
        }
        this.$utils.callAxiosWithBody('post', url, body, config).then((res) => {
          this.paging(1)
          this.$forceUpdate()
          this.resetLabel()
        })
      } else {
        EventBus.$emit('messageAlert', this.$t('Please Select Store'))
        this.resetLabel()
      }
    },

    paging (input) {
      this.page = input * 1 - 1
      this.goToPageInput = input
      this.searchLabelList(this.page)
    },
    updatePage (event) {
      console.log(event)
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    clickCheckBox () {
      this.page = 0
      this.searchLabelList()
    },
    saveRequestConfig: function (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params,
        PopUpMessage: 'true'
      }
      this.requestConfig = requestConfig
    },
    getRowClass () {
      if (this.btnDisabledDetail) return ''
      return this.ROW_CLASS
    },
    exportLabelList () {
      exportFiles(this.requestConfig, this.$refs.link, 'LabelList.xlsx')
    },
    setRouterSearchCondition (router) {
      if (commons.isAllInvalid([...Object.values(router.params)])) {
        // 검색조건 설정없으면 전체 라벨 표출.
        this.initData()
        return
      }
      if (!commons.isNull(router.params.status)) {
        this.searchBy.statusParam = router.params.status
        this.searchParam.status = router.params.status
        // var Status = Object.values(router.params)
        // this.searchBy.statusParam = Status
        // this.searchParam.status = router.params.status
      }
      if (!commons.isNull(router.params.network)) {
        this.searchParam.network = router.params.network
      }
      if (!commons.isNull(router.params.battery)) {
        this.searchParam.battery = router.params.battery
      }
      if (!commons.isNull(router.params.signal)) {
        this.searchParam.signal = router.params.signal
      }
      if (!commons.isNull(router.params.misusedLabelType)) {
        this.searchParam.misusedLabelType = router.params.misusedLabelType
      }
    },
    setLastSearchCondition () {
      const labelInfoSessionData = getTempObj('labelInfo')
      if (
        !commons.isNull(labelInfoSessionData) &&
        !commons.isNull(labelInfoSessionData.searchBy)
      ) {
        this.searchBy = labelInfoSessionData.searchBy
      }
    },
    resetSearchBy () {
      this.searchBy = {
        labelParam: null,
        gatewayParam: null,
        statusParam: null,
        typeParam: null,
        templateTypeParam: null,
        productIdParam: null,
        productDescParam: null
      }
    },
    // assign popup FOR ROGERS
    openassignPopup () {
      this.assignpopup = true
      this.productType = ''
      this.producttypechange = false
      this.productlist = []
      this.productdataselect = 'Product ID'
      this.templatelist = []
      this.templatelistvalue = ''
      this.labelCode = ''
      this.templatetypebylabel = false
      this.productValue = ''
      this.getTemplatestype()
    },
    getTemplatestype () {
      const params = { size: 2000 }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTemplatestype.method,
          requests.getTemplatestype.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            this.templatelist = res.data
          } else {
            this.templatelist = []
          }
        })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
        })
    },
    gettemplatesbylabel () {
      if (this.templatetypebylabel === true) {
        const params = { labelCode: this.labelCode }
        const config = { params: params }
        this.$utils
          .callAxios(
            requests.getTemplatestypebylabel.method,
            requests.getTemplatestypebylabel.url,
            config
          )
          .then(res => {
            if (res.status === 200) {
              if (res.data.colorType === 'TERNARY_RED' && (this.rogersBrand === 'ROGERS' || this.rogersBrand === 'ROGERS IN FIDO')) {
                this.gettemplatebylabeltype(res.data)
              } else if (res.data.colorType === 'TERNARY_YELLOW' && (this.rogersBrand === 'FIDO' || this.rogersBrand === 'FIDO IN ROGERS')) {
                this.gettemplatebylabeltype(res.data)
              } else {
                this.MessageBoardpopup = true
                this.templatelist = []
              }
            }
          }).catch(error => {
            console.log(error)
            EventBus.$emit('messageAlert', this.$t('LabelCode Not Valid'))
            this.templatetypebylabel = false
          })
      } else {
        this.getTemplatestype()
      }
    },
    changeflag (event) {
      this.labeltypeflag = event
    },
    gettemplatebylabeltype (data) {
      const params = { company: this.$store.state.auth.user.company, height: data.displayHeight, width: data.displayWidth, size: 10 }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.gettemplatesbylabeltype.method,
          requests.gettemplatesbylabeltype.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            this.templatelist = res.data.templateList
          }
        })
        .catch(error => {
          console.log(error)
          this.templatetypebylabel = false
          this.templatelist = []
        })
    },
    getStoreInfo (storeCode) {
      const params = { store: storeCode }
      const config = { params }
      if (this.productionType === 'cloud') params.company = this.$store.state.auth.user.company
      return this.$utils
        .callAxios(
          codes.requests.getStoreSummary.method,
          codes.requests.getStoreSummary.url,
          config
        )
        .then(res => {
          return res.data
        })
    },
    async tocheckRogersBrand (storeCode) {
      const storeInfo = await this.getStoreInfo(storeCode)
      const data = JSON.parse(storeInfo.storeDescription)
      this.rogersBrand = data.groupId.toUpperCase()
    },
    ToassignLabel () {
      if (this.labeltypeflag === false) {
        this.templatetypebylabel = true
        this.gettemplatesbylabel()
      } else {
        this.ToassignLabelbyStore()
      }
    },
    ToassignLabelbyStore () {
      var body = {}
      if (this.productValue.articleId !== '' && this.productValue !== '' && this.labelCode !== '' && this.templatelistvalue !== '') {
        if (this.templatetypebylabel === false) {
          body = {
            articleIdList: [this.productValue.articleId],
            customBatchId: 'DashboardRequest',
            labelCode: this.labelCode,
            templateName: this.templatelistvalue.typeName
          }
        } else if (this.templatetypebylabel === true) {
          body = {
            articleIdList: [this.productValue.articleId],
            labelCode: this.labelCode,
            templateName: this.templatelistvalue.templateName
          }
        }
        const finalobject = {
          assignList: [body]
        }
        const params = { store: this.selectedStore }
        const config = { params: params }
        return this.$utils
          .callAxiosWithBody(
            requests.toassignlabels.method,
            requests.toassignlabels.url,
            finalobject,
            config
          )
          .then(res => {
            // const result = res.data
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.assignpopup = false
          })
          .catch(error => {
            console.log(error)
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
            this.assignpopup = false
          })
      } else {
        EventBus.$emit('messageAlert', this.$t('Enter Valid Data'))
        this.assignpopup = true
      }
    },
    producttype (eve) {
      this.producttypechange = true
      this.getvaluebyproducttype(eve)
    },

    getvaluebyproducttype (val) {
      // key=PRODUCT_TYPE&stationCode=RC001&value=Accessory&size=2000
      const config = {}
      config.params = { key: 'PRODUCT_TYPE', stationCode: this.selectedStore, value: val, size: 2000 }
      // const url = configs.ServerAddress + requests.getvaluebyproducttype.url
      this.$utils
        .callAxios(
          requests.getvaluebyproducttype.method,
          requests.getvaluebyproducttype.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            console.log(res.data)
            this.productlist = res.data.articleList
          } else {
            this.productlist = []
          }
        }).catch((err) => {
          EventBus.$emit('messageAlert', this.$t(err.response.data.responseMessage))
        })
    },
    // end of assign event ROGERS

    // Resend Label Assign
    ResendLabelsAssign () {
      var results = this.InitialData.filter(({ labelCode }) => this.selected.some(({ id }) => id === labelCode))
      const finalvalue = results.filter(item => (item.articleList.length !== 0))
      results = finalvalue
      const finaldata = []
      var obj = {}
      console.log(results.length)
      if (results.length !== 0) {
        for (let i = 0; i < results.length; i++) {
          obj = {
            addInfo2: results[i].addInfo2,
            addInfo3: results[i].addInfo3,
            addInfo4: results[i].addInfo4,
            addInfo5: results[i].addInfo5,
            arrow: results[i].arrow,
            articleIdList: [
              results[i].articleList[0].articleId
            ],
            labelCode: results[i].labelCode,
            templateName: results[i].templateType[0]
          }
          finaldata.push(obj)
        }
        const url = '/api/common/labels/link'
        const config = {
          params: {
            store: this.selectedStore
          }
        }
        const body = {
          assignList: finaldata
        }
        this.$utils.callAxiosWithBody('post', url, body, config).then((res) => {
          if (res.status === 200) {
            this.messageText = this.$t(res.data.responseMessage)
            this.messageDialog = true
            this.paging(1)
            this.$forceUpdate()
          }
        })
          .catch(error => {
            console.debug(`Failed to assign labels. error: ${error}`)
            this.messageText = this.$t('assigning failed.')
            this.messageDialog = true
          })
      } else {
        this.messageText = this.$t('assigning failed.')
        this.messageDialog = true
      }
    },
    onChange (val) {
      this.statuslist = val
    },
    onChangetype (val) {
      this.labelTypelist = val
    },

    // LCL Methods
    // To Resend all labels funtion
    toresendAllLabels () {
      console.log(this.searchParam)
      console.log(this.searchBy)
      if (this.searchBy.typeParam) {
        if (this.searchBy.typeParam.length === 0) {
          this.searchParam.type = null
        } else {
          this.searchParam.type = (this.searchBy.typeParam).toString()
        }
      } else delete this.searchParam.type

      if (this.searchBy.statusParam) {
        console.log(this.searchBy.statusParam.length)
        if (this.searchBy.statusParam.length === 0) {
          this.searchParam.status = null
        } else {
          this.searchParam.status = (this.searchBy.statusParam).toString()
        }
      } else delete this.searchParam.status
      const params = {
        store: this.searchParam.store,
        label: this.searchBy.labelParam,
        status: this.searchParam.status,
        battery: this.searchParam.battery,
        signal: this.searchParam.signal,
        type: this.searchParam.type,
        network: this.searchParam.network,
        articleId: this.searchBy.productIdParam,
        articleName: this.searchBy.productDescParam,
        misusedLabelType: this.searchParam.misusedLabelType,
        gateway: this.searchBy.gatewayParam,
        templateType: this.searchBy.templateTypeParam
      }
      const config = { params: params }
      const finalobject = {}
      console.log(config)
      this.$utils
        .callAxiosWithBody(
          requests.toresendAllLabels.method,
          requests.toresendAllLabels.url,
          finalobject,
          config
        )
        .then(res => {
          console.log(res)
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
        })
        .catch(err => {
          console.log(err)
          EventBus.$emit('messageAlert', this.$t(err.response.data.responseMessage))
        })

    // end toresendAllLabels
    },

    // Blink LED ALL Functions
    initDataBlinkLEDAll () {
      this.$store.dispatch('auth/isAccessMenu', '6200').then(flag => {
        if (!flag) {
          this.pattern = 1
          this.duration = '0s'
          this.color = 'RED'
          this.patternItems = [{ id: 1, name: 'PATTERN_1' }]
          this.selectBoxEnable = true
        } else {
          this.pattern = 0
          this.duration = '0s'
          this.color = 'RED'
          this.patternItems = []
          this.getBlinkData()
        }
      })
    },
    getBlinkData () {
      const config = {
        params: { comapany: this.$store.state.auth.user.company }
      }
      this.$utils
        .callAxios(
          requests.getLabelLedData.method,
          requests.getLabelLedData.url,
          config
        )
        .then(res => {
          this.ledPattern = res.data
          if (res.data !== undefined && res.data.ledConfigList.length > 0) {
            this.patternItems = res.data.ledConfigList.map(ledConfig => {
              return { name: ledConfig.patternName, id: ledConfig.id }
            })
          }
          if (this.patternItems[0] !== undefined) {
            this.pattern = this.patternItems[0].id
          }
        })
    },
    toblinkLedAllLabels () {
      this.initDataBlinkLEDAll()
      this.dialogBlinkLED = true
      console.log(this.searchParam)
    },
    handleSaveBtnClick () {
      console.log(this.searchParam)
      console.log(this.searchBy)
      if (this.searchBy.typeParam) {
        if (this.searchBy.typeParam.length === 0) {
          this.searchParam.type = null
        } else {
          this.searchParam.type = (this.searchBy.typeParam).toString()
        }
      } else delete this.searchParam.type

      if (this.searchBy.statusParam) {
        console.log(this.searchBy.statusParam.length)
        if (this.searchBy.statusParam.length === 0) {
          this.searchParam.status = null
        } else {
          this.searchParam.status = (this.searchBy.statusParam).toString()
        }
      } else delete this.searchParam.status
      const params = {
        store: this.searchParam.store,
        label: this.searchBy.labelParam,
        status: this.searchParam.status,
        battery: this.searchParam.battery,
        signal: this.searchParam.signal,
        type: this.searchParam.type,
        network: this.searchParam.network,
        articleId: this.searchBy.productIdParam,
        articleName: this.searchBy.productDescParam,
        misusedLabelType: this.searchParam.misusedLabelType,
        gateway: this.searchBy.gatewayParam,
        templateType: this.searchBy.templateTypeParam,
        color: this.color,
        duration: this.duration,
        patternId: this.pattern
      }
      const config = { params: params }
      const finalobject = {}
      console.log(config)
      this.$utils
        .callAxiosWithBody(
          requests.toblinkLedAllLabels.method,
          requests.toblinkLedAllLabels.url,
          finalobject,
          config
        )
        .then(res => {
          console.log(res)
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.dialogBlinkLED = false
        })
        .catch(err => {
          console.log(err)
          EventBus.$emit('messageAlert', this.$t(err.response.data.responseMessage))
          this.dialogBlinkLED = false
        })
      // End Blink LED ALL
    },

    // Page Flip All Functions
    PageFlipAll () {
      this.pageFlipDialog = true
      this.togetPropert()
    },
    togetPropert () {
      const params = { }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.togetPageFlipSize.method,
          requests.togetPageFlipSize.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            this.pageSize = res.data.aimsPortalBiz1MaxPage
            for (let i = 1; i <= this.pageSize; i++) {
              this.PageListarray.push(i)
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.PageListarray = []
        })
    },

    PageFlipAllSaveBtn () {
      if (this.searchBy.typeParam) {
        if (this.searchBy.typeParam.length === 0) {
          this.searchParam.type = null
        } else {
          this.searchParam.type = (this.searchBy.typeParam).toString()
        }
      } else delete this.searchParam.type

      if (this.searchBy.statusParam) {
        console.log(this.searchBy.statusParam.length)
        if (this.searchBy.statusParam.length === 0) {
          this.searchParam.status = null
        } else {
          this.searchParam.status = (this.searchBy.statusParam).toString()
        }
      } else delete this.searchParam.status
      const params = {
        store: this.searchParam.store,
        label: this.searchBy.labelParam,
        status: this.searchParam.status,
        battery: this.searchParam.battery,
        signal: this.searchParam.signal,
        type: this.searchParam.type,
        network: this.searchParam.network,
        articleId: this.searchBy.productIdParam,
        articleName: this.searchBy.productDescParam,
        misusedLabelType: this.searchParam.misusedLabelType,
        gateway: this.searchBy.gatewayParam,
        templateType: this.searchBy.templateTypeParam,
        page: this.PageValue
      }
      const config = { params: params }
      const finalobject = {}
      console.log(config)
      this.$utils
        .callAxiosWithBody(
          requests.tosavepageFlipSettings.method,
          requests.tosavepageFlipSettings.url,
          finalobject,
          config
        )
        .then(res => {
          console.log(res)
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.pageFlipDialog = false
        })
        .catch(err => {
          console.log(err)
          EventBus.$emit('messageAlert', this.$t(err.response.data.responseMessage))
          this.pageFlipDialog = false
        })
    },
    // End LCD Features and Functions

    resetLabel () {
      this.selected = []
      this.searchLabelList()
    }
  }
}
</script>
<style scoped>
	::v-deep .nodatacss {
		position: relative;
		left: 470px;
	}

	::v-deep .row-class:hover {
		cursor: pointer;
	}
	::v-deep .pageAlign {
		/* width:100px;
	  margin-left:40px;
	  margin-right:90px */
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep .rowAlign {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	::v-deep .pageAlign ul li {
		padding: 0px !important;
		text-align: center;
	}

	.theme--light.v-chip:not(.v-chip--active) {
		background: #e0e0e0;
		margin: 7px !important;
	}
	.v-select.v-select--chips input {
		display: none !important;
	}
	.v-select.v-select--chips .v-select__selections {
		min-height: 30px !important;
	}
	::v-deep .form-select.v-text-field--outlined .v-select__selections {
		display: flex !important;
		position: relative;
	}

	.item + .item:before {
		content: ", ";
	}
	.v-tooltip-resize {
		width: 200px;
		word-break: break-word;
	}
	.v-tooltip__content {
		background: #001e38;
		color: #fff;
		margin-left: 4px;
		padding: 5px 10px !important;
	}
	.v-tooltip__content:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-top: 15px solid #001e38;
		left: 40%;
		bottom: -10px;
	}
	/* Scroll bar stylings */
	::v-deep ::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	/* Track */
	::v-deep ::-webkit-scrollbar-track {
		background: var(--lightestgrey);
	}

	/* Handle */
	::v-deep ::-webkit-scrollbar-thumb {
		background: #c1c1c1;
		border-radius: 5px;
	}

	/* Handle on hover */
	::v-deep ::-webkit-scrollbar-thumb:hover {
		background: #c1c1c1;
	}
	::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
		background: #c1c1c1;
		border-radius: 5px;
	}
	::v-deep .theme--light.v-chip:not(.v-chip--active) {
		background: #e0e0e0;
		margin: 5px !important;
	}
	::v-deep .statuslistclass input {
		display: none;
	}
	::v-deep .labelTypeclass input {
		display: none;
	}
	::v-deep .form-select1 {
		padding-top: 0px !important;
		width: 70px;
		margin-right: 12px !important;
	}
::v-deep .form-select.v-text-field--outlined .v-select__selection--comma {
    position: absolute;
    top: -3px !important;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 40px;
}
.LedPatternClass + input{
  display: contents!important;
}
.messageClass span {
 display: block;
 text-align: center;
}

</style>
