<template>
  <div>
    <v-btn
      @click="openScheduleModel($event)"
      :disabled="selectedLabel.length === 0 || scheduleSettingsButton"
      text
      :v-model="LabelSchedulepopup"
      class="btn ml-2"
      >{{ $t("Schedule Settings") }}</v-btn
    >
    <div>
      <v-dialog v-model="LabelSchedulepopup" scrollable persistent width="1300">
        <v-card class="popup add_store_popup">
          <v-card-title>
            <h3 class="page-title-bar">
              <i class="ico ico-label"></i>{{ $t("Schedule Setting") }}
            </h3>
          </v-card-title>
          <!-- new changes -->
          <v-card-text>
            <v-tabs v-model="tabData" grow class="tabBgColor">
              <v-tab @click="ChangeTab('Schedule')">{{ $t("Schedule") }}</v-tab>
              <v-tab @click="ChangeTab('RepeatSchedule')">{{
                $t("Repeat Schedule")
              }}</v-tab>
              <v-tab-item class="tabitemclass">
                <div style="padding: 25px">
                  <v-row>
                    <v-col cols="10">
                      <v-row>
                        <v-col class="d-flex">
                          <v-menu
                            v-model="schedule.showStartDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="50px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scheduleStartTime"
                                :label="`${$t('Start Time')}*`"
                                :placeholder="`${$t('Select Start Date')}`"
                                class="form-select mb-4 col mandatory"
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                hide-details
                                readonly
                                clearable
                                style="max-width: 170px; float: left"
                                prepend-icon=""
                                append-icon="mdi-calendar-month"
                                ref="calendarstart"
                                @click="setCurrentStartDate"
                                @click:append="
                                  $refs.calendarstart.$el
                                    .querySelector('input')
                                    .click()
                                "
                              >
                              </v-text-field>
                            </template>
                            <date-picker
                              mode="date"
                              v-model="scheduleStartTime"
                              :min-date="new Date()"
                              :minute-increment="1"
                              :is-required="true"
                              is24hr
                              :model-config="{
                                type: 'string',
                                mask: 'YYYY-MM-DD',
                              }"
                            />
                          </v-menu>
                          <v-select
                            :items="getHours"
                            outlined
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            v-model="startTimeHoursSelection"
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                          <span class="mt-8 pl-3">:</span>
                          <v-select
                            :v-label="`${$t('End Time')}*`"
                            :items="getMinutes"
                            outlined
                            v-model="startTimeMinutesSelection"
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>

                          <span class="mt-8 pl-3 pr-3"> ~ </span>

                          <v-menu
                            v-model="schedule.showEndDatePicker"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="50px"
                            class="ml-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scheduleEndTime"
                                @click:clear="scheduleEndTime = null"
                                :label="`${$t('End Time')}*`"
                                :placeholder="`${$t('Select End Date')}`"
                                class="form-select mb-4 col mandatory"
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                hide-details
                                readonly
                                clearable
                                style="max-width: 160px"
                                prepend-icon=""
                                append-icon="mdi-calendar-month"
                                ref="calendarend"
                                @click="setCurrentEndDate"
                                @click:append="
                                  $refs.calendarend.$el
                                    .querySelector('input')
                                    .click()
                                "
                              >
                              </v-text-field>
                            </template>
                            <date-picker
                              mode="date"
                              v-model="scheduleEndTime"
                              :min-date="scheduleStartTime"
                              :minute-increment="1"
                              :is-required="true"
                              is24hr
                              :model-config="{
                                type: 'string',
                                mask: 'YYYY-MM-DD',
                              }"
                            />
                          </v-menu>
                          <v-select
                            :v-label="`${$t('End Time')}*`"
                            :items="getHours"
                            outlined
                            v-model="endTimeHoursSelection"
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                          <span class="mt-8 pl-3">:</span>
                          <v-select
                            :v-label="`${$t('End Time')}*`"
                            :items="getMinutes"
                            v-model="endTimeMinutesSelection"
                            outlined
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex">
                          <v-select
                            :label="`${$t('Display Page')}`"
                            :items="PageListarray"
                            v-model="displayPageSchedule"
                            outlined
                            style="max-width: 160px"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                        </v-col></v-row
                      >
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        class="btn mt-6 ml-3"
                        style="height: 120px; width: 130px"
                        @click="AddSceduleddate"
                      >
                        <span style="line-height: 119px">Add</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="mt-8">
                        <div>
                          <v-data-table
                            :headers="scheduleHeaders"
                            :items="scheduleList"
                            item-key="id"
                            :hide-default-footer="true"
                            fixed-header
                            height="240"
                            disable-pagination
                            class="tbl-type01"
                          >
                            <template slot="no-data">
                              <p>{{ $t("No data available") }}</p>
                            </template>
                            <template v-slot:[`item.sno`]="{ item }">
                              <td>
                                {{ scheduleList.indexOf(item) + 1 }}
                              </td>
                            </template>
                            <template v-slot:[`item.icon`]="{ item }">
                              <v-icon
                                style="color: red"
                                @click="deleteSelectedSchedule(item)"
                                medium
                                >mdi-close-circle
                              </v-icon>
                            </template>
                          </v-data-table>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>

              <!-- //For Repeat Schedule Setting tab -->
              <v-tab-item class="tabitemclass">
                <div style="padding: 25px">
                  <v-row>
                    <v-col cols="10">
                      <v-row>
                        <v-col class="d-flex">
                          <v-menu
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="50px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="repeatscheduleStartTime"
                                :label="`${$t('Start Date')}*`"
                                :placeholder="`${$t('Select Start Date')}`"
                                class="form-select mb-4 col mandatory"
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                                hide-details
                                readonly
                                clearable
                                style="max-width: 170px; float: left"
                                prepend-icon=""
                                append-icon="mdi-calendar-month"
                                ref="Repeatcalendarstart"
                                @click="repeatscheduleStartTimeset"
                                @click:append="
                                  $refs.Repeatcalendarstart.$el
                                    .querySelector('input')
                                    .click()
                                "
                              >
                              </v-text-field>
                            </template>
                            <date-picker
                              mode="date"
                              v-model="repeatscheduleStartTime"
                              :min-date="new Date()"
                              :minute-increment="1"
                              :is-required="true"
                              is24hr
                              :model-config="{
                                type: 'string',
                                mask: 'YYYY-MM-DD',
                              }"
                            />
                          </v-menu>
                          <span
                            style="
                              position: absolute;
                              top: 38px;
                              left: 223px;
                              font-size: 12px;
                            "
                          >
                            Time
                          </span>
                          <v-select
                            :items="getHours"
                            outlined
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            v-model="repeatstartTimeHoursSelection"
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                          <span class="mt-8 pl-3">:</span>
                          <v-select
                            :items="getMinutes"
                            outlined
                            v-model="repeatstartTimeMinutesSelection"
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                          <span class="mt-8 pl-3 pr-3 ml-2"> ~ </span>
                          <v-select
                            :v-label="`${$t('End Time')}*`"
                            :items="getHours"
                            outlined
                            v-model="repeatendTimeHoursSelection"
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                          <span class="mt-8 pl-3">:</span>
                          <v-select
                            :v-label="`${$t('End Time')}*`"
                            :items="getMinutes"
                            v-model="repeatendTimeMinutesSelection"
                            outlined
                            style="max-width: 85px"
                            class="mt-6 pl-3"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="7" style="margin-left: 9px">
                          <small class="repeatdaysCss">Repeat Days</small>
                          <div class="row">
                            <div
                              v-for="(item, i) in getWeekDaysSelected"
                              :key="i"
                              class="cart-size"
                              :class="
                                item.selected ? 'active-day' : 'not-active-day'
                              "
                              @click="selectAssignFunction(item, i)"
                            >
                              <small> {{ item.name }}</small>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="4" class="d-flex">
                          <v-select
                            :label="`${$t('Display Page')}`"
                            :items="PageListarray"
                            v-model="repeatdisplayPageSchedule"
                            outlined
                            style="max-width: 160px"
                            dense
                            hide-details
                          >
                            <template v-slot:item="{ item }">
                              <span style="font-size: 0.8125rem">{{
                                $t(item)
                              }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="LedPatternClass"
                                style="display: contents !important"
                                >{{ $t(item) }}</span
                              >
                            </template>
                          </v-select>
                        </v-col></v-row
                      >
                    </v-col>

                    <v-col cols="2">
                      <v-btn
                        class="btn mt-6 ml-3"
                        style="height: 120px; width: 148px"
                        @click="AddRepeatSceduleddate"
                      >
                        <span style="line-height: 119px">Add</span>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <div class="mt-8">
                        <div>
                          <v-data-table
                            :headers="repeatscheduleHeaders"
                            :items="repeatscheduleList"
                            item-key="id"
                            :hide-default-footer="true"
                            fixed-header
                            height="240"
                            disable-pagination
                            class="tbl-type01"
                          >
                            <template slot="no-data">
                              <p>{{ $t("No data available") }}</p>
                            </template>
                            <template v-slot:[`item.sno`]="{ item }">
                              <td>
                                {{ repeatscheduleList.indexOf(item) + 1 }}
                              </td>
                            </template>
                            <template v-slot:[`item.icon`]="{ item }">
                              <v-icon
                                style="color: red"
                                @click="deleteSelectedRepeatSchedule(item)"
                                medium
                                >mdi-close-circle
                              </v-icon>
                            </template>
                          </v-data-table>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-card-actions v-if="tabData === 0" class="d-flex justify-center">
            <v-btn
              text
              icon
              @click="
                topostSchedullist();
                LabelSchedulepopup = false;
              "
              class="btn"
            >
              {{ $t("Save") }}</v-btn
            >
            <v-btn
              text
              icon
              @click="
                LabelSchedulepopup = false;
                resetLabel();
              "
              class="btn"
              >{{ $t("Cancel") }}</v-btn
            >
          </v-card-actions>
          <v-card-actions v-else class="d-flex justify-center">
            <v-btn
              text
              icon
              class="btn"
              @click="
                topostRepeatSchedullist();
                LabelSchedulepopup = false;
              "
              >{{ $t("Save") }}</v-btn
            >
            <v-btn
              text
              icon
              @click="
                LabelSchedulepopup = false;
                resetLabel();
              "
              class="btn"
              >{{ $t("Cancel") }}</v-btn
            >
          </v-card-actions>
          <!-- end -->
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
export default {
  components: {
    DatePicker
  },
  props: ['PageListarray', 'selectedLabel'],
  data () {
    return {
      LabelSchedulepopup: false,
      tabData: 0,
      schedule: {},
      scheduleStartTime: '',
      scheduleEndTime: '',
      startTimeAMPMSelection: '',
      endTimeAMPMSelection: '',
      startTimeHoursSelection: '',
      endTimeHoursSelection: '',
      startTimeMinutesSelection: '',
      endTimeMinutesSelection: '',
      displayPageSchedule: '',
      scheduleList: [],
      // Repeat schdule
      repeatscheduleStartTime: '',
      repeatscheduleEndTime: '',
      repeatstartTimeAMPMSelection: '',
      repeatendTimeAMPMSelection: '',
      repeatstartTimeHoursSelection: '',
      repeatendTimeHoursSelection: '',
      repeatstartTimeMinutesSelection: '',
      repeatendTimeMinutesSelection: '',
      repeatdisplayPageSchedule: '',
      repeatscheduleList: [],
      getWeekDaysSelected: [
        {
          name: 'Mon',
          selected: true
        },
        {
          name: 'Tue',
          selected: true
        },
        {
          name: 'Wed',
          selected: true
        },
        {
          name: 'Thu',
          selected: true
        },
        {
          name: 'Fri',
          selected: true
        },
        {
          name: 'Sat',
          selected: true
        },
        {
          name: 'Sun',
          selected: true
        }
      ],
      scheduleSettingsButton: null
    }
  },
  computed: {
    getHours () {
      return Array.from({ length: 24 }, (_, i) =>
        i.toString().padStart(2, '0')
      )
    },
    getMinutes () {
      return Array.from({ length: 60 }, (_, i) =>
        i.toString().padStart(2, '0')
      )
    },
    scheduleHeaders () {
      return [
        { text: this.$t('#'), sortable: false, value: 'sno' },
        {
          text: this.$t('START TIME *'),
          sortable: false,
          value: 'startTime',
          width: '25%'
        },
        {
          text: this.$t('END TIME *'),
          sortable: false,
          value: 'endTime',
          width: '25%'
        },
        {
          text: this.$t('DISPLAY PAGE'),
          sortable: false,
          value: 'displayPage',
          width: '23%'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'icon',
          width: '15%'
        }
      ]
      // end
    },
    repeatscheduleHeaders () {
      return [
        { text: this.$t('#'), sortable: false, value: 'sno', width: '10%' },
        {
          text: this.$t('START DATE *'),
          sortable: false,
          value: 'startDate',
          width: '25%'
        },
        {
          text: this.$t('DAY'),
          sortable: false,
          value: 'repeatDay',
          width: '25%'
        },
        {
          text: this.$t('TIME'),
          sortable: false,
          value: 'applyTime',
          width: '25%'
        },
        {
          text: this.$t('DISPLAY PAGE'),
          sortable: false,
          value: 'displayPage',
          width: '23%'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'icon',
          width: '15%'
        }
      ]
      // end
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    }
  },
  methods: {
    openScheduleModel (event) {
      this.scheduleList = []
      this.LabelSchedulepopup = event
      this.setCurrentStartDate()
    },
    setCurrentStartDate (e) {
      this.tabData = 0
      // this.scheduleList = []
      // this.repeatscheduleList = []
      if (!this.scheduleStartTime) {
        setTimeout(() => {
          this.scheduleStartTime = moment(new Date()).format('YYYY-MM-DD')
        }, 200)
      }
      const currentDate = new Date()
      const hour = currentDate.getHours()
      // const minutes = currentDate.getMinutes()
      //   // Determine if it's AM or PM
      if (hour >= 12) {
        this.startTimeAMPMSelection = 'PM'
      } else {
        this.startTimeAMPMSelection = 'AM'
      }
    },
    setCurrentEndDate (e) {
      if (!this.scheduleEndTime) {
        setTimeout(() => {
          this.scheduleEndTime = moment(
            new Date(this.scheduleStartTime)
          ).format('YYYY-MM-DD')
        }, 200)
      }
    },

    AddSceduleddate () {
      if (
        this.displayPageSchedule !== '' &&
        this.scheduleStartTime !== '' &&
        this.startTimeHoursSelection !== '' &&
        this.startTimeMinutesSelection !== ' ' &&
        this.scheduleEndTime !== '' &&
        this.endTimeHoursSelection !== '' &&
        this.endTimeMinutesSelection !== ' '
      ) {
        var array = {
          displayPage: this.displayPageSchedule,
          startTime:
            moment(new Date(this.scheduleStartTime)).format('YYYY-MM-DD') +
            ' ' +
            this.startTimeHoursSelection +
            ':' +
            this.startTimeMinutesSelection,
          endTime:
            moment(this.scheduleEndTime).format('YYYY-MM-DD') +
            ' ' +
            this.endTimeHoursSelection +
            ':' +
            this.endTimeMinutesSelection
        }
        const startTime = new Date(array.startTime)
        const endTime = new Date(array.endTime)
        const exists = this.scheduleList.some(
          (obj) =>
            obj.displayPage === array.displayPage &&
            obj.startTime === array.startTime &&
            obj.endTime === array.endTime
        )

        if (!exists && endTime > startTime) {
          if (this.scheduleList.length < 21) {
            this.scheduleList.push(array)
          } else {
            return EventBus.$emit('messageAlert', this.$t('Schedule List should be less than 20'))
          }
        } else {
          return EventBus.$emit(
            'messageAlert',
            this.$t(
              'Duplicate data not allowed or End Time should be greater than Start Time'
            )
          )
        }

        this.displayPageSchedule = ''
        this.scheduleStartTime = ''
        this.startTimeHoursSelection = ''
        this.startTimeMinutesSelection = ' '
        this.startTimeAMPMSelection = ''
        this.scheduleEndTime = ''
        this.endTimeHoursSelection = ''
        this.endTimeMinutesSelection = ' '
        this.endTimeAMPMSelection = ''
      } else {
        return EventBus.$emit(
          'messageAlert',
          this.$t('Please Select all data')
        )
      }
    },
    // delete Schedule time
    deleteSelectedSchedule (item) {
      console.log(item)
      this.scheduleList = this.scheduleList.filter(
        (obj) => JSON.stringify(obj) !== JSON.stringify(item)
      )
    },

    // END Schdule part
    // Repeat Scheduled part
    repeatscheduleStartTimeset (e) {
      if (!this.repeatscheduleStartTime) {
        setTimeout(() => {
          this.repeatscheduleStartTime = moment(new Date()).format(
            'YYYY-MM-DD'
          )
        }, 200)
      }
    },
    convertTo24HourFormat (timeStr) {
      const [period, time] = timeStr.split(' ')
      var [hours, minutes] = time.split(':').map(Number)

      if (period === 'PM') {
        if (hours !== 12) {
          hours += 12
        }
      } else if (period === 'AM' && hours === 12) {
        hours = 0
      }

      const formattedTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}`
      return formattedTime
    },
    AddRepeatSceduleddate () {
      const days = this.getWeekDaysSelected.every(day => !day.selected)
      if (
        this.repeatdisplayPageSchedule !== '' &&
        this.repeatscheduleStartTime !== '' &&
        this.repeatstartTimeHoursSelection !== '' &&
        this.repeatstartTimeMinutesSelection !== '' &&
        this.repeatendTimeHoursSelection !== '' &&
        this.repeatendTimeMinutesSelection !== '' && !days
      ) {
        const selecteddays = this.getWeekDaysSelected.filter(
          (el) => el.selected === true
        )
        var result = selecteddays.map((el) => el.name)
        result = result.join(',')
        const StarttimeData =
          this.repeatstartTimeHoursSelection +
          ':' +
          this.repeatstartTimeMinutesSelection
        var startTime = StarttimeData
        const EndtimeData =
          this.repeatendTimeHoursSelection +
          ':' +
          this.repeatendTimeMinutesSelection
        var endTime = EndtimeData
        var arrayRepeatSchedule = {
          displayPage: this.repeatdisplayPageSchedule,
          startDate: moment(new Date(this.repeatscheduleStartTime)).format(
            'YYYY-MM-DD'
          ),
          repeatDay: result.toUpperCase(),
          applyTime: startTime + ' ' + '~' + ' ' + endTime
        }
        const repeatDays = arrayRepeatSchedule.repeatDay.split(',') // Split the days
        var array = repeatDays.map((day) => ({
          displayPage: arrayRepeatSchedule.displayPage,
          startDate: arrayRepeatSchedule.startDate,
          repeatDay: day,
          applyTime: arrayRepeatSchedule.applyTime
        }))
        const existingData = this.repeatscheduleList.map(JSON.stringify)
        // Check if data already exists before pushing
        array.forEach(item => {
          if (!existingData.includes(JSON.stringify(item))) {
            if (array.length + existingData.length <= 20) {
              this.repeatscheduleList.push(item)
            } else {
              return EventBus.$emit('messageAlert', this.$t('RepeatSchedule List should be less than 20'))
            }
          } else {
            const val = JSON.stringify(item)
            return EventBus.$emit('messageAlert', this.$t('Duplicate data found in \n' + val)
            )
          }
        })
        this.repeatdisplayPageSchedule = ''
        this.repeatscheduleStartTime = moment(new Date()).format('YYYY-MM-DD')
        this.repeatstartTimeHoursSelection = ''
        this.repeatstartTimeMinutesSelection = ' '
        this.repeatstartTimeAMPMSelection = ''
        this.repeatendTimeHoursSelection = ''
        this.repeatendTimeMinutesSelection = ' '
        this.repeatendTimeAMPMSelection = ''
        this.getWeekDaysSelected.forEach((data) => {
          data.selected = true
        })
      } else {
        return EventBus.$emit(
          'messageAlert',
          this.$t('Please Select all data')
        )
      }
    },
    selectAssignFunction (item, index) {
      console.log(item)
      this.getWeekDaysSelected[index].selected =
        !this.getWeekDaysSelected[index].selected
    },
    deleteSelectedRepeatSchedule (item) {
      console.log(item)
      this.repeatscheduleList = this.repeatscheduleList.filter(
        (obj) => JSON.stringify(obj) !== JSON.stringify(item)
      )
      console.log(this.repeatscheduleList)
    },
    topostSchedullist () {
      console.log(this.scheduleList)

      const convertedDatascheduleList = this.scheduleList.map((item) => ({
        displayPage: item.displayPage,
        startTime: moment(item.startTime, 'YYYY-MM-DD hh:mm A').format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        endTime: moment(item.endTime, 'YYYY-MM-DD hh:mm A').format(
          'YYYY-MM-DD HH:mm:ss'
        )
      }))

      const finalobject = {
        scheduleList: convertedDatascheduleList,
        labelCodes: this.selectedLabel
      }

      const params = { store: this.selectedStore }
      const config = { params: params }
      return this.$utils
        .callAxiosWithBody(
          codes.requests.postScheduleSettings.method,
          codes.requests.postScheduleSettings.url,
          finalobject,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.$emit('fireResetLabel')
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage)
          )
          this.$emit('fireResetLabel')
        })
    },

    topostRepeatSchedullist () {
      console.log(this.repeatscheduleList)
      this.repeatscheduleList.forEach(item => {
        item.startDate = moment(item.startDate, 'YYYY-MM-DD').format('YY-MM-DD')
      })
      const finalobject = {
        repeatScheduleList: this.repeatscheduleList,
        labelCodes: this.selectedLabel,
        enable: 'true'
      }
      const params = { store: this.selectedStore }
      const config = { params: params }
      return this.$utils
        .callAxiosWithBody(
          codes.requests.postRepeatScheduleSettings.method,
          codes.requests.postRepeatScheduleSettings.url,
          finalobject,
          config
        )
        .then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.$emit('fireResetLabel')
        })
        .catch((error) => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage)
          )
          this.$emit('fireResetLabel')
        })
    },
    ChangeTab (data) {
      console.log(data)
      if (data === 'Schedule') {
        this.scheduleList = []
        this.schedule = {}
        this.scheduleStartTime = ''
        this.scheduleEndTime = ''
        this.startTimeAMPMSelection = ''
        this.endTimeAMPMSelection = ''
        this.startTimeHoursSelection = ''
        this.endTimeHoursSelection = ''
        this.startTimeMinutesSelection = ''
        this.endTimeMinutesSelection = ''
        this.displayPageSchedule = ''
      } else {
        this.repeatscheduleStartTime = moment(new Date()).format('YYYY-MM-DD')
        this.repeatscheduleList = []
        this.repeatscheduleEndTime = ''
        this.repeatstartTimeAMPMSelection = ''
        this.repeatendTimeAMPMSelection = ''
        this.repeatstartTimeHoursSelection = ''
        this.repeatendTimeHoursSelection = ''
        this.repeatstartTimeMinutesSelection = ''
        this.repeatendTimeMinutesSelection = ''
        this.repeatdisplayPageSchedule = ''
        this.getWeekDaysSelected.forEach((data) => {
          data.selected = true
        })
      }
      this.setCurrentStartDate()
    },
    resetLabel () {
      this.$emit('fireResetLabel')
    }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '2101').then(flag => {
      this.scheduleSettingsButton = flag
    })
  },
  watch: {}
}
</script>
<style scoped>
.radioBtnWrapper .radioItem {
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: auto;
  min-width: 114px;
  margin: 0 10px;
  text-align: center;
  height: 100px;
  display: flex;
  flex-direction: column;
}

::v-deep .v-tabs > .v-tabs-bar .v-tab.v-tab--active {
  border-bottom: 3px solid #999 !important;
}

::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  background: #fff !important;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #fff;
}

.selectedFile-list {
  background-color: #fff;
}

::v-deep .search-v-select > .v-input__control > .v-input__slot fieldset {
  border: 1px solid transparent !important;
}

.placeholder {
  position: absolute;
  z-index: 3;
  color: #aaa;
  top: 0px;
  display: block;
  left: 0px;
  padding: 17px 15px 9px;
  width: 100%;
}
::v-deep .v-tab--active {
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0px 2px 6px #00000033 !important;
  margin-top: 3px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
::v-deep .theme--light.v-tabs-items {
  background-color: transparent !important;
}

::v-deep .tabBgColor {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 4px #00000033 !important;
  opacity: 1 !important;
  border-radius: 5px !important;
}
::v-deep .v-tabs > .v-tabs-bar .v-tab.v-tab--active {
  border-bottom: none !important;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  background: #ccc !important;
  border-top-left-radius: 5px;
  margin-top: 3px;
  border-top-right-radius: 7px;
}
::v-deep .v-data-table-header tr th {
  background-color: #ececec !important;
}
</style>
<style>
.search-v-select {
  height: 37px;
  padding: 0 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  overflow: hidden;
}

.redbold {
  color: red;
  font-weight: bold;
}

.mandatory .v-label.v-label--active:after,
.mandatory .v-label:after {
  content: "*";
  color: red;
  margin-left: -5px;
  font-weight: bold;
}

.tabitemclass {
  padding: 15px;
  background-color: transparent !important;
  max-height: 700px !important;
  overflow-y: auto;
}

.LedPatternClass + input {
  display: contents !important;
}
.cart-size {
  border-radius: 7px;
  text-align: center;
  margin: 5px;
  width: 55px;
  line-height: 27px;
  padding: 5px 0 5px 0;
}
.active-day {
  background-color: #001e38;
  color: #ccd2d7;
  cursor: pointer;
}
.not-active-day {
  opacity: 0.2;
  background: #ccd2d7;
  cursor: pointer;
}
.repeatdaysCss {
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
  margin-top: -18px;
  margin-left: -9px;
}
</style>
